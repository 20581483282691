import { Divider, Paper, TextField, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import moment, { Moment } from 'moment';
import React, { useState } from 'react';
import GraphQLErrorLoadingBoundary from '../../components/GraphQLErrorLoadingBoundary';
import { bailsToReturn } from '../../graphql/queries';
import { BailsToReturn, BailsToReturnVariables } from '../../graphql/types';

const BailsSums: React.FunctionComponent = () => {
  const formatDate = (date: Moment) => date.format('YYYY-MM-DD');

  const [date, setDate] = useState(formatDate(moment()));
  const classes = useStyles();

  const handleDateChange = (event: any) => {
    const today = moment(event.target.value, 'YYYY-MM-DD');
    setDate(formatDate(today));
  };

  return (
    <Paper className={classes.paper}>
      <GraphQLErrorLoadingBoundary<BailsToReturn, BailsToReturnVariables>
        query={bailsToReturn}
        options={{
          variables: {
            date: {
              start: date,
              end: moment(date)
                .add(1, 'd')
                .format('YYYY-MM-DD'),
            },
          },
        }}
      >
        {({ data }) => {
          const { sums } = data.bailsToReturn;
          const total = sums.cardSum + sums.cashSum + sums.invoiceSum;
          return (
            <div className={classes.contentWrapper}>
              <TextField
                label="Kauce ke dni"
                type="date"
                value={date}
                className={classes.textField}
                onChange={handleDateChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <div className={classes.sumsWrapper}>
                <div className={classes.sum}>
                  <div>{`Suma hotovost:`}</div>
                  <div>{`${sums.cashSum.toLocaleString('FR')} CZK`}</div>
                </div>
                <div className={classes.sum}>
                  <div>{`Suma karta:`}</div>
                  <div>{`${sums.cardSum.toLocaleString('FR')} CZK`}</div>
                </div>
                <div className={classes.sum}>
                  <div>{`Suma faktura:`}</div>
                  <div>{`${sums.invoiceSum.toLocaleString('FR')} CZK`}</div>
                </div>
                <Divider />
                <div className={classes.sum}>
                  <div>{`Suma celkem:`}</div>
                  <div>{`${total.toLocaleString('FR')} CZK`}</div>
                </div>
              </div>
            </div>
          );
        }}
      </GraphQLErrorLoadingBoundary>
    </Paper>
  );
};

export default BailsSums;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: 16,
      display: 'flex',
      justifyContent: 'left',
    },
    datePicker: {
      marginLeft: 64,
      marginRight: 32,
    },
    contentWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    textField: {
      display: 'inline-table',
    },
    sumsWrapper: {
      marginLeft: 32,
    },
    sum: {
      display: 'flex',
      justifyContent: 'space-between',
      minWidth: 240,
    },
  }),
);
