import React from 'react';
import { useMutation } from 'react-apollo';
import { addPayment } from '../../graphql/queries';
import {
  addPayment as AddPaymentType,
  addPaymentVariables,
  PaymentForm,
  PaymentType,
} from '../../graphql/types';
import { Button, makeStyles, createStyles, Theme } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { getGraphqlErrorMessage } from '../../lib/utils';

interface Props {
  orderId: number;
  amount: number;
  type: PaymentType;
  form: PaymentForm;
  onComplete: (data: AddPaymentType) => void;
  refetchQueries: { query: any; variables: any }[];
  disabled?: boolean;
}

const AddPaymentButton = ({
  orderId,
  amount,
  type,
  form,
  onComplete,
  refetchQueries,
  disabled = false,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [createPayment, { loading }] = useMutation<
    AddPaymentType,
    addPaymentVariables
  >(addPayment, {
    onCompleted: onComplete,
    onError: err =>
      enqueueSnackbar(getGraphqlErrorMessage(err), { variant: 'error' }),
  });

  const handleClick = () => {
    createPayment({
      variables: {
        orderId,
        isUpdate: true,
        payment: {
          amount,
          type,
          form,
        },
      },
      refetchQueries,
    });
  };

  const classes = useStyles();

  return (
    <Button
      className={classes.button}
      disabled={loading || disabled || amount === 0}
      variant="contained"
      color="primary"
      onClick={handleClick}
    >
      {amount < 0 ? 'Vybrat' : 'Vrátit'}
    </Button>
  );
};

export default AddPaymentButton;

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    textField: {
      margin: '10px',
      width: '85px',
    },
    button: {
      marginLeft: '16px',
    },
    tableCell: {
      padding: '12px 4px 12px 4px',
    },
  }),
);
