import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Snackbar,
  SnackbarContent,
  TextField,
  withStyles,
} from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';
import { Mutation } from 'react-apollo';
import CheckBoxCustom from '../../components/CheckBox';
import { changeOrderDate, order as orderQuery } from '../../graphql/queries';
import auth from '../../lib/auth';
import { labelPlacement } from '../../lib/enums';
import { DateChangeInput, OrderType } from '../../lib/types';
import { getIsOrder } from '../../lib/utils';
import { ApolloError } from '@apollo/client';
import { getErrorMessage } from './NewOrder/errors';

type IProps = {
  order: OrderType;
  open: boolean;
  onClose: () => void;
  classes: any;
};

type IState = {
  season: boolean[];
  changedDate: DateChangeInput;
  datesDisabled: {
    from: boolean;
    to: boolean;
  };
  isOrder: boolean;
  errorMessage: string;
  errorOpen: boolean;
};

class ChangeDateDialog extends React.Component<IProps, IState> {
  state: IState = {
    changedDate: {
      id: this.props.order.id,
      from: moment(this.props.order.from).format('YYYY-MM-DD'),
      to: moment(this.props.order.to).format('YYYY-MM-DD'),
      season: this.props.order.season,
    },
    season: [false, false],
    datesDisabled: {
      from: false,
      to: false,
    },
    isOrder: false,
    errorMessage: '',
    errorOpen: false,
  };

  componentDidMount() {
    const { order } = this.props;
    let { season, datesDisabled } = this.state;
    const isOrder = order ? getIsOrder(order.state as any) : true;
    if (order.season) {
      datesDisabled = {
        from: isOrder,
        to: true,
      };
      const fromYear = moment(order.from).year();
      const toYear = moment(order.to).year();
      if (Math.abs(toYear - fromYear) === 1) {
        season = [true, false];
      } else {
        season = [false, true];
      }
    }
    this.setState({ season, datesDisabled, isOrder });
  }

  handleCheckboxChange = (index: number) => {
    const { season, changedDate, isOrder } = this.state;
    season[index] = !season[index];
    if (season[index]) {
      season[Math.abs(index - 1)] = false;
    }
    const isSeason = season.reduce((acc, curr) => acc || curr, false);
    let datesDisabled;
    if (isSeason) {
      changedDate.from = isOrder
        ? moment().format('YYYY-MM-DD')
        : changedDate.from;
      changedDate.to = (isOrder
        ? moment()
        : moment(changedDate.from, 'YYYY-MM-DD')
      )
        .add(index + 1, 'y')
        .format('YYYY-MM-DD');
      datesDisabled = {
        from: isOrder,
        to: true,
      };
    } else {
      datesDisabled = {
        from: false,
        to: false,
      };
    }
    changedDate.season = isSeason;
    this.setState({
      season,
      datesDisabled,
      changedDate,
    });
  };

  handleChangeDate = (name: string) => (event: {
    target: { value: string };
    persist: any;
  }) => {
    event.persist();
    const date = event.target.value;
    const { isOrder, changedDate, season } = this.state;
    if (!isOrder && changedDate.season) {
      changedDate.from = date;
      changedDate.to = moment(date, 'YYYY-MM-DD')
        .add(season[0] ? 1 : 2, 'years')
        .format('YYYY-MM-DD');
      this.setState({ changedDate });
    } else {
      this.setState(prevState => ({
        changedDate: {
          ...prevState.changedDate,
          [name]: date,
        },
      }));
    }
  };

  handleClose = () => {
    this.setState(prevState => ({
      ...prevState,
      errorOpen: false,
    }));
  };

  mutationError = (err: ApolloError) => {
    const graphqlError = err.graphQLErrors?.[0];
    this.setState(prevState => ({
      ...prevState,
      errorOpen: true,
      errorMessage: getErrorMessage(graphqlError.name),
    }));
  };

  render() {
    const { open, onClose, order, classes } = this.props;
    const { season, changedDate } = this.state;
    const isAdmin = auth.isAdmin();
    const isOrder = order ? getIsOrder(order.state as any) : true;
    const color = isOrder ? 'primary' : 'secondary';
    return (
      <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{`Měníte termín u objednávky číslo ${order.id}`}</DialogTitle>
        <DialogContent>
          <div className="checkBoxsGroup">
            {Object.keys(season).map((_, index) => (
              <CheckBoxCustom
                key={index}
                checked={season[index]}
                onChange={() => this.handleCheckboxChange(index)}
                name={index === 0 ? 'Jedna sezóna' : 'Dvě sezóny'}
                labelPlacement={labelPlacement.END}
                color={color}
              />
            ))}
          </div>
          <div className="checkBoxsGroup">
            <TextField
              label="Od"
              type="date"
              required={true}
              value={changedDate.from}
              className="text-field"
              disabled={this.state.datesDisabled.from}
              onChange={this.handleChangeDate('from')}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: isAdmin ? null : new Date().toJSON().split('T')[0],
              }}
            />
            <TextField
              label="Do"
              type="date"
              required={true}
              value={changedDate.to}
              className="text-field"
              disabled={this.state.datesDisabled.to}
              onChange={this.handleChangeDate('to')}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: changedDate.from.split('T')[0],
              }}
            />
          </div>
          <Divider />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <div className="invisible">{}</div>
          <Mutation
            mutation={changeOrderDate}
            variables={{ order: changedDate }}
            onCompleted={onClose}
            onError={this.mutationError}
            refetchQueries={[
              {
                query: orderQuery,
                variables: { id: changedDate.id },
              },
            ]}
          >
            {(postMutation: () => void) => (
              <Button variant="contained" color={color} onClick={postMutation}>
                Změnit termín
              </Button>
            )}
          </Mutation>
          <Button onClick={onClose} color="primary">
            Zavřít
          </Button>
        </DialogActions>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.errorOpen}
          autoHideDuration={6000}
          onClose={this.handleClose}
        >
          <SnackbarContent
            style={{ backgroundColor: '#d32f2f' }}
            message={<span>{this.state.errorMessage}</span>}
          />
        </Snackbar>
      </Dialog>
    );
  }
}

const styles = (theme: any) => ({
  textField: {
    margin: '16px',
    width: '85px',
  },
  button: {
    marginLeft: '16px',
  },
  dialogActions: {
    justifyContent: 'space-between',
  },
});

export default withStyles(styles)(ChangeDateDialog);
