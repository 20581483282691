import gql from 'graphql-tag';

export const addItemPrice = gql`
  mutation addItemPrice($name: String!) {
    addItemPrice(name: $name) {
      id
    }
  }
`;

export const addSetPrice = gql`
  mutation addSetPrice($name: String!) {
    addSetPrice(name: $name) {
      id
    }
  }
`;

export const addCategoryMutation = gql`
  mutation AddCategory($name: String!, $itemsExcludedFromSet: Boolean) {
    addCategory(name: $name, itemsExcludedFromSet: $itemsExcludedFromSet) {
      id
    }
  }
`;

export const editCategoryMutation = gql`
  mutation EditCategory($input: EditCategoryInput!) {
    editCategory(input: $input) {
      id
      name
      itemsExcludedFromSet
      imagePath
    }
  }
`;

export const addCatalogGroup = gql`
  mutation addCatalogGroup($name: String!) {
    addCatalogGroup(name: $name) {
      id
    }
  }
`;

export const deleteCatalogItem = gql`
  mutation deleteCatalogItem($catalogItemId: Int!) {
    deleteCatalogItem(catalogItemId: $catalogItemId)
  }
`;

export const changeOrderDate = gql`
  mutation changeOrderDate($order: DateChangeInput!) {
    changeOrderDate(order: $order)
  }
`;

export const setMorningPickUp = gql`
  mutation setMorningPickUp($orderId: Int!, $morningPickUp: Boolean) {
    setMorningPickUp(orderId: $orderId, morningPickUp: $morningPickUp) {
      morningPickUp
      orderPrice
    }
  }
`;

export const setEveningRetrun = gql`
  mutation setEveningReturn($orderId: Int!, $eveningReturn: Boolean) {
    setEveningReturn(orderId: $orderId, eveningReturn: $eveningReturn) {
      eveningReturn
      orderPrice
    }
  }
`;

export const createFollowingOrder = gql`
  mutation createFollowingOrder(
    $from: DateTime!
    $to: DateTime!
    $orderId: Int!
    $season: Boolean!
  ) {
    createFollowingOrder(
      from: $from
      to: $to
      orderId: $orderId
      season: $season
    ) {
      id
    }
  }
`;

export const extendCurrentOrder = gql`
  mutation extendCurrentOrder($to: DateTime!, $orderId: Int!, $price: Int!) {
    extendCurrentOrder(to: $to, orderId: $orderId, price: $price) {
      id
    }
  }
`;

const catalogItemInfoFragment = gql`
  fragment CatalogItemInfo on CatalogItem {
    id
    category {
      id
      name
    }
    purpose
    name
    size
    selectSize
    variant
    producer
    note
    pair
    price
    settings
    createsSet
    itemPriceId
    setPriceId
  }
`;

export const createCatalogItem = gql`
  mutation createCatalogItem($catalogItem: CreateCatalogItemInput!) {
    createCatalogItem(catalogItem: $catalogItem) {
      ...CatalogItemInfo
    }
  }
  ${catalogItemInfoFragment}
`;

export const updateCatalogItem = gql`
  mutation updateCatalogItem($catalogItem: UpdateCatalogItemInput!) {
    updateCatalogItem(catalogItem: $catalogItem) {
      ...CatalogItemInfo
    }
  }
  ${catalogItemInfoFragment}
`;

export const addCatalogItemToCatalogGroupMutaion = gql`
  mutation addCatalogItem(
    $catalogItemIds: [Int!]!
    $catalogGroupId: Int!
    $positionInCatalogGroup: Int!
  ) {
    addCatalogItemToCatalogGroup(
      catalogItemIds: $catalogItemIds
      catalogGroupId: $catalogGroupId
      positionInCatalogGroup: $positionInCatalogGroup
    )
  }
`;

export const removeCatalogItemFromCatalogGroupMutation = gql`
  mutation removeCatalogItem($catalogItemIds: [Int!]!) {
    removeCatalogItemFromCatalogGroup(catalogItemIds: $catalogItemIds)
  }
`;

export const setPositionOfCatalogItemsInCatalogGroupMutation = gql`
  mutation setCatalogItem(
    $catalogItemIds: [Int!]!
    $positionInCatalogGroup: Int!
  ) {
    setPositionOfCatalogItemsInCatalogGroup(
      catalogItemIds: $catalogItemIds
      positionInCatalogGroup: $positionInCatalogGroup
    )
  }
`;

export const canCatalogItemBeInSet = gql`
  query CanCatalogItemBeInSet($setId: Int!, $catalogId: Int!) {
    canCatalogItemBeInSet(setId: $setId, catalogId: $catalogId)
  }
`;

export const getCatalogItem = gql`
  query getCatalogItem($id: ID!) {
    catalogItem(id: $id) {
      id
      category {
        id
        name
      }
      purpose
      name
      size
      selectSize
      variant
      producer
      note
      pair
      price
      settings
      createsSet
      itemPriceId
      setPriceId
      seasonDisabled
    }
  }
`;

export const getSetCatalogItems = gql`
  query getSetCatalogItems($ids: [Int!]) {
    getSetCatalogItems(ids: $ids) {
      id
      purpose
      name
      categoryName
      size
      selectSize
      variant
      producer
      note
      pair
      createsSet
      settings
      price
    }
  }
`;

export const addItemToSet = gql`
  mutation AddItemToSet($setId: Int!, $itemId: Int!) {
    addItemToSet(setId: $setId, itemId: $itemId) {
      id
    }
  }
`;

export const removeSetFromOrder = gql`
  mutation RemoveSetFromOrder($setId: Int!) {
    removeSetFromOrder(setId: $setId) {
      id
    }
  }
`;

export const removeItemFromSet = gql`
  mutation RemoveItemFromSet($setId: Int!, $itemId: Int!) {
    removeItemFromSet(setId: $setId, itemId: $itemId) {
      id
    }
  }
`;

export const accountingSummary = gql`
  query accountingSummary($from: DateTime, $to: DateTime) {
    accountingSummary(from: $from, to: $to) {
      ordersSummary {
        orderId
        customerName
        customerPhone
        priceCash
        priceCard
        priceInvoice
        priceOnline
        priceDPH
        bailCash
        bailCard
        bailInvoice
        bailReturnedSum
      }
      sums {
        priceCash
        priceCard
        priceInvoice
        priceOnline
        bailCash
        bailCard
        bailInvoice
        bailReturnedSum
        priceDPH
      }
    }
  }
`;

export const loadCatalogItems = gql`
  query loadCatalogItems(
    $offset: Int
    $limit: Int
    $sort: String
    $order: String
    $search: String
    $barCode: String
  ) {
    catalogItems(
      offset: $offset
      limit: $limit
      sort: $sort
      order: $order
      search: $search
      barCode: $barCode
    ) {
      totalCount
      items {
        id
        name
        producer
        category {
          id
          name
        }
        purpose
        variant
        size
        selectSize
        stockItemsCount
        borrowed
        seasonDisabled
        imagePath
        catalogGroup {
          id
          name
          code
        }
      }
    }
  }
`;

export const loadStockItems = gql`
  query stockItems($offset: Int, $limit: Int, $search: String) {
    stockItems(offset: $offset, limit: $limit, search: $search) {
      totalCount
      items {
        code
        catalogItem {
          name
        }
      }
    }
  }
`;

export const loadEmployees = gql`
  query employees {
    employees {
      totalCount
      employees {
        id
        role
        user {
          id
          name
          username
        }
      }
    }
  }
`;

export const loadClosedDays = gql`
  query ClosedDays {
    closedDays {
      id
      from
      to
      recurring
      note
    }
  }
`;

export const addClosedDay = gql`
  mutation addClosedDay(
    $from: DateTime!
    $to: DateTime!
    $recurring: RecurringType!
    $note: String
  ) {
    addClosedDay(from: $from, to: $to, recurring: $recurring, note: $note) {
      id
    }
  }
`;

export const loadOrders = gql`
  query loadOrders(
    $offset: Int
    $limit: Int
    $sort: String
    $order: String
    $from: DateLimit
    $to: DateLimit
    $state: [String]
    $source: [ReservationSource]
    $search: String
    $unreturned: Boolean
  ) {
    orders(
      offset: $offset
      limit: $limit
      sort: $sort
      order: $order
      from: $from
      to: $to
      state: $state
      source: $source
      search: $search
      unreturned: $unreturned
    ) {
      totalCount
      items {
        id
        from
        to
        season
        lostAndFound
        overbooked {
          id
          name
          producer
          size
        }
        deliveryAddress {
          id
          streetName
          streetNumber
          city
          zipCode
        }
        orderOriginator
        bail
        price
        discount {
          id
          discount
        }
        withoutBail
        payments {
          id
          amount
          type
          createdAt
        }
        customer {
          id
          name
          phone
        }
        state
        note
        eetReceipt
        sets {
          id
          category
          client {
            id
            age
            weight
            height
            fitness
            snowboardPosition
          }
        }
        extended
      }
    }
  }
`;

export const createOrder = gql`
  mutation createOrder($order: OrderInput) {
    createOrder(order: $order) {
      id
    }
  }
`;

export const createReservation = gql`
  mutation createReservation($order: OrderInput!) {
    createReservation(order: $order) {
      paymentUrl
    }
  }
`;

export const itemPrices = gql`
  query itemPrices($variant: String) {
    itemPrices(variant: $variant) {
      id
      name
      variant
      oneDayPrice
      twoDayPrice
      basePrice
      bail
      seasonPrice
      twoSeasonPrice
      seasonBail
      fixedPrice
    }
  }
`;

export const getSetPrices = gql`
  query GetSetPrices {
    getSetPrices {
      id
      name
      variant
      oneDayPrice
      twoDayPrice
      basePrice
      seasonPrice
      twoSeasonPrice
      bail
      imagePath
      seasonBail
    }
  }
`;

export const setPrices = gql`
  query setPrices($variant: String) {
    setPrices(variant: $variant) {
      id
      name
      variant
      oneDayPrice
      twoDayPrice
      basePrice
      seasonPrice
      twoSeasonPrice
      bail
      imagePath
      seasonBail
    }
  }
`;

export const countBailBySetId = gql`
  query CountBailBySetId($setId: Int!, $season: Boolean!) {
    countBailBySetId(setId: $setId, season: $season) {
      setId
      amount
    }
  }
`;

export const countPriceBySetId = gql`
  query CountPriceBySetId($setId: Int!, $orderId: Int!) {
    countPriceBySetId(setId: $setId, orderId: $orderId) {
      setId
      amount
    }
  }
`;

export const categories = gql`
  query categories {
    categories {
      id
      name
      imagePath
      itemsExcludedFromSet
    }
  }
`;

export const allowedCategories = gql`
  query AllowedCategory($setPriceId: Int) {
    allowedCategories(setPriceId: $setPriceId) {
      id
    }
  }
`;

export const setAllowedCategories = gql`
  mutation setAllowedCategories($setPriceId: Int, $allowedCategories: [Int]) {
    setAllowedCategories(
      setPriceId: $setPriceId
      allowedCategories: $allowedCategories
    )
  }
`;

export const addPayment = gql`
  mutation addPayment(
    $orderId: Int
    $payment: PaymentInput
    $isUpdate: Boolean!
  ) {
    addPayment(orderId: $orderId, payment: $payment, isUpdate: $isUpdate) {
      amount
    }
  }
`;

export const addPayments = gql`
  mutation addPayments(
    $orderId: Int
    $payments: [PaymentInput]
    $isUpdate: Boolean!
  ) {
    addPayments(orderId: $orderId, payments: $payments, isUpdate: $isUpdate) {
      amount
    }
  }
`;

export const addPaymentsAndCancelOrder = gql`
  mutation addPaymentsAndCancelOrder(
    $orderId: Int
    $payments: [PaymentInput]
    $isUpdate: Boolean!
  ) {
    addPaymentsAndCancelOrder(
      orderId: $orderId
      payments: $payments
      isUpdate: $isUpdate
    ) {
      amount
    }
  }
`;

export const setWithoutBail = gql`
  mutation setWithoutBail($orderId: Int, $withoutBail: Boolean) {
    setWithoutBail(orderId: $orderId, withoutBail: $withoutBail)
  }
`;
export const setDiscount = gql`
  mutation setDiscount($orderId: Int, $discount: Int) {
    setDiscount(orderId: $orderId, discount: $discount)
  }
`;

export const updateOrderPriceOrBail = gql`
  mutation updateOrderPriceOrBail(
    $orderId: Int
    $amount: Int
    $valueType: String
  ) {
    updateOrderPriceOrBail(
      orderId: $orderId
      amount: $amount
      valueType: $valueType
    )
  }
`;

export const updateState = gql`
  mutation updateState($orderId: Int, $state: OrderState) {
    updateState(orderId: $orderId, state: $state) {
      id
      state
    }
  }
`;
export const setItemPrice = gql`
  mutation setItemPrice($price: PriceInput) {
    setItemPrice(price: $price)
  }
`;

export const setSetPrice = gql`
  mutation setSetPrice($price: PriceSetInput) {
    setSetPrice(price: $price)
  }
`;

export const deletePayment = gql`
  mutation deletePayment($paymentId: ID!) {
    deletePayment(paymentId: $paymentId)
  }
`;
export const deleteOrder = gql`
  mutation deleteOrder($orderId: Int!) {
    deleteOrder(orderId: $orderId)
  }
`;

export const cancelOrder = gql`
  mutation cancelOrder($orderId: Int!) {
    cancelOrder(orderId: $orderId)
  }
`;

export const changeOrder = gql`
  mutation ChangeOrder($orderId: Int!, $sets: OrderChangeSets!) {
    changeOrder(orderId: $orderId, sets: $sets)
  }
`;

export const configQuery = gql`
  query ConfigQuery {
    configQuery {
      seasonEnabled
      twoSeasonsEnabled
      addressDeliveryDisabled
      isReservationDisabled
    }
  }
`;

export const configUpdate = gql`
  mutation ConfigUpdate($input: ConfigInput!) {
    configUpdate(input: $input) {
      seasonEnabled
      twoSeasonsEnabled
      addressDeliveryDisabled
      isReservationDisabled
    }
  }
`;

export const deleteClosedDay = gql`
  mutation deleteClosedDay($id: Int!) {
    deleteClosedDay(id: $id)
  }
`;

export const PaymentsQuery = gql`
  query Payments(
    $limit: Int
    $offset: Int
    $from: DateTime
    $to: DateTime
    $price: Boolean
    $bail: Boolean
    $repay: Boolean
    $repayPrice: Boolean
    $cash: Boolean
    $card: Boolean
    $invoice: Boolean
    $online: Boolean
  ) {
    payments(
      limit: $limit
      offset: $offset
      from: $from
      to: $to
      price: $price
      bail: $bail
      repay: $repay
      repayPrice: $repayPrice
      cash: $cash
      card: $card
      invoice: $invoice
      online: $online
    ) {
      totalCount
      sums {
        priceSum
        bailSum
        repaySum
        repaySumPayment
        cashSum
        cardSum
        invoiceSum
        onlineSum
      }
      payments {
        id
        amount
        type
        form
        createdAt
        user {
          name
          username
        }
        order {
          id
        }
      }
    }
  }
`;

export const catalogItemDetail = gql`
  query CatalogItem($id: ID!) {
    catalogItem(id: $id) {
      id
      name
      producer
      purpose
      size
      selectSize
      variant
      categoryName
      description
      universal
      seasonDisabled
      stockItems {
        id
        code
        createdAt
        updatedAt
        deleted
        author {
          user {
            id
            username
          }
        }
      }
      orders {
        id
        from
        to
        state
        customer {
          id
          name
          phone
        }
      }
    }
  }
`;

export const getPricingForInterval = gql`
  query GetPricingForInterval(
    $catalogId: Int!
    $from: DateTime!
    $to: DateTime!
    $set: Boolean!
    $season: Boolean!
    $morningPickUp: Boolean!
  ) {
    getPricingForInterval(
      catalogId: $catalogId
      from: $from
      to: $to
      set: $set
      season: $season
      morningPickUp: $morningPickUp
    ) {
      price
      bail
    }
  }
`;

export const discounts = gql`
  query Discounts {
    discounts {
      discounts {
        id
        role
        discount
      }
    }
  }
`;

export const orderBailDialog = gql`
  query OrderBail($id: Int!) {
    order(id: $id) {
      id
      bail
      price
      to
      serviceNote
      state
      payments {
        amount
        type
      }
      amounts {
        toPayPrice
        toReturnBail
        newPrice
        bail
        price
        returnBail
        returnPrice
      }
      withoutBail
      discount {
        id
        discount
      }
      sets {
        items {
          firstItemReturnedDate
          secondItemReturnedDate
          firstItemReturned
          secondItemReturned
          firstItem {
            code
            catalogItem {
              name
              size
              variant
              producer
              categoryName
            }
          }
          secondItem {
            code
            catalogItem {
              name
              size
              variant
              producer
              categoryName
            }
          }
        }
      }
    }
  }
`;
export const order = gql`
  query Order($id: Int!) {
    order(id: $id) {
      id
      bail
      from
      to
      state
      season
      price
      payments {
        amount
        type
      }
      withoutBail
      discount {
        id
        discount
      }
      morningPickUp
      eveningReturn
    }
  }
`;

export const bailsToReturn = gql`
  query BailsToReturn($date: DateLimit!) {
    bailsToReturn(date: $date) {
      totalCount
      sums {
        cashSum
        cardSum
        invoiceSum
      }
    }
  }
`;

export const catalogGroupsQuery = gql`
  query CatalogGroups {
    catalogGroups {
      id
      name
      code
    }
  }
`;

export const deleteSize = gql`
  mutation DeleteSize($sizeId: ID!) {
    deleteSize(sizeId: $sizeId)
  }
`;

export const sizesCountQuery = gql`
  query SizesCount($catalogGroupName: String) {
    getSizesCount(catalogGroupName: $catalogGroupName)
  }
`;

export const sizesQuery = gql`
  query Sizes($catalogGroupName: String, $offset: Int, $limit: Int) {
    getSizes(
      catalogGroupName: $catalogGroupName
      offset: $offset
      limit: $limit
    ) {
      id
      heightMin
      heightMax
      weightMin
      weightMax
      footSize
      min
      max
      size
      count
      catalogGroups {
        id
        name
        code
      }
    }
  }
`;

export const filtersQuery = gql`
  query Filters($input: FilterInput) {
    getFilters(input: $input) {
      id
      basketSetType
      catalogItemType
      ccsPurpose
      ccsStyle
      sex
      skiArc
      skills
      skiSlope
      catalogGroups {
        id
        name
        code
      }
    }
  }
`;

export const getFiltersQuery = gql`
  query GetFilters {
    filters {
      id
      basketSetType
      catalogItemType
      ccsPurpose
      ccsStyle
      sex
      skiArc
      skills
      skiSlope
    }
  }
`;

export const filterByIdQuery = gql`
  query GetFilterById($id: Int!) {
    getFilterById(id: $id) {
      id
      basketSetType
      catalogItemType
      ccsPurpose
      ccsStyle
      sex
      skiArc
      skills
      skiSlope
      catalogGroups {
        id
        code
      }
    }
  }
`;

export const mutateFiltersQuery = gql`
  mutation MutateFilter($input: MutateItemFilter!) {
    mutateFilter(input: $input) {
      id
    }
  }
`;

export const setSize = gql`
  mutation SetSize($input: SizeInput!) {
    setSize(input: $input) {
      id
    }
  }
`;

export const catalogItemsInCatalogGroupQuery = gql`
  query CatalogItemsInCatalogGroup($input: CatalogItemsSizeIndependentInput!) {
    catalogItemsSizeIndependent(input: $input) {
      totalCount
      items {
        id
        name
        purpose
        producer
        variant
        positionInCatalogGroup
        category {
          id
          name
        }
        ids
        sizes
      }
    }
  }
`;

export const getCatalogItemDescription = gql`
  query GetCatalogItemDescription($id: ID!) {
    catalogItem(id: $id) {
      id
      description
    }
  }
`;

export const setCatalogItemsDescription = gql`
  mutation SetItemDescription($catalogItemIds: [ID!]!, $description: String!) {
    setCatalogItemsDescription(
      catalogItemIds: $catalogItemIds
      description: $description
    ) {
      id
    }
  }
`;

export const getCustomers = gql`
  query GetCustomers($name: String, $phone: String) {
    customers(name: $name, phone: $phone) {
      id
      email
      name
      phone
    }
  }
`;

export const getCashRegisterRecords = gql`
  query GetCashRegisterRecords($date: String) {
    cashRegisterRecords(date: $date) {
      id
      openId
      closedId
      openedAt
      closedAt
      openWithAmount
      closedWithAmount
      closedWithDifference
      differenceResolved
      closedDifference
    }
  }
`;

export const paymentFragment = gql`
  fragment PaymentFragment on Payment {
    type
    user {
      name
    }
  }
`;

export const cashRegisterFragment = gql`
  fragment CashRegisterFragment on CashRegister {
    recordType
    closedWithDifference
    differenceResolved
    closedDifference
    employee {
      id
      user {
        name
      }
    }
  }
`;

export const getCashRegister = gql`
  query GetCashRegister($cashRegisterRecordId: ID!) {
    cashRegister(cashRegisterRecordId: $cashRegisterRecordId) {
      id
      createdAt
      amount
      ...CashRegisterFragment
      ...PaymentFragment
    }
  }
  ${paymentFragment},
  ${cashRegisterFragment}
`;

export const addRecordToCashRegister = gql`
  mutation AddRecordToCashRegister($recordType: RecordType!, $amount: Int!) {
    addRecordToCashRegister(recordType: $recordType, amount: $amount) {
      id
    }
  }
`;

export const cashRegisterDifferenceResolved = gql`
  mutation CashRegisterDifferenceResolved($id: ID!) {
    cashRegisterDifferenceResolved(id: $id) {
      id
      differenceResolved
    }
  }
`;

export const setOrderNoteMutation = gql`
  mutation SetOrderNote($orderId: Int!, $note: String) {
    setOrderNote(orderId: $orderId, note: $note) {
      id
      note
    }
  }
`;

export const getLastCashRecord = gql`
  query GetLastCashRecord {
    lastCashRecord {
      closedId
      openId
      id
      openedAt
      closedAt
      openWithAmount
      closedWithAmount
      closedWithDifference
      closedDifference
      differenceResolved
    }
  }
`;

export const getOrder = gql`
  query GetOrder($id: Int!) {
    order(id: $id) {
      id
      from
      to
      note
      serviceNote
      state
      season
      deliveryAddress {
        id
        streetName
        streetNumber
        city
        zipCode
        customerId
      }
      customer {
        id
        name
        phone
        email
      }
      sets {
        id
        price
        bail
        category
        items {
          id
          firstItem {
            id
            code
            catalogItem {
              id
              name
              variant
              producer
              categoryName
              size
              createsSet
              pair
            }
          }
          secondItem {
            id
            code
          }
        }
      }
      lostAndFound
      bail
      price
      payments {
        id
        amount
        type
        form
        createdAt
      }
      withoutBail
      discount {
        id
        discount
      }
      morningPickUp
      eveningReturn
      orderOriginator
      paymentUrl
      extended
    }
  }
`;

export const getOrderDetail = gql`
  query GetOrderDetail($id: Int!) {
    order(id: $id) {
      id
      from
      to
      bail
      price
      state
      withoutBail
      note
      payments {
        id
        amount
        type
        form
        createdAt
      }
      discount {
        id
        discount
      }
      customer {
        id
        name
        phone
        email
      }
      sets {
        id
        price
        bail
        category
        items {
          id
          firstItem {
            id
            code
            catalogItem {
              id
              name
              variant
              producer
              categoryName
              size
            }
          }
          secondItem {
            id
            code
          }
        }
      }
    }
  }
`;

export const findStockItem = gql`
  query FindStockItem($code: String!, $deleted: Boolean!) {
    findStockItem(code: $code, deleted: $deleted) {
      id
      code
      catalogItem {
        id
        name
        createsSet
        size
        variant
        producer
        categoryName
        createsSet
        pair
      }
      activeOrder {
        id
      }
      lostAndFoundOrder {
        id
      }
      deleted
    }
  }
`;

export const findStockItemPriceInterval = gql`
  query FindStockItemPriceInterval(
    $code: String!
    $from: DateTime!
    $to: DateTime!
    $set: Boolean!
    $season: Boolean!
    $morningPickUp: Boolean!
    $deleted: Boolean!
  ) {
    findStockItemPriceInterval(
      code: $code
      from: $from
      to: $to
      set: $set
      season: $season
      morningPickUp: $morningPickUp
      deleted: $deleted
    ) {
      id
      code
      catalogItem {
        id
        name
        createsSet
        size
        variant
        producer
        categoryName
        createsSet
        pair
      }
      deleted
      price
      bail
    }
  }
`;

export const findStockItemsLike = gql`
  query FindStockItemsLike($codeLike: String!, $deleted: Boolean!) {
    findStockItemsLike(codeLike: $codeLike, deleted: $deleted) {
      id
      code
      catalogItem {
        id
        name
        size
        variant
        producer
        categoryName
      }
      activeOrder {
        id
      }
      lostAndFoundOrder {
        id
      }
      deleted
    }
  }
`;

export const findStockPairItemsLike = gql`
  query FindStockPairItemsLike(
    $catalogId: Int!
    $codeLike: String!
    $deleted: Boolean!
  ) {
    findStockPairItemsLike(
      catalogId: $catalogId
      codeLike: $codeLike
      deleted: $deleted
    ) {
      id
      code
      catalogItem {
        id
        name
        size
        variant
        producer
        categoryName
      }
      activeOrder {
        id
      }
      lostAndFoundOrder {
        id
      }
      deleted
    }
  }
`;

export const stockUsageQuery = gql`
  query StockUsageQuery(
    $limit: Int
    $offset: Int
    $searchCode: String
    $searchCatalog: String
    $searchSize: String
  ) {
    stockUsage(
      limit: $limit
      offset: $offset
      searchCode: $searchCode
      searchCatalog: $searchCatalog
      searchSize: $searchSize
    ) {
      totalCount
      items {
        id
        code
        catalogId
        catalogName
        catalogSize
        days
        years
      }
    }
  }
`;

export const getPriceForFollowingOrderQuery = gql`
  query GetPriceForFollowingOrderQuery(
    $from: DateTime!
    $to: DateTime!
    $orderId: Int!
    $season: Boolean!
  ) {
    priceForFollowingOrder(
      from: $from
      to: $to
      orderId: $orderId
      season: $season
    ) {
      setsInOrder {
        price
        setId
        bail
      }
      conflictOrders {
        id
        from
        to
        state
        customer {
          id
          name
          phone
        }
      }
    }
  }
`;

export const isOrderSeasonPriceDisabled = gql`
  query IsOrderSeasonPriceDisabled($orderId: Int!) {
    isOrderSeasonPriceDisabled(orderId: $orderId)
  }
`;

export const categoryEarningsQuery = gql`
  query CategoryEarningsQuery($from: DateTime!, $to: DateTime!) {
    categoryEarnings(from: $from, to: $to) {
      id
      name
      amount
      stornoAmount
    }
  }
`;
