/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Box,
  Fab,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import { Warning } from '@material-ui/icons';
import AccountBalanceWallet from '@material-ui/icons/AccountBalanceWallet';
import AddIcon from '@material-ui/icons/Add';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import Cancel from '@material-ui/icons/Cancel';
import CloudDownload from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import RefreshIcon from '@material-ui/icons/Refresh';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import { Link, RouteComponentProps } from '@reach/router';
import moment from 'moment';
import * as React from 'react';
import { Query, Subscription, withApollo } from 'react-apollo';
import Pagination from 'react-js-pagination';
import ConfirmDialog from '../../components/ConfirmDialog';
import ContextMenu from '../../components/ContextMenu';
import { deleteOrder, loadOrders, updateState } from '../../graphql/queries';
import { ORDER_SUBSCRIPTION } from '../../graphql/subscriptions';
import { OrderState } from '../../graphql/types';
import auth from '../../lib/auth';
import { PagesNames } from '../../lib/enums';
import { orderStateSingularCZ, paymentTypeCZ } from '../../lib/localization';
import pagesState from '../../lib/pageState';
import {
  OrderPageState,
  OrderStateFilters,
  OrderType,
  Payment,
  ReservationStateFilters,
  bailsSum,
} from '../../lib/types';
import { downloadPDF, getStates, stylePhoneNumber } from '../../lib/utils';
import BailDialog from './BailDialog';
import BailsSums from './BailsSums';
import CancelPaymentsModal from './CancelPaymentsModal';
import ExtendOrderModal from './ExtendOrderModal';
import FollowingReservationModal from './FollowingOrderModal';
import OrdersAndReservationsFilters from './OrdersAndReservationsFilters';
import PriceDialog from './PriceDialog';
import './order.css';

type IState = {
  activePage: number;
  order: string;
  direction: string;
  search: string;
  bailDialogOpen: number;
  priceDialogOpen: number;
  bail: number;
  from: string;
  to: string;
  date: string;
  bailsSums: bailsSum;
  itemsCountPerPage: number;
  confrimDialogOrder: OrderType | null;
  orderFilters: OrderStateFilters;
  reservationFilters: ReservationStateFilters;
  activeEntity: 'ORDER' | 'RESERVATION';
  source: ('ADMINISTRATION' | 'ONLINE_RESERVATION')[];
  updated: Date;
  isCancelPaymentsModalOpen: boolean;
  payments: Payment[];
  orderId: number;
  actionMenuOpen: boolean;
  isFollowingOrderModalOpen: boolean;
  folowingOrder: OrderType | null;
  isExtendOrderModalOpen: boolean;
  extendedOrder: OrderType | null;
  isChangeOrderModalOpen: boolean;
  changedOrder: OrderType | null;
};

type IProps = {
  classes?: any;
  client: any;
};

const tableRowColor: any = Object.freeze({
  NEW: '#ffea00',
  PREPARED: '#00b0ff',
  ACTIVE: '#00e676',
  FINISHED: 'white',
  CHANGE: '#FF6E40',
  RESERVATION_NEW: '#ffea00',
  RESERVATION_PREPARED: '#00b0ff',
  RESERVATION_PAID: '#00e676',
  RESERVATION_CANCELED: '#FF0000',
  CANCELED: '#FF0000',
});

const addressToCZ = Object.freeze({
  streetName: 'Ulice',
  streetNumber: 'č.p:',
  city: 'Město',
  zipCode: 'PSČ',
});

const clientParamsToCZ = Object.freeze({
  age: 'Věk',
  weight: 'Váha',
  height: 'Výška',
  fitness: 'Zdatnost',
  snowboardPosition: 'Pozice na snowboardu',
});

const getUnits = (param: string) => {
  if (param === 'weight') {
    return 'kg';
  } else if (param === 'height') {
    return 'cm';
  }
  return '';
};

class OrderList extends React.Component<IProps, IState> {
  state: IState = {
    activePage: 1,
    order: 'id',
    direction: 'desc',
    search: '',
    bailDialogOpen: -1,
    bail: 0,
    priceDialogOpen: -1,
    from: moment().format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
    date: moment().format('YYYY-MM-DD'),
    bailsSums: {
      cashSum: 0,
      cardSum: 0,
      invoiceSum: 0,
    },
    itemsCountPerPage: 12,
    confrimDialogOrder: null,
    orderFilters: {
      new: false,
      prepared: false,
      active: false,
      finished: false,
      unreturned: false,
      canceled: false,
    },
    reservationFilters: {
      new: false,
      prepared: false,
      paid: false,
      canceled: false,
    },
    activeEntity: 'ORDER',
    source: ['ADMINISTRATION', 'ONLINE_RESERVATION'],
    updated: new Date(),
    isCancelPaymentsModalOpen: false,
    payments: [],
    orderId: 0,
    actionMenuOpen: false,
    isFollowingOrderModalOpen: false,
    folowingOrder: null,
    isExtendOrderModalOpen: false,
    extendedOrder: null,
    isChangeOrderModalOpen: false,
    changedOrder: null,
  };

  refetch?: () => void = undefined;

  componentWillMount() {
    const state: OrderPageState | null = pagesState.getPageState(
      PagesNames.Order,
    ) as any;

    if (state) {
      this.setState({
        activePage: state.activePage,
        direction: state.direction,
        order: state.order,
        search: state.search,
        itemsCountPerPage: state.itemsCountPerPage,
        orderFilters: state.orderFilters,
        reservationFilters: state.reservationFilters,
        activeEntity: state.activeEntity,
        source: state.source,
      });
    }
  }

  componentWillUnmount() {
    const state: OrderPageState = {
      activePage: this.state.activePage,
      order: this.state.order,
      direction: this.state.direction,
      search: this.state.search,
      itemsCountPerPage: this.state.itemsCountPerPage,
      orderFilters: this.state.orderFilters,
      reservationFilters: this.state.reservationFilters,
      activeEntity: this.state.activeEntity,
      source: this.state.source,
    };
    pagesState.setPageState(PagesNames.Order, state);
  }

  handleBailOpen = (id: number) => () => this.setState({ bailDialogOpen: id });

  handlePriceOpen = (id: number) => () => {
    this.setState({ priceDialogOpen: id });
  };

  handleBailClose = () => {
    this.setState({ bailDialogOpen: -1 });
  };

  handlePriceClose = () => {
    this.setState({ priceDialogOpen: -1 });
  };

  handlePageChange = (pageNumber: number) => {
    this.setState({ activePage: pageNumber });
  };

  handleCancelPaymentsModalClose = () => {
    this.setState({ isCancelPaymentsModalOpen: false });
    this.setState({ payments: [] });
    this.setState({ orderId: 0 });
  };

  handleFollowingOrderModalClose = () => {
    this.setState({ isFollowingOrderModalOpen: false });
    this.setState({ folowingOrder: null });
  };

  handleExtendOrderModalClose = () => {
    this.setState({ isExtendOrderModalOpen: false });
    this.setState({ extendedOrder: null });
  };

  handleChangeOrderModalClose = () => {
    this.setState({ isChangeOrderModalOpen: false });
    this.setState({ changedOrder: null });
  };

  isLate = (item: OrderType) => {
    if (item.state === 'ACTIVE' && moment(item.to).isBefore(moment())) {
      return '#FF4444';
    }
    return undefined;
  };

  handleConfrimDialogOpen = (order: OrderType) =>
    this.setState({ confrimDialogOrder: order });
  handleConfrimDialogClose = () => this.setState({ confrimDialogOrder: null });

  sortBy = (name: string) => () => {
    this.setState(oldState => {
      return {
        order: name,
        direction:
          oldState.order === name
            ? oldState.direction === 'asc'
              ? 'desc'
              : 'asc'
            : 'asc',
      };
    });
  };

  handleSearchChange = (event: { target: { value: string } }) => {
    this.setState({ search: event.target.value, activePage: 1 });
  };

  createFollowingReservation = (item: OrderType) => {
    this.setState({ isFollowingOrderModalOpen: true });
    this.setState({ folowingOrder: item });
    this.handleActionMenuOpen(true);
  };

  extendCurrentOrder = (item: OrderType) => {
    this.setState({ isExtendOrderModalOpen: true });
    this.setState({ extendedOrder: item });
    this.handleActionMenuOpen(true);
  };

  changeCurrentOrder = (item: OrderType) => {
    this.setState({ isChangeOrderModalOpen: true });
    this.setState({ changedOrder: item });
    this.handleActionMenuOpen(true);
  };

  handleActionMenuOpen = (isOpen: boolean) => {
    this.setState({ actionMenuOpen: isOpen });
  };

  handleOnDeleteAgree = async () => {
    const { confrimDialogOrder } = this.state;
    if (confrimDialogOrder) {
      const response = await this.props.client.mutate({
        mutation: deleteOrder,
        variables: {
          orderId: confrimDialogOrder.id,
        },
      });

      if (response.data.deleteOrder) {
        this.handleConfrimDialogClose();
      } else {
        window.alert(
          'Nepodařilo se smazat transakci. Prosím zkuste to později.',
        );
      }
    }
  };

  countEditPriceAmount = (order: OrderType) => {
    if (!order || order.price === 0 || order.discount.discount === 100) {
      return 0;
    }

    let price = order.price;
    if (order.discount) {
      let percentRest = (order.price * 100) / order.price;
      if (percentRest === 100) {
        percentRest -= order.discount.discount;
      }

      price = Math.round(
        (order.price * percentRest) / (100 - order.discount.discount),
      );
    }

    return price;
  };

  cancelReservation = async (item: OrderType) => {
    if (item.payments.length) {
      this.setState({ payments: item.payments });
      this.setState({ orderId: item.id });
      this.setState({ isCancelPaymentsModalOpen: true });

      return;
    } else {
      const activeEntity = this.state.activeEntity;
      let state = null;

      if (activeEntity === 'RESERVATION') {
        state = OrderState.RESERVATION_CANCELED;
      } else if (activeEntity === 'ORDER') {
        state = OrderState.CANCELED;
      }

      if (state) {
        await this.changeOrderState(item, state);
      }
    }
    this.handleActionMenuOpen(true);
  };

  changeOrderState = async (item: OrderType, orderState: OrderState) => {
    await this.props.client.mutate({
      mutation: updateState,
      variables: {
        orderId: item.id,
        state: orderState,
      },
      refetchQueries: [
        { query: loadOrders, variables: this.getVariablesForLoadOrdersQuery() },
      ],
    });
  };

  getVariablesForLoadOrdersQuery = () => {
    return {
      offset: this.state.itemsCountPerPage * (this.state.activePage - 1),
      limit: this.state.itemsCountPerPage,
      sort: this.state.order,
      order: this.state.direction,
      search: this.state.search,
      state: getStates(
        this.state.orderFilters,
        this.state.reservationFilters,
        this.state.activeEntity,
      ),
      source: this.state.source,
      unreturned: this.state.orderFilters.unreturned,
    };
  };

  handleEntityChange = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: any,
  ) => {
    event.preventDefault();
    if (value) {
      this.setState({
        activeEntity: value,
        source: value === 'ORDER' ? [] : this.state.source,
      });
    }
  };

  handleSourceChange = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: any,
  ) => {
    event.preventDefault();
    if (value) {
      this.setState({ source: value });
    }
  };

  handleStateChange = (state: string) => {
    if (this.state.activeEntity === 'ORDER') {
      const { orderFilters } = this.state;
      orderFilters[state] = !orderFilters[state];
      this.setState({ orderFilters, activePage: 1 });
    } else {
      const { reservationFilters } = this.state;
      reservationFilters[state] = !reservationFilters[state];
      this.setState({ reservationFilters, activePage: 1 });
    }
  };

  shouldShowReturnBailDialog = (item: OrderType) =>
    item.state === OrderState.PREPARED ||
    item.state === OrderState.RESERVATION_PREPARED ||
    (item.state === OrderState.RESERVATION_CANCELED &&
      item.payments.length > 0);

  handleSubscriptionData = () => {
    if (this.refetch !== undefined) {
      this.refetch();
      this.setState({ updated: new Date() });
    }
    return undefined;
  };

  shouldShowPriceButton = (item: OrderType) => {
    const totalPricePayments = item.payments
      .filter(payment => payment.type === 'PRICE')
      .reduce((sum, payment) => sum + payment.amount, 0);

    const totalBailPayments = item.payments
      .filter(payment => payment.type === 'BAIL')
      .reduce((sum, payment) => sum + payment.amount, 0);

    return !(
      totalPricePayments === item.price && totalBailPayments === item.bail
    );
  };

  render() {
    const { classes } = this.props;
    const isAdmin = auth.isAdmin();
    return (
      <div className="Item">
        <Paper className={classes.paper}>
          <OrdersAndReservationsFilters
            orderFilters={this.state.orderFilters}
            reservationFilters={this.state.reservationFilters}
            activeEntity={this.state.activeEntity}
            handleEntityChange={this.handleEntityChange}
            handleSourceChange={this.handleSourceChange}
            source={this.state.source}
            handleStateCahnge={this.handleStateChange}
            handleSearchChange={this.handleSearchChange}
            search={this.state.search}
          />
          <BailsSums />
          <Subscription
            subscription={ORDER_SUBSCRIPTION}
            onSubscriptionData={this.handleSubscriptionData}
          >
            {({ data }: any) => (
              <Tooltip
                title={
                  data && data.order
                    ? `objednávka ${data.order.id}: ${data.order.type}`
                    : ''
                }
              >
                <div
                  className={classes.actualization}
                  onClick={this.handleSubscriptionData}
                >
                  Aktualizováno:{' '}
                  {moment(this.state.updated).format('DD.MM. HH:mm:ss')}
                </div>
              </Tooltip>
            )}
          </Subscription>
        </Paper>
        <Query
          query={loadOrders}
          fetchPolicy="network-only"
          // pollInterval={2000}
          variables={this.getVariablesForLoadOrdersQuery()}
        >
          {(data: any) => {
            if (data.loading) {
              return <p>Loading...</p>;
            }
            if (data.error) {
              return <p>Error :</p>;
            }
            this.refetch = data.refetch;
            let confrimDialogContent = '';
            const confrimDialogOrder = this.state.confrimDialogOrder;
            if (confrimDialogOrder) {
              confrimDialogContent = `Opravdu chcete smazat objednávku číslo ${
                confrimDialogOrder.id
              } na jméno
                ${confrimDialogOrder.customer.name} ve stavu ${
                orderStateSingularCZ[confrimDialogOrder.state]
              }`;
            }
            return (
              <div>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableCellHead}>
                        <a
                          onClick={this.sortBy('id')}
                          className={this.state.order === 'id' ? 'ordered' : ''}
                        >
                          ID
                        </a>
                      </TableCell>
                      <TableCell className={classes.tableCellHead}>
                        <a
                          onClick={this.sortBy('name')}
                          className={
                            this.state.order === 'name' ? 'ordered' : ''
                          }
                        >
                          Jméno
                        </a>
                      </TableCell>
                      <TableCell className={classes.tableCellHead}>
                        <a
                          onClick={this.sortBy('phone')}
                          className={
                            this.state.order === 'phone' ? 'ordered' : ''
                          }
                        >
                          Telefon
                        </a>
                      </TableCell>
                      <TableCell className={classes.tableCellHead}>
                        <a
                          onClick={this.sortBy('from')}
                          className={
                            this.state.order === 'from' ? 'ordered' : ''
                          }
                        >
                          Od
                        </a>
                      </TableCell>
                      <TableCell className={classes.tableCellHead}>
                        <a
                          onClick={this.sortBy('to')}
                          className={this.state.order === 'to' ? 'ordered' : ''}
                        >
                          Do
                        </a>
                      </TableCell>
                      <TableCell className={classes.tableCellHead}>
                        <a
                          onClick={this.sortBy('state')}
                          className={
                            this.state.order === 'state' ? 'ordered' : ''
                          }
                        >
                          Stav
                        </a>
                      </TableCell>
                      <TableCell className={classes.tableCellHead}>
                        <a
                          onClick={this.sortBy('season')}
                          className={
                            this.state.order === 'season' ? 'ordered' : ''
                          }
                        >
                          Sezóna
                        </a>
                      </TableCell>
                      <TableCell
                        className={classes.tableCellHead}
                        style={{ textAlign: 'center' }}
                      >
                        Záloha
                        <br />
                        (zaplaceno,&nbsp;&nbsp;má&nbsp;zaplatit,&nbsp;&nbsp;vráceno)
                      </TableCell>
                      <TableCell
                        className={classes.tableCellHead}
                        style={{ textAlign: 'center' }}
                      >
                        Cena
                        <br />
                        (zaplaceno,&nbsp;&nbsp;má&nbsp;zaplatit,&nbsp;&nbsp;vráceno)
                      </TableCell>
                      <TableCell
                        className={classes.tableCellHead}
                        style={{ textAlign: 'center' }}
                      >
                        Akce
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.data.orders.items.map((item: OrderType) => (
                      <TableRow
                        key={item.id}
                        style={{
                          backgroundColor:
                            this.isLate(item) || tableRowColor[item.state],
                        }}
                      >
                        <TableCell className={classes.tableCell}>
                          <div className="pricesCell">
                            {item.overbooked.length > 0 && (
                              <Tooltip
                                classes={{ tooltip: classes.customWidth }}
                                title={
                                  <>
                                    <div>
                                      Pro tuto objednávku nemáte na skladě
                                      dostatek následujících položek:{' '}
                                    </div>{' '}
                                    <br />{' '}
                                    <div>
                                      {(item.overbooked || [])
                                        .map(
                                          (c: any) =>
                                            `${c.id}: ${c.producer} ${c.name}, ${c.size}`,
                                        )
                                        .join('; ')}
                                    </div>
                                  </>
                                }
                              >
                                <Warning />
                              </Tooltip>
                            )}
                            {
                              <Link to={`order-detail/${item.id}`}>
                                {item.id}
                              </Link>
                            }
                          </div>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {item.customer.name}
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          {stylePhoneNumber(item.customer.phone)}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {new Date(item.from).toLocaleDateString()}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {new Date(item.to).toLocaleDateString()}
                        </TableCell>
                        <Tooltip
                          classes={{ tooltip: classes.customWidth }}
                          title={
                            item.orderOriginator === 'ONLINE_RESERVATION' && (
                              <>
                                <strong>Doručení:</strong>
                                <br />
                                <Box className={classes.detail}>
                                  {item.deliveryAddress ? (
                                    Object.entries(item.deliveryAddress)
                                      .filter(([key]) =>
                                        Object.keys(addressToCZ).includes(key),
                                      )
                                      .map(([key, value], i) => (
                                        <div
                                          key={`${item.deliveryAddress?.id}_${i}`}
                                        >
                                          {addressToCZ[key]}: {value}{' '}
                                        </div>
                                      ))
                                  ) : (
                                    <div> Vyzvednutí na prodejně</div>
                                  )}
                                </Box>
                                <br />
                                <strong>Údaje o zákazníkovy:</strong>
                                <br />
                                {item.sets
                                  .filter(set => set.client)
                                  .map(s => (
                                    <Box className={classes.detail} key={s.id}>
                                      <div>{s.category}</div>
                                      <Box className={classes.detail}>
                                        {Object.entries(s.client)
                                          .filter(([key]) =>
                                            Object.keys(
                                              clientParamsToCZ,
                                            ).includes(key),
                                          )
                                          .map(([key, value], i) => (
                                            <div key={`${s.id}_${i}`}>
                                              {clientParamsToCZ[key]}:{' '}
                                              {value || '?'}{' '}
                                              {value && getUnits(key)}
                                            </div>
                                          ))}
                                      </Box>
                                    </Box>
                                  ))}
                              </>
                            )
                          }
                        >
                          <TableCell className={classes.tableCell}>
                            {orderStateSingularCZ[item.state]}
                          </TableCell>
                        </Tooltip>
                        <TableCell className={classes.tableCell}>
                          {item.season ? 'ano' : 'ne'}
                        </TableCell>
                        <Tooltip
                          classes={{ tooltip: classes.customWidth }}
                          title={item.payments
                            .filter(
                              p => p.type === 'BAIL' || p.type === 'RETURN',
                            )
                            .map(
                              p =>
                                new Date(p.createdAt).toLocaleDateString() +
                                ' ' +
                                paymentTypeCZ[p.type] +
                                ': ' +
                                p.amount +
                                'CZK' +
                                '\n',
                            )
                            .join(', ')}
                        >
                          <TableCell
                            className={classes.tableCell}
                            style={{ textAlign: 'right' }}
                          >
                            <div
                              className="pricesCell"
                              style={{
                                marginRight:
                                  item.state === 'FINISHED' ? '0px' : '28px',
                              }}
                            >
                              <div style={{ width: '65px' }}>
                                {item.payments
                                  .filter(p => p.type === 'BAIL')
                                  .map(p => p.amount)
                                  .reduce((a, b) => a + b, 0)
                                  .toLocaleString('FR')}
                              </div>
                              <div style={{ width: '65px' }}>
                                {item.bail
                                  ? item.withoutBail
                                    ? 0
                                    : item.bail.toLocaleString('FR')
                                  : 0}
                              </div>
                              <div style={{ width: '65px' }}>
                                {item.payments
                                  .filter(p => p.type === 'RETURN')
                                  .map(p => p.amount)
                                  .reduce((a, b) => a + b, 0)
                                  .toLocaleString('FR')}
                              </div>
                              {item.state === 'FINISHED' ||
                              item.payments
                                .filter(p => p.type === 'PRICE')
                                .map(p => p.amount)
                                .reduce((a, b) => a + b, 0) > item.price ||
                              item.payments
                                .filter(p => p.type === 'BAIL')
                                .map(p => p.amount)
                                .reduce((a, b) => a + b, 0) > item.bail ? (
                                <AccountBalanceWallet
                                  className="icon"
                                  onClick={this.handleBailOpen(item.id)}
                                />
                              ) : (
                                ''
                              )}
                            </div>
                          </TableCell>
                        </Tooltip>
                        <Tooltip
                          classes={{ tooltip: classes.customWidth }}
                          title={item.payments
                            .filter(p => p.type === 'PRICE')
                            .map(
                              p =>
                                new Date(p.createdAt).toLocaleDateString() +
                                ' ' +
                                paymentTypeCZ[p.type] +
                                ': ' +
                                p.amount +
                                'CZK' +
                                '\n',
                            )
                            .join(', ')}
                        >
                          <TableCell
                            className={classes.tableCell}
                            style={{ textAlign: 'right' }}
                          >
                            <div
                              className="pricesCell"
                              style={{
                                marginRight: this.shouldShowReturnBailDialog(
                                  item,
                                )
                                  ? '0px'
                                  : '28px',
                              }}
                            >
                              <div style={{ width: '65px' }}>
                                {item.payments
                                  .filter(p => p.type === 'PRICE')
                                  .map(p => p.amount)
                                  .reduce((a, b) => a + b, 0)
                                  .toLocaleString('FR')}
                              </div>
                              <div style={{ width: '65px' }}>
                                {Math.round(
                                  (this.countEditPriceAmount(item) / 100) *
                                    (100 - item.discount.discount),
                                ).toLocaleString('FR')}
                              </div>
                              <div style={{ width: '65px' }}>
                                {item.payments
                                  .filter(p => p.type === 'RETURN_PRICE')
                                  .map(p => p.amount)
                                  .reduce((a, b) => a + b, 0)
                                  .toLocaleString('FR')}
                              </div>
                              {!(
                                item.state === OrderState.CANCELED ||
                                item.state === OrderState.FINISHED ||
                                item.state === OrderState.ACTIVE ||
                                item.state === OrderState.RESERVATION_CANCELED
                              ) && (
                                <AccountBalanceWallet
                                  className="icon"
                                  onClick={this.handlePriceOpen(item.id)}
                                />
                              )}
                              {item.state === OrderState.RESERVATION_CANCELED &&
                                item.payments.length > 0 && (
                                  <AccountBalanceWallet
                                    className="icon"
                                    onClick={this.handleBailOpen(item.id)}
                                  />
                                )}
                            </div>
                          </TableCell>
                        </Tooltip>
                        <TableCell
                          className={classes.tableCell}
                          style={{ width: '60px' }}
                        >
                          <ContextMenu
                            open={this.state.actionMenuOpen}
                            setMenuOpen={this.handleActionMenuOpen}
                          >
                            {item.state === 'PREPARED' ||
                            item.state === 'FINISHED' ||
                            item.state === 'RESERVATION_PAID' ||
                            item.state === 'ACTIVE' ? (
                              <MenuItem
                                onClick={() => {
                                  downloadPDF(item.id, true);
                                  this.handleActionMenuOpen(true);
                                }}
                              >
                                <ListItemIcon>
                                  <CloudDownload fontSize="small" />
                                </ListItemIcon>

                                <ListItemText primary="Stáhnout PDF" />
                              </MenuItem>
                            ) : null}
                            <MenuItem
                              disabled={!isAdmin && item.payments.length > 0}
                              onClick={() => this.handleConfrimDialogOpen(item)}
                            >
                              <ListItemIcon>
                                <DeleteIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText primary="Smazat objednávku" />
                            </MenuItem>

                            {isAdmin &&
                              !(
                                item.state === OrderState.ACTIVE ||
                                item.state === OrderState.FINISHED ||
                                item.state === OrderState.CANCELED ||
                                item.state ===
                                  OrderState.RESERVATION_CANCELED ||
                                item.state === OrderState.RESERVATION_PAID
                              ) && (
                                <MenuItem
                                  title={'Objednávka bude stornovaná'}
                                  onClick={() =>
                                    window.confirm(
                                      `Opravdu chcete stornovat ${
                                        this.state.activeEntity ===
                                        'RESERVATION'
                                          ? 'rezervaci'
                                          : 'objednávku'
                                      }?`,
                                    ) && this.cancelReservation(item)
                                  }
                                >
                                  <ListItemIcon>
                                    <Cancel fontSize="small" />
                                  </ListItemIcon>
                                  <ListItemText primary="Stornovat rezervaci" />
                                </MenuItem>
                              )}

                            {this.state.activeEntity === 'ORDER' &&
                              item.season === false &&
                              item.state === OrderState.ACTIVE && (
                                <MenuItem
                                  title={'Objednávka bude prodloužena'}
                                  onClick={() =>
                                    window.confirm(
                                      'Opravdu chcete objednávku prodloužit?',
                                    ) && this.extendCurrentOrder(item)
                                  }
                                >
                                  <ListItemIcon>
                                    <RefreshIcon fontSize="small" />
                                  </ListItemIcon>
                                  <ListItemText primary="Prodloužit objednávku" />
                                </MenuItem>
                              )}

                            {isAdmin &&
                              this.state.activeEntity === 'ORDER' &&
                              item.state === OrderState.ACTIVE && (
                                <MenuItem
                                  title={'Objednávka bude stornovaná'}
                                  onClick={() =>
                                    window.confirm(
                                      'Opravdu chcete vrátit objednávku do stavu připravená?',
                                    ) &&
                                    this.changeOrderState(
                                      item,
                                      OrderState.PREPARED,
                                    )
                                  }
                                >
                                  <ListItemIcon>
                                    <SwapHorizIcon fontSize="small" />
                                  </ListItemIcon>
                                  <ListItemText primary="Vrátit do přípravy" />
                                </MenuItem>
                              )}

                            {(item.state === OrderState.ACTIVE ||
                              item.state === OrderState.FINISHED) && (
                              <MenuItem
                                onClick={() =>
                                  window.confirm(
                                    'Opravdu chcete vytvořit návaznou objednávku?',
                                  ) && this.createFollowingReservation(item)
                                }
                              >
                                <ListItemIcon>
                                  <AddIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary="Návazná objednávka" />
                              </MenuItem>
                            )}

                            {(item.state === OrderState.ACTIVE ||
                              item.state === OrderState.PREPARED) && (
                              <MenuItem
                                title={'Objednávka bude změněná'}
                                onClick={() => this.changeCurrentOrder(item)}
                              >
                                <ListItemIcon>
                                  <AutorenewIcon fontSize="small" />
                                </ListItemIcon>
                                <Link
                                  to={`order-change/${item.id}`}
                                  style={{ textDecoration: 'none' }}
                                >
                                  <ListItemText primary="Změnit objednávku" />
                                </Link>
                              </MenuItem>
                            )}
                          </ContextMenu>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                {data.data.orders.totalCount > this.state.itemsCountPerPage ? (
                  <div style={{ float: 'right' }}>
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={this.state.itemsCountPerPage}
                      totalItemsCount={data.data.orders.totalCount}
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange}
                    />
                  </div>
                ) : (
                  <div />
                )}
                <div className="addButton" style={{ marginTop: '80px' }}>
                  <Link to={`add-new/reservation`}>
                    <Tooltip title="Vytvořit rezervaci">
                      <Fab color="secondary" style={{ marginRight: 32 }}>
                        <AddIcon />
                      </Fab>
                    </Tooltip>
                  </Link>
                  <Link to={`add-new/order`}>
                    <Tooltip title="Vytvořit objednávku">
                      <Fab color="primary">
                        <AddIcon />
                      </Fab>
                    </Tooltip>
                  </Link>
                </div>
                {this.state.confrimDialogOrder && (
                  <ConfirmDialog
                    open={true}
                    onClose={this.handleConfrimDialogClose}
                    onAgree={this.handleOnDeleteAgree}
                    titleLabel="Mazání objednávky"
                    dialogContent={confrimDialogContent}
                  />
                )}
              </div>
            );
          }}
        </Query>
        {this.state.priceDialogOpen > 0 && (
          <PriceDialog
            orderId={this.state.priceDialogOpen}
            open={true}
            handleClose={this.handlePriceClose}
            loadOrdersVariables={this.getVariablesForLoadOrdersQuery()}
          />
        )}
        {this.state.bailDialogOpen > 0 && (
          <BailDialog
            open={true}
            id={this.state.bailDialogOpen}
            handleClose={this.handleBailClose}
            loadOrdersVariables={this.getVariablesForLoadOrdersQuery()}
          />
        )}
        <CancelPaymentsModal
          orderId={this.state.orderId}
          isOpen={this.state.isCancelPaymentsModalOpen}
          onClose={this.handleCancelPaymentsModalClose}
          payments={this.state.payments}
        />
        {this.state.extendedOrder && (
          <ExtendOrderModal
            isOpen={this.state.isExtendOrderModalOpen}
            order={this.state.extendedOrder}
            onClose={this.handleExtendOrderModalClose}
            loadOrdersVariables={this.getVariablesForLoadOrdersQuery()}
          />
        )}
        {this.state.folowingOrder && (
          <FollowingReservationModal
            isOpen={this.state.isFollowingOrderModalOpen}
            order={this.state.folowingOrder}
            onClose={this.handleFollowingOrderModalClose}
            loadOrdersVariables={this.getVariablesForLoadOrdersQuery()}
          />
        )}
      </div>
    );
  }
}

const styles = (theme: any) => ({
  paper: {
    padding: 16,
    display: 'flex',
    justifyContent: 'space-around',
    flexFlow: 'wrap',
    position: 'relative' as 'relative',
    paddingBottom: 40,
  },
  customWidth: {
    maxWidth: 190,
  },
  datePicker: {
    marginLeft: 64,
    marginRight: 32,
  },
  right: {
    paddingRight: 15,
  },
  tableCellHead: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.75rem',
    padding: '4px 24px 4px 24px;',
  },
  tableCell: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '14px',
    padding: '8px 24px 8px 24px',
  },
  detail: {
    marginLeft: '6px',
  },
  actualization: {
    position: 'absolute' as 'absolute',
    right: '10px',
    bottom: '10px',
    cursor: 'pointer',
  },
});

const OrderListWithApolloStyles = withApollo(withStyles(styles)(OrderList));

const WrapperComponent: React.FunctionComponent<RouteComponentProps> = () => (
  <OrderListWithApolloStyles />
);

export default WrapperComponent;
