import React, { useState } from 'react';
import Modal from '../../components/Modal';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  makeStyles,
  createStyles,
  Theme,
  Button,
} from '@material-ui/core';
import {
  RecordType,
  AddRecordToCashRegisterVariables,
  AddRecordToCashRegister,
} from '../../graphql/types';
import { useMutation } from '@apollo/react-hooks';
import {
  addRecordToCashRegister,
  getCashRegister,
} from '../../graphql/queries';
import { useParams } from '@reach/router';
import { getGraphqlErrorMessage } from '../../lib/utils';
import { useSnackbar } from 'notistack';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const AddRecordModal = ({ isOpen, onClose }: Props) => {
  const params = useParams();
  const [type, setType] = useState('INCOME');
  const [amount, setAmount] = useState('');
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [addRecord] = useMutation<
    AddRecordToCashRegister,
    AddRecordToCashRegisterVariables
  >(addRecordToCashRegister, {
    onError: error => {
      enqueueSnackbar(getGraphqlErrorMessage(error), { variant: 'error' });
    },
  });

  const handleAddRecord = async () => {
    await addRecord({
      variables: {
        recordType: type as RecordType,
        amount: Number(amount),
      },
      refetchQueries: [
        {
          query: getCashRegister,
          variables: { cashRegisterRecordId: params.id },
        },
      ],
    });
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Přidat záznam">
      <FormControl className={classes.form}>
        <InputLabel id="demo-simple-select-label">Typ záznamu</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={type}
          onChange={e => setType(e.target.value as 'INCOME' | 'EXPENDITURE')}
        >
          <MenuItem value="INCOME">PŘÍJEM</MenuItem>
          <MenuItem value="EXPENDITURE">VÝDAJ</MenuItem>
        </Select>
        <TextField
          label="Suma (CZK)"
          className={classes.priceInput}
          type="number"
          onChange={e => setAmount(e.target.value)}
          value={amount}
        />
        <Button
          variant="contained"
          color="secondary"
          disabled={!amount.length}
          onClick={handleAddRecord}
          className={classes.saveButt}
        >
          Uložit
        </Button>
      </FormControl>
    </Modal>
  );
};

export default AddRecordModal;

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    priceInput: {
      marginLeft: 24,
    },
    form: {
      display: 'flex',
      flexDirection: 'row',
    },
    saveButt: {
      marginLeft: 24,
    },
  }),
);
