import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  Fab,
  FormControlLabel,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { RouteComponentProps } from '@reach/router';
import cs from 'date-fns/locale/cs';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { loadClosedDays } from '../../../graphql/queries';

import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery } from 'react-apollo';
import ConfirmDialog from '../../../components/ConfirmDialog';
import {
  addClosedDay as addClosedDayQuery,
  deleteClosedDay as deleteClosedDayQuery,
} from '../../../graphql/queries';
import {
  addClosedDay as AddClosedDayType,
  ClosedDays as ClosedDaysResponse,
  deleteClosedDay as DeleteClosedDayType,
  RecurringType,
  addClosedDayVariables,
  deleteClosedDayVariables,
} from '../../../graphql/types';
import { getGraphqlErrorMessage } from '../../../lib/utils';

interface Props extends RouteComponentProps {}

const defaultFormData = {
  checked: false,
  selectedDateFrom: new Date(),
  selectedDateTo: new Date(),
  recurring: false,
  note: '',
};

const ClosedDays: React.FC<Props> = props => {
  const [dialogMode, setDialogMode] = useState(false);
  const [showError, setShowError] = useState(false);
  const [validForm, setValidForm] = useState(true);
  const [formData, setFormData] = useState(defaultFormData);
  const [
    selectedDateTo,
    handleDateChangeTo,
  ] = useState<MaterialUiPickersDate | null>(null);

  const handleInputChange = (name: string) => (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const value =
      event.target.type === 'checkbox'
        ? (event.target as HTMLInputElement).checked
        : event.target.value;
    setFormData(formData => ({
      ...formData,
      [name]: value,
    }));

    if (
      'checked' === name &&
      (event.target as HTMLInputElement).checked &&
      !selectedDateTo
    ) {
      setValidForm(false);
    } else {
      setValidForm(true);
      setFormData(formData => ({
        ...formData,
        selectedDateTo: selectedDateTo ?? formData.selectedDateFrom,
      }));
    }
  };

  const handleDateChange = (name: string) => (date: MaterialUiPickersDate) => {
    setFormData(formData => ({
      ...formData,
      [name]: date,
    }));

    if (name === 'selectedDateTo') {
      handleDateChangeTo(date);
    }

    if (name === 'selectedDateFrom' && !formData.checked && null !== date) {
      setFormData(formData => ({
        ...formData,
        selectedDateTo: date,
      }));
    }

    if (formData.checked && null !== date && date < formData.selectedDateFrom) {
      handleDateChangeTo(date);
      setFormData(formData => ({
        ...formData,
        selectedDateTo: date,
      }));
      setValidForm(false);
      setShowError(true);
    }
  };

  const [deleteRow, setDeleteRow] = React.useState(0);

  useEffect(() => {
    if (
      new Date(
        Date.UTC(
          formData.selectedDateFrom.getFullYear(),
          formData.selectedDateFrom.getMonth(),
          formData.selectedDateFrom.getDate(),
        ),
      ).getTime() <
      new Date(
        Date.UTC(
          formData.selectedDateTo.getFullYear(),
          formData.selectedDateTo.getMonth(),
          formData.selectedDateTo.getDate(),
        ),
      ).getTime()
    ) {
      setValidForm(true);
      setShowError(false);
    }

    if (!formData.checked) {
      setFormData(formData => ({
        ...formData,
        selectedDateTo: formData.selectedDateFrom,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.selectedDateTo]);

  const { enqueueSnackbar } = useSnackbar();

  const [addClosedDay] = useMutation<AddClosedDayType, addClosedDayVariables>(
    addClosedDayQuery,
    {
      onError: err =>
        enqueueSnackbar(getGraphqlErrorMessage(err), { variant: 'error' }),
    },
  );

  const [deleteClosedDay] = useMutation<
    DeleteClosedDayType,
    deleteClosedDayVariables
  >(deleteClosedDayQuery, {
    onError: err =>
      enqueueSnackbar(getGraphqlErrorMessage(err), { variant: 'error' }),
  });

  const formatDate = (date: Date, format = 'D. M. YYYY') => {
    return moment(date)
      .utc()
      .format(format);
  };

  const handleSave = async () => {
    await addClosedDay({
      variables: {
        from: moment(formData.selectedDateFrom)
          .utc(true)
          .startOf('day'),
        to: moment(formData.selectedDateTo)
          .utc(true)
          .endOf('day'),
        recurring: formData.recurring
          ? RecurringType.YEARLY
          : RecurringType.ONCE,
        note: formData.note,
      },
      refetchQueries: [
        {
          query: loadClosedDays,
        },
      ],
    });
    setFormData(defaultFormData);
    setDialogMode(false);
    handleDateChangeTo(null);
  };

  const handleDelete = async () => {
    await deleteClosedDay({
      variables: {
        id: deleteRow,
      },
      refetchQueries: [
        {
          query: loadClosedDays,
        },
      ],
    });
    setDeleteRow(0);
  };

  const data = useQuery<ClosedDaysResponse>(loadClosedDays);
  if (!data.data) return null;

  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Zavřeno od</TableCell>
            <TableCell>Zavřeno do</TableCell>
            <TableCell>Opakování</TableCell>
            <TableCell>Poznámka</TableCell>
            <TableCell>Smazání</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.data?.closedDays?.map(closedDay => (
            <TableRow key={closedDay?.id}>
              <TableCell>{closedDay?.id}</TableCell>
              <TableCell>{formatDate(closedDay?.from)}</TableCell>
              <TableCell>{formatDate(closedDay?.to)}</TableCell>
              <TableCell>
                {closedDay?.recurring === RecurringType.ONCE
                  ? 'Pouze ve zvolené datum'
                  : 'Každý rok'}
              </TableCell>
              <TableCell>{closedDay?.note}</TableCell>
              <TableCell>
                <Button onClick={() => setDeleteRow(closedDay?.id || 0)}>
                  <DeleteIcon />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className="addButton" style={{ marginTop: '80px' }}>
        <Tooltip title="Přidat">
          <Fab color="primary" onClick={() => setDialogMode(true)}>
            <AddIcon />
          </Fab>
        </Tooltip>
      </div>
      <Dialog
        aria-labelledby="simple-dialog-title"
        fullWidth={false}
        maxWidth={false}
        open={dialogMode}
      >
        <DialogTitle>Nový záznam</DialogTitle>
        <div className="diablogWrapper">
          <div className="dialogRowEmp">
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cs}>
              <Grid container style={{ padding: '2rem' }}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.checked}
                        onChange={handleInputChange('checked')}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    }
                    label="Nastavit období"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-from"
                    label="Zavřeno od"
                    format="dd.MM.yyyy"
                    value={formData.selectedDateFrom}
                    onChange={handleDateChange('selectedDateFrom')}
                    KeyboardButtonProps={{
                      'aria-label': 'vyberte datum',
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  {formData.checked ? (
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-to"
                      label="Zavřeno do"
                      format="dd.MM.yyyy"
                      value={selectedDateTo}
                      onChange={handleDateChange('selectedDateTo')}
                      KeyboardButtonProps={{
                        'aria-label': 'vyberte datum',
                      }}
                    />
                  ) : null}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.recurring}
                        onChange={handleInputChange('recurring')}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    }
                    label="Opakuje se každý rok"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Poznámka"
                    value={formData.note}
                    onChange={handleInputChange('note')}
                  />
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
          </div>
          {showError ? (
            <Typography align="center" color="error">
              Datum do nesmí být menší než datum od
            </Typography>
          ) : null}

          <DialogActions style={{ justifyContent: 'center' }}>
            <Button
              onClick={handleSave}
              variant="contained"
              color="primary"
              disabled={!validForm}
            >
              Uložit
            </Button>
            <Button onClick={() => setDialogMode(false)} color="secondary">
              Zavřít
            </Button>
          </DialogActions>
        </div>
      </Dialog>
      {!!deleteRow && (
        <ConfirmDialog
          open={true}
          onClose={() => setDeleteRow(0)}
          onAgree={handleDelete}
          titleLabel="Mazání záznamu"
          dialogContent={`Opravdu chcete smazat záznam číslo ${deleteRow}`}
        />
      )}
    </div>
  );
};

export default ClosedDays;
