import {
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  FormControl,
  FormControlLabel,
} from '@material-ui/core';
import { sortBy } from 'rambda';
import * as R from 'rambda';
import * as React from 'react';
import { graphql, Mutation, withApollo } from 'react-apollo';
import {
  allowedCategories as allowedCategoriesQuery,
  categories as categoriesQuery,
  setAllowedCategories,
  setPrices,
} from '../graphql/queries';
import { Category } from '../lib/types';
import { flowRight as compose } from 'lodash';

type IProps = {
  title: string;
  onClose: () => any;
  classes?: string[];
  open: boolean;
  setPriceId: number;
  client: any;
  categories: any;
  allowedCategories: any;
};

type IState = {
  categories: Category[] | null;
  allowedCategories: number[] | null;
};

class AllowedCategoriesDialog extends React.Component<IProps, IState> {
  static getDerivedStateFromProps(nextProps: IProps, prevState: IState) {
    const { categories, allowedCategories } = prevState;
    const loading =
      !nextProps.categories.loading && !nextProps.allowedCategories.loading;
    if (loading && !categories && !allowedCategories) {
      const allowedCategoriesNumbers = nextProps.allowedCategories.allowedCategories.map(
        (category: Category) => category.id,
      );
      return {
        allowedCategories: allowedCategoriesNumbers,
        categories: nextProps.categories.categories,
      };
    } else {
      return null;
    }
  }

  state: IState = {
    allowedCategories: null,
    categories: null,
  };

  handleClose = () => {
    this.props.onClose();
  };

  handleListItemClick = (value: any) => {
    this.props.onClose();
  };

  handleChange = (categoryId: number) => (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    let { allowedCategories } = this.state;
    if (allowedCategories) {
      if (checked) {
        allowedCategories = [...allowedCategories, categoryId];
      } else {
        allowedCategories = allowedCategories.filter(id => id !== categoryId);
      }
    }
    this.setState({ allowedCategories });
  };

  render() {
    const { title, classes, onClose, setPriceId, ...other } = this.props;

    const { categories, allowedCategories } = this.state;

    const loading = !(
      !this.props.categories.loading && !this.props.allowedCategories.loading
    );

    return (
      <Dialog
        onClose={this.handleClose}
        aria-labelledby="simple-dialog-title"
        {...other}
        fullWidth={true}
      >
        <DialogTitle id="simple-dialog-title">
          {`Nastavit povolené kategorie u ${title}`}
        </DialogTitle>
        {loading && allowedCategories ? (
          'Loading ...'
        ) : (
          <div className="content">
            <FormControl className="text-field full-width">
              {categories &&
                allowedCategories &&
                sortBy(R.compose(R.toLower, R.prop('name')))(categories).map(
                  (category: Category) => {
                    return (
                      <FormControlLabel
                        className="checkbox-label"
                        key={category.id}
                        control={
                          <Checkbox
                            checked={
                              allowedCategories.indexOf(category.id) !== -1
                            }
                            onChange={this.handleChange(category.id)}
                            value={category.id.toString()}
                            color="primary"
                          />
                        }
                        label={category.name}
                      />
                    );
                  },
                )}
            </FormControl>
            <Mutation
              mutation={setAllowedCategories}
              variables={{
                allowedCategories: this.state.allowedCategories,
                setPriceId,
              }}
              refetchQueries={[{ query: setPrices }]}
              onCompleted={this.handleClose}
              // onError={this.mutationError}
            >
              {(postMutation: any) => (
                <Button
                  className="save-button"
                  variant="contained"
                  color="primary"
                  onClick={postMutation}
                >
                  Nastavit
                </Button>
              )}
            </Mutation>
          </div>
        )}
      </Dialog>
    );
  }
}

export default compose(
  withApollo,
  graphql(categoriesQuery, { name: 'categories' }),
  graphql(allowedCategoriesQuery, {
    name: 'allowedCategories',
    options: (props: IProps) => ({
      variables: { setPriceId: props.setPriceId },
      fetchPolicy: 'network-only',
    }),
  }),
)(AllowedCategoriesDialog);
