import { CatalogItemsSizeIndependentInput } from '../../../graphql/types';
import ApolloClient from 'apollo-client';
import {
  GET_INPUT_WITH_CATALOG_ITEM_ID,
  GET_INPUT_WITHOUT_CATALOG_ITEM_ID,
} from '../../../graphql/localQueries';
import { MutationFunctionOptions, ExecutionResult } from 'react-apollo';
import { catalogItemsInCatalogGroupQuery } from '../../../graphql/queries';

export function writeInputToCache(
  client: ApolloClient<object>,
  input: CatalogItemsSizeIndependentInput,
) {
  const keys = [
    'limit',
    'offset',
    'sort',
    'order',
    'search',
    'catalogGroupId',
    'withoutCatalogGroup',
  ];
  const newInput: CatalogItemsSizeIndependentInput = {};
  keys.forEach(key => {
    newInput[key] = input[key] !== undefined ? input[key] : null;
  });
  const data = input.catalogGroupId
    ? {
        inputWithCatalogItemID: {
          ...newInput,
          __typename: 'InputWithCatalogItemID',
        },
      }
    : {
        inputWithoutCatalogItemID: {
          ...newInput,
          __typename: 'InputWithoutCatalogItemID',
        },
      };
  client.writeData({ data });
}

export function clean(obj: {}) {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
  return obj;
}

export function handleMutation<TMutation, TVars>(
  client: ApolloClient<object>,
  variables: TVars,
  mutation: (
    options?: MutationFunctionOptions<TMutation, TVars> | undefined,
  ) => Promise<ExecutionResult<TMutation>>,
) {
  const inputWith = client.readQuery({
    query: GET_INPUT_WITH_CATALOG_ITEM_ID,
  });
  const inputWithout = client.readQuery({
    query: GET_INPUT_WITHOUT_CATALOG_ITEM_ID,
  });

  if (inputWith && inputWithout) {
    delete inputWith.inputWithCatalogItemID.__typename;
    delete inputWithout.inputWithoutCatalogItemID.__typename;
    mutation({
      variables,
      refetchQueries: [
        {
          query: catalogItemsInCatalogGroupQuery,
          variables: { input: clean(inputWith.inputWithCatalogItemID) },
        },
        {
          query: catalogItemsInCatalogGroupQuery,
          variables: {
            input: clean(inputWithout.inputWithoutCatalogItemID),
          },
        },
      ],
    });
  }
}
