import { Router } from '@reach/router';
import React from 'react';
import { NewOrder, OrderChange, OrderDetail } from '..';
import OrdersAndReservationsList from './OrdersAndReservationsList';

const OrdersAndReservations = () => (
  <Router>
    <OrdersAndReservationsList path="/" />
    <NewOrder path="add-new/:entity" />
    <OrderDetail path="order-detail/:orderId" />
    <OrderChange path="order-change/:orderId" />
  </Router>
);

export default OrdersAndReservations;
