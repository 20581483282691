import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles,
} from '@material-ui/core';
import * as React from 'react';

type IProps = {
  open: boolean;
  onClose: () => void;
};

class UnpaidPaymentDialog extends React.Component<IProps> {
  render() {
    const { open, onClose } = this.props;
    return (
      <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="unpiaud-payment-dialog-title">
          Nebyla zadána platba
        </DialogTitle>
        <DialogContent>Před vydáním musíte zadat platbu.</DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const styles = (theme: any) => ({
  textField: {
    margin: '16px',
    width: '85px',
  },
  button: {
    marginLeft: '16px',
  },
  dialogActions: {
    justifyContent: 'space-between',
  },
});

export default withStyles(styles)(UnpaidPaymentDialog);
