import { TableCell } from '@material-ui/core';
import * as React from 'react';
import { navigate } from '@reach/router';

interface IProps {
  catalogItemId: number;
  textAlign:
    | 'end'
    | 'inherit'
    | 'initial'
    | 'center'
    | '-moz-initial'
    | 'revert'
    | 'unset'
    | 'justify'
    | 'left'
    | 'match-parent'
    | 'right'
    | 'start'
    | undefined;
}

class ItemCell extends React.Component<IProps> {
  render() {
    return (
      <TableCell
        onClick={() =>
          navigate(`/catalog/items/item-detail/${this.props.catalogItemId}`)
        }
        style={{ textAlign: this.props.textAlign, cursor: 'pointer' }}
        title={this.props.catalogItemId.toString()}
      >
        {this.props.children}
      </TableCell>
    );
  }
}

export default ItemCell;
