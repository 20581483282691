import { PagesNames } from './enums';
import { CatalogPageState, OrderPageState, PagesStateType } from './types';

const STATE_KEY = '@CZSKI/state';

export const CatalogStateDefault: CatalogPageState = {
  activePage: 1,
  direction: 'asc',
  order: 'name',
  barCode: '',
  search: '',
  itemsCountPerPage: 12,
};

export const OrderStateDefault: OrderPageState = {
  activePage: 1,
  direction: 'desc',
  order: 'id',
  search: '',
  itemsCountPerPage: 12,
  orderFilters: {
    new: false,
    prepared: false,
    active: false,
    finished: false,
    unreturned: false,
    canceled: false,
  },
  reservationFilters: {
    new: false,
    prepared: false,
    paid: false,
    canceled: false,
  },
  activeEntity: 'ORDER',
  source: ['ADMINISTRATION', 'ONLINE_RESERVATION'],
};

class PagesState {
  pagesState: PagesStateType | null = null;

  setPageState(pageName: PagesNames, state: any) {
    if (this.pagesState !== null) {
      this.pagesState[pageName] = state;
      this.savePagesState(this.pagesState);
    }
  }

  getPageState(pageName: PagesNames) {
    this.loadPagesState();
    if (this.pagesState !== null) {
      return this.pagesState[pageName];
    }
    return null;
  }

  initialization() {
    const states: PagesStateType = {
      Catalog: CatalogStateDefault,
      Order: OrderStateDefault,
    };
    this.savePagesState(states);
  }

  private savePagesState(state: PagesStateType) {
    if (pagesState === null) {
      throw new Error('Empty Page state');
    }
    localStorage.setItem(STATE_KEY, JSON.stringify({ state }));
  }

  private loadPagesState() {
    const state = localStorage.getItem(STATE_KEY);
    if (state !== null) {
      this.pagesState = JSON.parse(state).state;
    }
  }
}

const pagesState = new PagesState();
export default pagesState;
