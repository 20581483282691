import gql from 'graphql-tag';

export const ORDER_SUBSCRIPTION = gql`
  subscription OrderChange {
    order {
      id
      type
    }
  }
`;
