import { RouteComponentProps } from '@reach/router';
import * as React from 'react';
import { withApollo, useQuery } from 'react-apollo';
import { stockUsageQuery } from '../../graphql/queries';
import { StockUsageQuery, StockUsageQueryVariables } from '../../graphql/types';
import {
  Box,
  Button,
  Table,
  TextField,
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  withStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Pagination from 'react-js-pagination';

const PAGE_SIZE = 10;

interface IProps {
  classes?: any;
  client: any;
}

const styles = (theme: any) => ({
  datePicker: {
    marginLeft: 10,
    marginRight: 10,
  },
  pagination: {
    display: 'flex',
    justifyContent: 'end',
    '& .pagination': {
      width: 'unset',
    },
  },
});

const CategoryEarnings = ({ classes }: IProps) => {
  const [page, setPage] = React.useState(1);
  const [searchCode, setSearchCode] = React.useState<string | null>(null);
  const [searchCatalog, setSearchCatalog] = React.useState<string | null>(null);
  const [searchSize, setSearchSize] = React.useState<string | null>(null);
  const [params, setParams] = React.useState<{
    searchCode?: string | null;
    searchSize?: string | null;
    searchCatalog?: string | null;
  }>({});
  const { data } = useQuery<StockUsageQuery, StockUsageQueryVariables>(
    stockUsageQuery,
    {
      variables: {
        limit: PAGE_SIZE,
        offset: Math.max(0, page - 1) * PAGE_SIZE,
        searchCode: params.searchCode,
        searchCatalog: params.searchCatalog,
        searchSize: params.searchSize,
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  const search = () => {
    setParams({
      searchCode: searchCode || undefined,
      searchCatalog: searchCatalog || undefined,
      searchSize: searchSize || undefined,
    });
  };
  return (
    <Box>
      <Box display="flex" flex={1} alignItems="center" marginBottom="24px">
        <TextField
          label="Kód položky"
          value={searchCode}
          className={classes.datePicker}
          onChange={event => setSearchCode(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: (
              <IconButton size="small" onClick={event => setSearchCode('')}>
                <CloseIcon />
              </IconButton>
            ),
          }}
        />
        <TextField
          label="Název kategorie"
          value={searchCatalog}
          className={classes.datePicker}
          onChange={event => setSearchCatalog(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: (
              <IconButton size="small" onClick={event => setSearchCatalog('')}>
                <CloseIcon />
              </IconButton>
            ),
          }}
        />
        <TextField
          label="Velikost"
          value={searchSize}
          className={classes.datePicker}
          onChange={event => setSearchSize(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: (
              <IconButton size="small" onClick={event => setSearchSize('')}>
                <CloseIcon />
              </IconButton>
            ),
          }}
        />
        <Button variant="contained" onClick={search}>
          Hledat
        </Button>
      </Box>
      <Box maxHeight="calc(100vh - 300px)" overflow="auto" width="100%">
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Id položky</TableCell>
              <TableCell>Kód položky</TableCell>
              <TableCell>Název kategorie</TableCell>
              <TableCell>Velikost</TableCell>
              <TableCell>Počet dnů</TableCell>
              <TableCell>Počet sezón</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.stockUsage.items.map(stockUsage => (
              <TableRow key={stockUsage.id}>
                <TableCell>{stockUsage.id}</TableCell>
                <TableCell>{stockUsage.code}</TableCell>
                <TableCell>{stockUsage.catalogName}</TableCell>
                <TableCell>{stockUsage.catalogSize}</TableCell>
                <TableCell>{stockUsage.days}</TableCell>
                <TableCell>{stockUsage.years}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <Box className={classes.pagination}>
        <Pagination
          activePage={page}
          itemsCountPerPage={PAGE_SIZE}
          totalItemsCount={data?.stockUsage.totalCount || 0}
          pageRangeDisplayed={5}
          onChange={pageNumber => setPage(pageNumber)}
        />
      </Box>
    </Box>
  );
};

const CategoryEarningsWithStylesApollo = withStyles(styles)(
  withApollo(CategoryEarnings),
);

const WrapperComponent: React.FunctionComponent<RouteComponentProps> = () => (
  <CategoryEarningsWithStylesApollo />
);

export default WrapperComponent;
