import React from 'react';
import PageDrawer from '../PageDrawer';
import RouteWrapper from '../RouteWrapper';
import Items from './Items';
import Sets from './Sets';
import { Router } from '@reach/router';

type Props = {
  pathName: string;
};

const PriceList: React.FunctionComponent<Props> = ({ pathName }) => (
  <PageDrawer
    listItems={[
      { label: 'Položky', to: 'items', adminRightsRequired: false },
      { label: 'Sety', to: 'sets', adminRightsRequired: false },
    ]}
    selectedItem={pathName}
  >
    <Router>
      <RouteWrapper path="price-list" default>
        <Items path="items" />
        <Sets path="sets" />
      </RouteWrapper>
    </Router>
  </PageDrawer>
);

export default PriceList;
