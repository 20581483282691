import React, { useState } from 'react';
import {
  makeStyles,
  createStyles,
  Theme,
  Table,
  TablePagination,
} from '@material-ui/core';
import CatalogItemTableHead from './CatalogItemTableHead';
import CatalogItemTableContent from './CatalogItemTableContent';
import {
  CatalogItemCellType,
  CatalogItemTableActionsType,
} from '../../../lib/types';
import {
  CatalogItemsInCatalogGroupVariables,
  CatalogItemsInCatalogGroup,
} from '../../../graphql/types';
import { useApolloClient } from 'react-apollo';
import { writeInputToCache } from './utils';

interface IProps {
  tableCellsDefinition: CatalogItemCellType[];
  variables: CatalogItemsInCatalogGroupVariables;
  data: CatalogItemsInCatalogGroup;
  refetch: (
    variables?: CatalogItemsInCatalogGroupVariables | undefined,
  ) => void;
  actionsDefinition: CatalogItemTableActionsType;
}

const CatalogItemTableWrapper: React.FunctionComponent<IProps> = ({
  tableCellsDefinition,
  variables,
  data,
  refetch,
  actionsDefinition,
}) => {
  const [offset, setOffset] = useState(variables.input.offset || 0);
  const [limit, setLimit] = useState(variables.input.limit || 10);
  const client = useApolloClient();
  const classes = useStyles();

  const handlePageChange = (variables: CatalogItemsInCatalogGroupVariables) => (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number,
  ) => {
    if (event) {
      event.preventDefault();
    }
    const newOffset = page * (variables.input.limit || 0);
    setOffset(newOffset);
    const newQueryVariables = {
      ...variables,
      input: {
        ...variables.input,
        offset: newOffset,
      },
    };
    refetch(newQueryVariables);
    writeInputToCache(client, newQueryVariables.input);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    event.preventDefault();
    const newLimit = parseInt(event.target.value, 10);
    setLimit(newLimit);
    const newQueryVariables = {
      ...variables,
      input: {
        ...variables.input,
        offset: 0,
        limit: newLimit,
      },
    };
    refetch(newQueryVariables);
    writeInputToCache(client, newQueryVariables.input);
  };

  if (variables.input.catalogGroupId) {
    client.writeData({
      data: { catalogGroupLength: data.catalogItemsSizeIndependent.totalCount },
    });
  }
  console.log({
    limit,
    offset,
  });
  return (
    <div className={classes.root}>
      <Table className={classes.table} size="medium" padding="none">
        <CatalogItemTableHead
          tableCellsDefinition={tableCellsDefinition}
          variables={variables}
          refetch={refetch}
        />
        <CatalogItemTableContent
          tableCellsDefinition={tableCellsDefinition}
          data={data.catalogItemsSizeIndependent}
          actionsDefinition={actionsDefinition}
        />
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={data.catalogItemsSizeIndependent.totalCount}
        rowsPerPage={limit}
        page={offset / limit}
        onChangePage={handlePageChange(variables as any)}
        onChangeRowsPerPage={handleRowsPerPageChange}
      />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
    },
    table: {
      minWidth: 750,
    },
  }),
);
export default CatalogItemTableWrapper;
