import { createStyles, makeStyles, Paper, Theme } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import React, { useState } from 'react';
import GraphQLErrorLoadingBoundary from '../../../components/GraphQLErrorLoadingBoundary';
import { catalogGroupsQuery } from '../../../graphql/queries';
import { CatalogGroups } from '../../../graphql/types';
import CatalogItemSearch from './CatalogItemSearch';
import CatalogItemTable from './CatalogItemTable';
import GroupSelector from './GroupSelector';

const Groups: React.FunctionComponent<RouteComponentProps> = () => {
  const [catalogGroupId, setCatalogGroupId] = useState('');
  const [search, setSearch] = useState('');
  const classes = useStyles();

  const handleCatalogGroupChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    child: React.ReactNode,
  ) => {
    event.preventDefault();
    setCatalogGroupId(event.target.value as string);
  };

  const handleSearchChange = (event: any) => {
    event.preventDefault();
    setSearch(event.target.value);
  };

  return (
    <div className={classes.pageWrapper}>
      <GraphQLErrorLoadingBoundary<CatalogGroups>
        query={catalogGroupsQuery}
        options={{ fetchPolicy: 'network-only' }}
      >
        {({ data, refetch }) => (
          <Paper className={classes.container}>
            <GroupSelector
              selectedCatalogGroupId={catalogGroupId}
              catalogGroups={data.catalogGroups}
              setCatalogGroup={handleCatalogGroupChange}
            />
            <Paper className={classes.tableContainer}>
              {catalogGroupId === '' ? (
                <div className={classes.text}>
                  Vyberte Skupinu pro zobrazení katalogových položek
                </div>
              ) : (
                <CatalogItemTable
                  catalogGroupId={parseInt(catalogGroupId, 10)}
                  showItemPositionInGroup={true}
                  actionsDefinition={{
                    removeItemAndReorder: true,
                    addItem: false,
                  }}
                />
              )}
            </Paper>
          </Paper>
        )}
      </GraphQLErrorLoadingBoundary>
      <Paper className={classes.container}>
        <CatalogItemSearch
          search={search}
          handeSearchChange={handleSearchChange}
        />
        <Paper className={classes.tableContainer}>
          <CatalogItemTable
            withoutCatalogGroup={true}
            search={search}
            actionsDefinition={{
              removeItemAndReorder: false,
              addItem: true,
            }}
          />
        </Paper>
      </Paper>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageWrapper: {
      padding: 16,
      display: 'flex',
    },
    container: {
      flex: '1 1 0px',
      marginRight: 16,
      textAlign: 'center',
      padding: 8,
    },
    tableContainer: {
      width: '100%',
      minHeight: 'calc(100vh - 250px)',
      marginTop: 16,
    },
    text: {
      fontSize: 32,
      fontWeight: 'bold',
      height: '100%',
      paddingTop: '20vh',
    },
  }),
);

export default Groups;
