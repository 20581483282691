import {
  createStyles,
  makeStyles,
  Modal,
  Theme,
  Paper,
} from '@material-ui/core';
import React from 'react';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  children: any;
  title: string;
}

const CustomModal = ({ onClose, isOpen, children, title }: Props) => {
  const classes = useStyles();
  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={isOpen}
      onClose={onClose}
    >
      <Paper className={classes.paper}>
        <span className={classes.title}>{title}</span>
        <div className={classes.content}>{children}</div>
      </Paper>
    </Modal>
  );
};

export default CustomModal;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: 'black',
      textTransform: 'uppercase',
      fontSize: 16,
    },
    content: {
      marginTop: 24,
    },
    paper: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      padding: 32,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  }),
);
