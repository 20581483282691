import React from 'react';
import {
  GetCashRegisterRecords,
  CashRegisterDifferenceResolvedVariables,
  CashRegisterDifferenceResolved,
} from '../../graphql/types';
import { makeStyles, createStyles, Theme, Button } from '@material-ui/core';
import moment from 'moment';
import { useMutation } from '@apollo/react-hooks';
import { cashRegisterDifferenceResolved } from '../../graphql/queries';
import { Link } from '@reach/router';
import { formatTime } from '../../lib/utils';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Divider } from '@material-ui/core';

interface Props {
  data: GetCashRegisterRecords;
}

const mapDay = {
  0: 'Neděle',
  1: 'Pondělí',
  2: 'Úterý',
  3: 'Středa',
  4: 'Čtvrtek',
  5: 'Pátek',
  6: 'Sobota',
};

const RecordsList = ({ data }: Props) => {
  const classes = useStyles();

  const [resolveDifferent] = useMutation<
    CashRegisterDifferenceResolved,
    CashRegisterDifferenceResolvedVariables
  >(cashRegisterDifferenceResolved);

  const handleResolveDifference = (id: string | null) => {
    if (id) {
      resolveDifferent({
        variables: {
          id,
        },
      });
    }
  };

  return (
    <div>
      <List>
        <ListItem>
          <ListItemText
            className={`${classes.cell} ${classes.day}`}
            style={{ width: '15%' }}
          >
            Den
          </ListItemText>
          <ListItemText
            className={`${classes.cell} ${classes.createdAt}`}
            style={{ width: '30%' }}
          >
            Kasa otevřena
          </ListItemText>
          <ListItemText
            className={`${classes.cell} ${classes.closedAt}`}
            style={{ width: '30%' }}
          >
            Kasa zavřena
          </ListItemText>
          <ListItemText
            className={`${classes.cell} ${classes.closedWithDiff}`}
            style={{ width: '30%' }}
          >
            Zavřeno s rozdílem
          </ListItemText>
          <ListItemText
            className={`${classes.cell} ${classes.diffResolved}`}
            style={{ width: '15%' }}
          >
            Rozdíl vyřešen
          </ListItemText>
        </ListItem>
      </List>
      <Divider />
      <List>
        {data?.cashRegisterRecords.map(item => (
          <ListItem key={item.id}>
            <ListItemLink
              className={`${classes.cell} ${classes.day}`}
              style={{ width: '15%' }}
              itemId={item.id}
            >
              {mapDay[moment(item.openedAt).day()]}
            </ListItemLink>
            <ListItemLink
              className={`${classes.cell} ${classes.createdAt}`}
              style={{ width: '30%' }}
              itemId={item.id}
            >
              {formatTime(item.openedAt)}
            </ListItemLink>
            <ListItemLink
              className={`${classes.cell} ${classes.closedAt}`}
              style={{ width: '30%' }}
              itemId={item.id}
            >
              {item.closedAt ? formatTime(item.closedAt) : '-'}
            </ListItemLink>
            <ListItemLink
              className={`${classes.cell} ${classes.closedWithDiff}`}
              style={{ width: '30%' }}
              itemId={item.id}
            >
              {item.closedWithDifference
                ? item.closedDifference
                : item.closedWithDifference == null
                ? '-'
                : 'Ne'}
            </ListItemLink>
            {item.closedWithDifference && !item.differenceResolved ? (
              <ListItem
                className={`${classes.cell} ${classes.diffResolved}`}
                style={{ width: '15%' }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleResolveDifference(item.closedId);
                  }}
                >
                  Vyřešit
                </Button>
              </ListItem>
            ) : (
              <ListItemLink
                className={`${classes.cell} ${classes.diffResolved}`}
                style={{ width: '15%' }}
                itemId={item.id}
              >
                {item.differenceResolved ? 'Ano' : '-'}
              </ListItemLink>
            )}
          </ListItem>
        ))}
      </List>
    </div>
  );
};

interface ItemProps {
  itemId: string;
  style: React.CSSProperties;
  children: React.ReactNode;
  className: string;
}

const ListItemLink = ({ children, itemId, ...props }: ItemProps) => (
  <ListItem
    {...props}
    component={(props: any) => (
      <Link to={`/cash-register/${itemId}`} {...props} />
    )}
  >
    {children}
  </ListItem>
);

export default RecordsList;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cell: {
      padding: 0,
    },
    day: {
      width: '15%',
    },
    createdAt: {
      width: '30%',
    },
    closedAt: {
      width: '30%',
    },
    closedWithDiff: {
      width: '30%',
    },
    diffResolved: {
      width: '15%',
    },
    paper: {
      padding: 16,
      display: 'flex',
      justifyContent: 'space-around',
      flexFlow: 'wrap',
    },
    customWidth: {
      maxWidth: 190,
    },
    datePicker: {
      marginLeft: 64,
      marginRight: 32,
    },
    right: {
      paddingRight: 15,
    },
    tableCellHead: {
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: '0.75rem',
      padding: '4px 24px 4px 24px;',
    },
    tableCell: {
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: '14px',
      padding: '8px 24px 8px 24px',
    },
  }),
);
