import {
  createStyles,
  makeStyles,
  Paper,
  Theme,
  TableHead,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Button,
  Tooltip,
  Fab,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import React, { useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import {
  sizesQuery,
  deleteSize,
  catalogGroupsQuery,
  sizesCountQuery,
} from '../../../graphql/queries';
import { Sizes_getSizes } from '../../../graphql/types';
import { Mutation, useQuery } from 'react-apollo';
import Pagination from 'react-js-pagination';
import EditIcon from '@material-ui/icons/Edit';
import EditSizeDialog from './EditSizeDialog';
import DeleteIcon from '@material-ui/icons/Delete';

const ITEMS_PER_PAGE = 15;

const Sizes: React.FunctionComponent<RouteComponentProps> = () => {
  const [catalogGroupName, setCatalogGroupName] = useState<String>('');
  const [activePage, setActivePage] = useState<number>(1);
  const [edit, setEdit] = useState<string | null>(null);
  const classes = useStyles();

  const handleCatalogGroupNameChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
  ) => {
    setCatalogGroupName(event.target.value as string);
    setActivePage(1);
  };

  const clearCatalogGroupName = (
    _e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    setCatalogGroupName('');
    setActivePage(1);
  };

  const editSize = (id: string | null | undefined, open: boolean) => () => {
    open && id ? setEdit(id) : setEdit(null);
  };

  const { data } = useQuery(catalogGroupsQuery);

  const { data: sizeCountData } = useQuery(sizesCountQuery, {
    variables: {
      catalogGroupName,
    },
    fetchPolicy: 'network-only',
  });

  const totalItemsCount = sizeCountData?.getSizesCount || 0;

  const { data: sizesData, loading, error } = useQuery(sizesQuery, {
    variables: {
      catalogGroupName,
      offset: ITEMS_PER_PAGE * (activePage - 1),
      limit: ITEMS_PER_PAGE,
    },
  });

  const { catalogGroups } = data || {};

  return (
    <>
      <div className={classes.pageWrapper}>
        <Paper className={classes.container}>
          <div className={classes.controlContainer}>
            <FormControl>
              <InputLabel>Katalogová skupina</InputLabel>
              <Select
                value={catalogGroupName}
                onChange={e => handleCatalogGroupNameChange(e)}
                style={{ width: '200px' }}
                defaultValue={''}
              >
                {catalogGroups &&
                  catalogGroups.map((item: { code: string }) => (
                    <MenuItem key={item.code} value={item.code}>
                      {item.code}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            {!!catalogGroupName.length && (
              <Button onClick={e => clearCatalogGroupName(e)}>Vymazat</Button>
            )}
          </div>
          {loading && <p>Loading...</p>}
          {error && <p>Error :</p>}
          {sizesData && sizesData.getSizes && (
            <>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Katalogové skupiny</TableCell>
                    <TableCell>min. výška</TableCell>
                    <TableCell>max. výška</TableCell>
                    <TableCell>min. váha</TableCell>
                    <TableCell>max. váha</TableCell>
                    <TableCell>velikost chodidla</TableCell>
                    <TableCell>min. délka produktu</TableCell>
                    <TableCell>max. délka produktu</TableCell>
                    <TableCell>velikost</TableCell>
                    <TableCell>Editace</TableCell>
                    <TableCell>Smazání</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sizesData.getSizes.map(
                    (size: Sizes_getSizes | null | undefined) => (
                      <TableRow key={size?.id || 'a'}>
                        <TableCell>{size?.id}</TableCell>
                        <TableCell>
                          {`${[
                            ...new Set(
                              size?.catalogGroups.map(item => item.name),
                            ),
                          ].join(', ') || ''} ${
                            size?.catalogGroups.length
                              ? `(${[
                                  ...new Set(
                                    size?.catalogGroups.map(item => item.code),
                                  ),
                                ].join(', ')})`
                              : ''
                          } `}
                        </TableCell>
                        <TableCell>{size?.heightMin}</TableCell>
                        <TableCell>{size?.heightMax}</TableCell>
                        <TableCell>{size?.weightMin}</TableCell>
                        <TableCell>{size?.weightMax}</TableCell>
                        <TableCell>{size?.footSize}</TableCell>
                        <TableCell>{size?.min}</TableCell>
                        <TableCell>{size?.max}</TableCell>
                        <TableCell>{size?.size}</TableCell>
                        <TableCell>
                          <Button onClick={editSize(size?.id, true)}>
                            <EditIcon />
                          </Button>
                          <EditSizeDialog
                            size={size}
                            open={edit === size?.id}
                            onClose={editSize(size?.id, false)}
                          />
                        </TableCell>
                        <TableCell>
                          <Mutation
                            mutation={deleteSize}
                            refetchQueries={[
                              {
                                query: sizesQuery,
                                variables: {
                                  catalogGroupName,
                                  offset: ITEMS_PER_PAGE * (activePage - 1),
                                  limit: ITEMS_PER_PAGE,
                                },
                              },
                            ]}
                          >
                            {(deleteItem: any) => (
                              <Button
                                onClick={() => {
                                  if (
                                    // eslint-disable-next-line no-restricted-globals
                                    confirm('Opravdu chcete smazat položku?')
                                  ) {
                                    deleteItem({
                                      variables: {
                                        sizeId: size?.id,
                                      },
                                    });
                                  }
                                }}
                              >
                                <DeleteIcon />
                              </Button>
                            )}
                          </Mutation>
                        </TableCell>
                      </TableRow>
                    ),
                  )}
                </TableBody>
              </Table>
              <div style={{ float: 'right' }}>
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={ITEMS_PER_PAGE}
                  totalItemsCount={totalItemsCount}
                  pageRangeDisplayed={5}
                  onChange={setActivePage}
                />
              </div>
            </>
          )}
        </Paper>
      </div>
      <EditSizeDialog
        size={null}
        open={edit === 'new'}
        onClose={editSize('new', false)}
      />
      <div className="addButton" style={{ marginTop: '10px' }}>
        <Tooltip title="Přidat">
          <Fab color="primary" onClick={editSize('new', true)}>
            <AddIcon />
          </Fab>
        </Tooltip>
      </div>
    </>
  );
};

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    pageWrapper: {
      padding: 16,
      display: 'flex',
    },
    container: {
      flex: '1 1 0px',
      marginRight: 16,
      textAlign: 'center',
      padding: 8,
    },
    controlContainer: {
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'center',
      gap: '15px',
    },
  }),
);

export default Sizes;
