import { Credentials, UserAuth } from './types';
import fetch from 'cross-fetch';

const USER_KEY = '@CZSKI/user';

class Auth {
  user: UserAuth | null = null;

  async login(username: string, password: string) {
    const credentials: Credentials = {
      username,
      password,
    };
    const response = await fetch('/api/auth/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(credentials),
    });

    if (!response.ok) {
      console.error(response);
    } else {
      const res = await response.json();
      const user: UserAuth = {
        accessToken: res.accessToken,
        role: res.role,
        username: res.username,
      };
      this.saveUser(user);
    }
    return response;
  }

  async register(
    name: string,
    username: string,
    password: string,
    code: number,
    role: string,
  ) {
    const data = {
      name,
      username,
      password,
      code,
      role,
    };
    const response = await fetch('/api/editCredentials/register', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${auth.getAccessToken()}`,
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(data),
    });

    const responseText = await response.text();
    if (!response.ok) {
      window.alert(responseText);
    }
    return response;
  }

  async changeCode(newCode: number, employeeId: number) {
    const data = {
      newCode,
      employeeId,
    };

    const response = await fetch('/api/editCredentials/changeCode', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${auth.getAccessToken()}`,
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(data),
    });

    const responseText = await response.text();
    if (!response.ok) {
      window.alert(responseText);
    }
    return response;
  }

  async changePassword(password: string, userId: number) {
    const data = {
      password,
      userId,
    };
    const response = await fetch('/api/editCredentials/changePassword', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${auth.getAccessToken()}`,
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(data),
    });

    const responseText = await response.text();
    if (!response.ok) {
      window.alert(responseText);
    }
    return response;
  }

  async changeRole(role: string, employeeId: number) {
    const data = {
      role,
      employeeId,
    };

    const response = await fetch('/api/editCredentials/changeRole', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${auth.getAccessToken()}`,
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(data),
    });

    const responseText = await response.text();
    if (!response.ok) {
      window.alert(responseText);
    }
    return response;
  }

  saveUser(user: UserAuth) {
    if (user.accessToken == null) {
      throw new Error('Empty accessToken!');
    }
    if (user.role == null) {
      throw new Error('Empty role');
    }
    localStorage.setItem(USER_KEY, JSON.stringify({ user }));
    this.user = user;
    return this.user;
  }

  getAccessToken() {
    const user = this.getUser();
    if (user !== null) {
      return user.accessToken;
    }
    return null;
  }

  getUserName() {
    const user = this.getUser();
    if (user !== null) {
      return user.username;
    }
    return null;
  }

  getUser() {
    const user = localStorage.getItem(USER_KEY);
    if (user !== null) {
      return JSON.parse(user).user;
    }
    return null;
  }

  isLoggedIn() {
    const user = this.getUser();
    return user !== null;
  }

  async logout() {
    const user = this.getUser();
    if (user == null) {
      return false;
    }
    localStorage.removeItem(USER_KEY);
    this.user = null;
    return true;
  }

  isAdmin() {
    const user = this.getUser();
    if (user) {
      return user.role === 'ADMIN' || user.role === 'OWNER';
    }
    return false;
  }

  isOwner() {
    const user = this.getUser();
    if (user) {
      return user.role === 'OWNER';
    }
    return false;
  }
}

const auth = new Auth();
export default auth;
