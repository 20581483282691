import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
  withStyles,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import * as React from 'react';
import { Mutation } from 'react-apollo';
import { Price } from '../../lib/types';
import { PriceEntity } from '../../lib/enums';
import {
  itemPrices,
  setItemPrice,
  setPrices,
  setSetPrice,
} from '../../graphql/queries';

type IProps = {
  classes: any;
  open: boolean;
  item: Price;
  onClose: () => void;
  entity: PriceEntity;
};

type IState = {
  item: Price;
};

class PriceEditDialog extends React.Component<IProps, IState> {
  state: IState = {
    item: this.props.item,
  };

  handleChangePrice = (name: string) => (event: {
    target: { value: string };
  }) => {
    const value = event.target.value;
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value,
      },
    }));
  };

  onVariantChange = (event: any) => {
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        variant: event.target.value,
      },
    }));
  };

  onFixedPriceChange = () => {
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        fixedPrice: !prevState.item.fixedPrice,
      },
    }));
  };

  getItemPrice = (entity: PriceEntity) => {
    const { item } = this.state;
    const itemPrice = {
      id: item.id,
      oneDayPrice: parseInt(item.oneDayPrice.toString(), 10),
      twoDayPrice: parseInt(item.twoDayPrice.toString(), 10),
      basePrice: parseInt(item.basePrice.toString(), 10),
      bail: parseInt(item.bail.toString(), 10),
      seasonPrice: parseInt(item.seasonPrice.toString(), 10),
      seasonBail: parseInt(item.seasonBail.toString(), 10),
      twoSeasonPrice: parseInt(item.twoSeasonPrice.toString(), 10),
      variant: item.variant,
    };
    if (entity === PriceEntity.ITEM_PRICE) {
      itemPrice['fixedPrice'] = item.fixedPrice;
    }
    return itemPrice;
  };

  render() {
    const { open, classes, entity } = this.props;
    const { item } = this.state;
    return (
      <Dialog
        onClose={this.props.onClose}
        open={open}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{`Ceny pro ${item.name}`}</DialogTitle>
        <DialogContent style={{ padding: '0px' }}>
          <div className={classes.content}>
            <FormControl>
              <InputLabel htmlFor="item-variant">Varianta</InputLabel>
              <Select
                className={classes.select}
                value={item.variant}
                onChange={this.onVariantChange}
                color="primary"
                inputProps={{
                  id: 'item-variant',
                }}
              >
                <MenuItem value={'VIP'}>{'VIP'}</MenuItem>
                <MenuItem value={'TOP'}>{'TOP'}</MenuItem>
                <MenuItem value={'BASIC'}>{'BASIC'}</MenuItem>
              </Select>
            </FormControl>
            {entity === PriceEntity.ITEM_PRICE && (
              <FormControl>
                <InputLabel htmlFor="item-fixed-price">Fixní cena</InputLabel>
                <Select
                  className={classes.select}
                  value={item.fixedPrice}
                  onChange={this.onFixedPriceChange}
                  color="primary"
                  inputProps={{
                    id: 'item-fixed-price',
                  }}
                >
                  <MenuItem value={'true'}>{'ANO'}</MenuItem>
                  <MenuItem value={'false'}>{'NE'}</MenuItem>
                </Select>
              </FormControl>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '16px',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'baseline',
                width: '100%',
                justifyContent: 'flex-start',
              }}
            >
              <TextField
                label="1 den"
                type="number"
                className={classes.textField}
                value={item.oneDayPrice}
                onChange={this.handleChangePrice('oneDayPrice')}
              />
              <TextField
                label="2 den"
                type="number"
                disabled={item.fixedPrice}
                className={classes.textField}
                value={item.twoDayPrice}
                onChange={this.handleChangePrice('twoDayPrice')}
              />
              <TextField
                label="Základ"
                type="number"
                disabled={item.fixedPrice}
                className={classes.textField}
                value={item.basePrice}
                onChange={this.handleChangePrice('basePrice')}
              />
              <TextField
                label="Záloha"
                type="number"
                className={classes.textField}
                value={item.bail}
                onChange={this.handleChangePrice('bail')}
              />
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'baseline',
                width: '100%',
                justifyContent: 'flex-start',
              }}
            >
              <TextField
                label="1 Sezóna"
                type="number"
                className={classes.textField}
                value={item.seasonPrice}
                onChange={this.handleChangePrice('seasonPrice')}
              />
              <TextField
                label="2 Sezóny"
                type="number"
                className={classes.textField}
                value={item.twoSeasonPrice}
                onChange={this.handleChangePrice('twoSeasonPrice')}
              />
              <TextField
                label="Sezónní záloha"
                type="number"
                className={classes.textField}
                value={item.seasonBail}
                onChange={this.handleChangePrice('seasonBail')}
              />
            </div>
          </div>
          <DialogActions className={classes.dialogActions}>
            <div className="invisible">{}</div>
            <Mutation
              mutation={
                PriceEntity.ITEM_PRICE === entity ? setItemPrice : setSetPrice
              }
              variables={{
                price: this.getItemPrice(entity),
              }}
              onCompleted={this.props.onClose}
              refetchQueries={[{ query: itemPrices }, { query: setPrices }]}
            >
              {(postMutation: any) => (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={postMutation}
                >
                  Uložit
                </Button>
              )}
            </Mutation>
            <Button onClick={this.props.onClose} color="primary">
              Zavřít
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }
}

const styles = (theme: any) => ({
  textField: {
    margin: '8px',
  },
  content: {
    display: 'flex',
    alignItems: 'baseline',
    width: '100%',
    justifyContent: 'space-around',
  },
  select: {
    marginTop: '14px',
    width: '80px',
  },
  button: {
    width: '150px',
    marginTop: '16px',
  },
  dialogActions: {
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
});

export default withStyles(styles)(PriceEditDialog);
