import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from '@material-ui/core';
import * as React from 'react';

type IProps = {
  open: boolean;
  onClose: () => void;
  onAgree: () => void;
  titleLabel: string;
  dialogContent: string;
};
class ConfirmmDialog extends React.Component<IProps> {
  render() {
    const { open, onClose, titleLabel, dialogContent, onAgree } = this.props;
    return (
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{titleLabel}</DialogTitle>
        <DialogContent>{dialogContent}</DialogContent>
        <Divider />
        <DialogActions>
          <Button color="primary" variant="contained" onClick={onAgree}>
            Souhlasím
          </Button>
          <Button variant="contained" color="primary" onClick={onClose}>
            Zpět
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ConfirmmDialog;
