import {
  Button,
  SnackbarContent,
  IconButton,
  Paper,
  makeStyles,
} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import { RouteComponentProps } from '@reach/router';
import moment from 'moment';
import { Mutation, useLazyQuery } from 'react-apollo';
import CheckBoxCustom from '../../../components/CheckBox';
import {
  createOrder,
  createReservation,
  loadOrders,
  getCustomers,
} from '../../../graphql/queries';
import auth from '../../../lib/auth';
import { labelPlacement, PagesNames } from '../../../lib/enums';
import pagesState, { OrderStateDefault } from '../../../lib/pageState';
import { InputOrderType, OrderPageState } from '../../../lib/types';
import { getStates } from '../../../lib/utils';
import SelectTextField from '../../../components/SelectTextField';
import React, { useState } from 'react';
import { GetCustomers, GetCustomersVariables } from '../../../graphql/types';
import CloseIcon from '@material-ui/icons/Close';
import { ApolloError } from '@apollo/client';
import { getErrorMessage } from './errors';

interface IState {
  completedOpen: boolean;
  errorOpen: boolean;
  errorMessage: string;
  order: InputOrderType;
  dateDisabled: boolean;
  season: boolean[];
  OrderState: OrderPageState;
  useSeasonPrices: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    padding: '4px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100px',
    marginLeft: '20px',
    marginTop: '20px',
    boxShadow: 'none',
    position: 'relative',
  },
  iconButton: {
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
  icon: {
    width: '16px',
    height: '16px',
  },
}));

interface Props extends RouteComponentProps {
  navigate?: any;
  entity?: string;
}

const NewOrder = ({ navigate, entity }: Props) => {
  const from = moment().format('YYYY-MM-DD');
  const to = moment().format('YYYY-MM-DD');
  const [state, setState] = useState<IState>({
    completedOpen: false,
    errorOpen: false,
    errorMessage: '',
    order: {
      from,
      to,
      season: false,
      customer: {
        name: '',
        phone: '',
        id: '',
      },
      note: '',
    },
    dateDisabled: false,
    season: [false, false],
    OrderState:
      (pagesState.getPageState(PagesNames.Order) as any) || OrderStateDefault,
    useSeasonPrices: false,
  });

  const classes = useStyles();

  const [getCustomersQuery, { data: customers, loading }] = useLazyQuery<
    GetCustomers,
    GetCustomersVariables
  >(getCustomers, { fetchPolicy: 'cache-and-network' });

  const handleUseSeasonPricesChange = () =>
    setState(prevState => ({
      ...prevState,
      useSeasonPrices: !prevState.useSeasonPrices,
      order: {
        ...prevState.order,
        season: true,
      },
    }));

  const mutationCompleted = () => {
    setState(prevState => ({ ...prevState, completedOpen: true }));
    navigate('/orders-and-reservations');
  };

  const mutationError = (err: ApolloError) => {
    const graphqlError = err.graphQLErrors?.[0];
    setState(prevState => ({
      ...prevState,
      errorOpen: true,
      errorMessage: getErrorMessage(graphqlError.name),
    }));
  };

  const handleClose = () => {
    setState(prevState => ({
      ...prevState,
      completedOpen: false,
      errorOpen: false,
    }));
  };

  const handleChange = (name: string, isNumber: boolean = false) => (event: {
    target: { value: string };
    persist: any;
  }) => {
    event.persist();
    setState(prevState => ({
      ...prevState,
      order: {
        ...prevState.order,
        [name]: isNumber
          ? parseInt(event.target.value, 10)
          : event.target.value,
      },
    }));
  };

  const handleCustomerChange = (value: string, name: string) => {
    setState(prevState => ({
      ...prevState,
      order: {
        ...prevState.order,
        customer: {
          ...prevState.order.customer,
          [name]: value,
        },
      },
    }));
  };

  const handleCheckboxChange = (index: number) => {
    const { season, order } = state;
    season[index] = !season[index];
    if (season[index]) {
      season[Math.abs(index - 1)] = false;
    }
    const result = season.reduce((acc, curr) => acc || curr, false);
    if (result) {
      order.from = moment().format('YYYY-MM-DD');
      order.to = moment()
        .add(index + 1, 'y')
        .format('YYYY-MM-DD');
    }
    order.season = result;
    setState(prevState => ({
      ...prevState,
      season,
      dateDisabled: result,
    }));
  };

  const validate = (postMutation: () => any) => () => {
    if (
      state.order.customer.name.length === 0 ||
      state.order.customer.phone.length < 9
    ) {
      alert('Vyplňte všechna povinná pole!');
      return false;
    }
    if (
      new Date(state.order.from).getTime() >= new Date(state.order.to).getTime()
    ) {
      alert('Datum OD musí být menší než datum DO!');
      return false;
    }
    return postMutation();
  };

  const handleChangeDate = (name: string) => (event: {
    target: { value: string };
    persist: any;
  }) => {
    event.persist();
    const date = event.target.value;
    setState(prevState => ({
      ...prevState,
      order: {
        ...prevState.order,
        [name]: date,
      },
    }));
  };

  const handleLoadCustomers = (query: string, fieldName: string) => {
    getCustomersQuery({
      variables: {
        name: fieldName === 'name' ? query : '',
        phone: fieldName === 'phone' ? query : '',
      },
    });
  };

  const handleSelectCustomer = (value: number | string, fieldName: string) => {
    const selectedCustomer = customers?.customers?.find(c => c.id === value);
    setState(prevState => ({
      ...prevState,
      order: {
        ...prevState.order,
        customer: {
          name:
            selectedCustomer?.name ||
            (fieldName === 'name' ? '' : prevState.order.customer.name),
          phone:
            selectedCustomer?.phone ||
            (fieldName === 'phone' ? '' : prevState.order.customer.phone),
          id: selectedCustomer
            ? selectedCustomer.id.toString()
            : fieldName === 'id'
            ? ''
            : prevState.order.customer.id,
        },
      },
    }));
  };

  const handleRemoveId = () => {
    setState(prevState => ({
      ...prevState,
      order: {
        ...prevState.order,
        customer: {
          ...prevState.order.customer,
          id: '',
        },
      },
    }));
  };

  const { season, OrderState, useSeasonPrices } = state;
  const isAdmin = auth.isAdmin();

  const nameOptions =
    customers?.customers?.map(c => ({
      value: c.id || '',
      optionLabel: `${c.name} ${c.phone}`,
      inputLabel: c.name,
    })) || [];
  const phonesOptions =
    customers?.customers?.map(c => ({
      value: c.id || '',
      optionLabel: `${c.phone} ${c.name}`,
      inputLabel: c.phone || '',
    })) || [];

  return (
    <div className="newOrder" style={{ alignItems: 'center' }}>
      <h2>
        {entity === 'order'
          ? 'Přidáváte novou objednávku'
          : 'Přidáváte novou rezervaci'}
      </h2>
      <div className="orderDetails" style={{ justifyContent: 'center' }}>
        <div className="newOrder" style={{ margin: '16px' }}>
          <Paper component="form" className={classes.root}>
            <TextField
              label="ID"
              type="string"
              disabled={true}
              value={state.order.customer.id}
            />
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={handleRemoveId}
              className={classes.iconButton}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>
          </Paper>
          <SelectTextField
            label="Phone"
            name="phone"
            onChange={handleCustomerChange}
            onLoad={handleLoadCustomers}
            options={phonesOptions}
            onSelect={handleSelectCustomer}
            loading={loading}
            value={state.order.customer.phone}
          />
          <SelectTextField
            label="Jméno"
            name="name"
            onChange={handleCustomerChange}
            onLoad={handleLoadCustomers}
            options={nameOptions}
            onSelect={handleSelectCustomer}
            loading={loading}
            value={state.order.customer.name}
          />
          <TextField
            label="Poznámka"
            type="string"
            required={false}
            value={state.order.note}
            className="text-field"
            onChange={handleChange('note')}
          />
        </div>
        <div className="newOrder" style={{ margin: '16px' }}>
          <div className="orderDetails" style={{ marginTop: '40px' }}>
            {Object.keys(season).map((key, index) => (
              <CheckBoxCustom
                key={index}
                checked={season[index]}
                onChange={() => handleCheckboxChange(index)}
                name={index === 0 ? 'Jedna sezóna' : 'Dvě sezóny'}
                labelPlacement={labelPlacement.END}
              />
            ))}
            {isAdmin && (
              <CheckBoxCustom
                checked={useSeasonPrices}
                onChange={handleUseSeasonPricesChange}
                name={'Použít sezóní ceny'}
                labelPlacement={labelPlacement.END}
              />
            )}
          </div>
          <TextField
            label="Od"
            type="date"
            required={true}
            value={state.order.from}
            className="text-field"
            disabled={state.dateDisabled}
            onChange={handleChangeDate('from')}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: isAdmin ? null : new Date().toJSON().split('T')[0],
            }}
          />
          <TextField
            label="Do"
            type="date"
            required={true}
            value={state.order.to}
            className="text-field"
            disabled={state.dateDisabled}
            onChange={handleChangeDate('to')}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: state.order.from.split('T')[0],
            }}
          />
        </div>
      </div>
      <Mutation
        mutation={entity === 'order' ? createOrder : createReservation}
        variables={{
          order: {
            ...state.order,
            customer: {
              ...state.order.customer,
              id:
                state.order.customer.id === ''
                  ? undefined
                  : state.order.customer.id,
            },
          },
        }}
        onCompleted={mutationCompleted}
        onError={mutationError}
        refetchQueries={[
          {
            query: loadOrders,
            variables: {
              offset:
                OrderState.itemsCountPerPage * (OrderState.activePage - 1),
              limit: OrderState.itemsCountPerPage,
              sort: OrderState.order,
              search: OrderState.search,
              order: OrderState.direction,
              state: getStates(
                OrderState.orderFilters,
                OrderState.reservationFilters,
                OrderState.activeEntity,
              ),
            },
          },
        ]}
      >
        {(postMutation: any) => (
          <Button
            style={{ width: '120px' }}
            variant="contained"
            color={entity === 'order' ? 'primary' : 'secondary'}
            onClick={validate(postMutation)}
          >
            Vytvořit
          </Button>
        )}
      </Mutation>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={state.completedOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <SnackbarContent
          style={{ backgroundColor: '#43a047' }}
          message={<span>Uloženo</span>}
        />
      </Snackbar>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={state.errorOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <SnackbarContent
          style={{ backgroundColor: '#d32f2f' }}
          message={<span>{state.errorMessage}</span>}
        />
      </Snackbar>
    </div>
  );
};

export default NewOrder;
