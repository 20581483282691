import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles,
} from '@material-ui/core';

type IProps = {
  open: boolean;
  onClose: (confirm: boolean) => void;
};

const MissingPaymentsDialog = ({ open, onClose }: IProps) => {
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="unpiaud-payment-dialog-title">
        Nebyla zadány všechny platby
      </DialogTitle>
      <DialogContent>
        Zaplacená částka za půjčovné nebo kauci nesedí s cenou na faktuře
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(true)} color="primary">
          Přesto vydat
        </Button>
        <Button onClick={() => onClose(false)} color="secondary">
          Zpět
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const styles = () => ({
  textField: {
    margin: '16px',
    width: '85px',
  },
  button: {
    marginLeft: '16px',
  },
  dialogActions: {
    justifyContent: 'space-between',
  },
});

export default withStyles(styles)(MissingPaymentsDialog);
