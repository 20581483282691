import { Button, TextField, withStyles } from '@material-ui/core';
import { RouteComponentProps, navigate } from '@reach/router';
import * as React from 'react';
import logo from '../../assets/czskiLogo.png';
import auth from '../../lib/auth';
import pagesState from '../../lib/pageState';
import './Login.css';

type State = {
  username: string;
  password: string;
  errorText: string;
};

type Props = {
  classes: any;
};

const styles = (theme: any) => ({
  textField: {
    width: 300,
  },
});

class Login extends React.Component<Props & RouteComponentProps, State> {
  state: State = {
    username: '',
    password: '',
    errorText: '',
  };

  constructor(props: Props) {
    super(props);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  onUsernameChange = (event: any) =>
    this.setState({ username: event.target.value });
  onPasswordChange = (event: any) =>
    this.setState({ password: event.target.value });

  handleKeyPress = (e: any) => {
    if (e.keyCode === 13) {
      this.onLoginClick();
    }
  };
  onLoginClick = async () => {
    const { username, password } = this.state;
    const result = await auth.login(username, password);
    switch (result.status) {
      case 200:
        pagesState.initialization();
        navigate('orders-and-reservations');
        break;
      case 401:
        this.setState({ errorText: 'Špatná kombinace jména a hesla' });
        break;
    }
  };

  render() {
    const { classes } = this.props;
    const { username, password, errorText } = this.state;
    return (
      <div className="login">
        <div className="loginContainer">
          <div className="logo">
            <img src={logo} className="img" alt="CZSKI logo" />
          </div>
          <div className="errorText">{errorText}</div>
          <TextField
            required={true}
            label="Jméno"
            value={username}
            onChange={this.onUsernameChange}
            className={classes.textField}
            margin="normal"
          />
          <TextField
            onKeyDown={this.handleKeyPress}
            required={true}
            label="Heslo"
            value={password}
            type="password"
            className={classes.textField}
            onChange={this.onPasswordChange}
            margin="normal"
          />
          <Button onClick={this.onLoginClick} variant="contained">
            Přihlásit
          </Button>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Login);
