import React from 'react';
import { Router } from '@reach/router';
import PageDrawer from '../PageDrawer';
import Items from './Items/Items';
import ItemDetail from './Items/ItemDetail';
import RouteWrapper from '../RouteWrapper';
import Categories from './Categories/Categories';
import ItemEdit from './Items/ItemEdit';
import Groups from './Groups/Groups';
import Sizes from './Sizes/SizesList';
import Filters from './Filters/FiltersList';
import FilterEdit from './Filters/FilterEdit';

type Props = {
  pathName: string;
};

const Catalog: React.FunctionComponent<Props> = ({ pathName }) => (
  <PageDrawer
    listItems={[
      { label: 'Položky', to: 'items', adminRightsRequired: false },
      { label: 'Kategorie', to: 'categories', adminRightsRequired: false },
      { label: 'Skupiny', to: 'groups', adminRightsRequired: true },
      { label: 'Velikosti', to: 'sizes', adminRightsRequired: true },
      { label: 'Filtry', to: 'filters', adminRightsRequired: true },
    ]}
    selectedItem={pathName}
  >
    <Router>
      <RouteWrapper path="catalog" default>
        <RouteWrapper path="items">
          <Items path="/" />
          <ItemDetail path="item-detail/:catalogItemId" />
          <ItemEdit path="item-edit/:catalogItemId" />
        </RouteWrapper>
        <Categories path="categories" />
        <Groups path="groups" />
        <Sizes path="sizes" />
        <RouteWrapper path="filters">
          <Filters path="/" />
          <FilterEdit path="filter-edit/:filterId" />
        </RouteWrapper>
      </RouteWrapper>
    </Router>
  </PageDrawer>
);

export default Catalog;
