import React, { useCallback } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { debounce } from 'lodash';

interface OptionItem {
  value: number | string;
  optionLabel: string;
  inputLabel: string;
}

interface Props {
  loading?: boolean;
  name: string;
  label: string;
  options: OptionItem[];
  onLoad: (query: string, fieldName: string) => void;
  onSelect: (value: number, fieldName: string) => void;
  onChange: (value: string, name: string) => void;
  value: string;
}

const SelectTextField = ({
  loading,
  name,
  label,
  options,
  onLoad,
  onSelect,
  onChange,
  value,
}: Props) => {
  const handleKeyUp = (value: string) => {
    if (value.length > 3) onLoad(value, name);
  };

  const handleKeyUpDelayed = useCallback(debounce(handleKeyUp, 400), []);

  return (
    <Autocomplete
      options={value.length > 3 ? options : []}
      onChange={(_, newValue) => onSelect(newValue ? newValue.value : '', name)}
      freeSolo
      loading={loading}
      className="text-field"
      value={value}
      inputValue={value}
      renderOption={option => <div>{option.optionLabel}</div>}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          type="text"
          fullWidth
          onKeyUp={() => handleKeyUpDelayed(value)}
          onChange={e => onChange(e.target.value, name)}
        />
      )}
    />
  );
};

export default SelectTextField;
