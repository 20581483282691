import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  MenuItem,
  Select,
  TextField,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';
import { useQuery } from 'react-apollo';
import auth from '../../lib/auth';
import { PaymentForm } from '../../lib/enums';
import { paymentFormCZ } from '../../lib/localization';
import { loadOrders, orderBailDialog } from '../../graphql/queries';
import './order.css';
import StockOrderTable from './StockOrderTable';
import {
  OrderBailVariables,
  OrderBail,
  OrderBail_order_sets_items,
  addPayment,
  PaymentType,
} from '../../graphql/types';
import { notEmpty } from '../../lib/utils';
import AddPaymentButton from './AddPaymentButton';

type Props = {
  open: boolean;
  id: number;
  handleClose: () => void;
  loadOrdersVariables: any;
};

const BailDialog = ({ handleClose, id, open, loadOrdersVariables }: Props) => {
  const [payment, setPayment] = useState({
    type: PaymentType.RETURN_PRICE,
    amount: 0,
    form: PaymentForm.CASH,
    paid: 0,
    amountToPay: 0,
  });

  const [bail, setBail] = useState({
    type: PaymentType.RETURN,
    amount: 0,
    form: PaymentForm.CASH,
    paid: 0,
    amountToPay: 0,
  });

  const [stockItems, setStockItems] = useState<OrderBail_order_sets_items[]>(
    [],
  );

  const { data, loading } = useQuery<OrderBail, OrderBailVariables>(
    orderBailDialog,
    {
      variables: {
        id,
      },
      fetchPolicy: 'network-only',
      onCompleted: data => {
        let currentItems = [...stockItems];
        const order = data.order;
        const amounts = order && order.amounts;
        if (order && amounts) {
          (order.sets || []).forEach(set => {
            if (set && set.items) {
              currentItems = currentItems.concat(set.items.filter(notEmpty));
            }
          });
          setStockItems(currentItems);
          setPayment(prevState => ({
            ...prevState,
            paid: amounts.returnPrice,
            amount: amounts.toPayPrice,
            amountToPay: amounts.price,
          }));
          setBail(prevState => ({
            ...prevState,
            paid: amounts.returnBail,
            amount: amounts.toReturnBail,
            amountToPay: amounts.bail,
          }));
        }
      },
    },
  );

  const onAmountChange = (
    type: 'bail' | 'payment',
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const item = type === 'bail' ? bail : payment;
    let amount = Number(e.target.value);
    if (!auth.isAdmin() && amount < 0) {
      amount = Math.abs(amount);
    }
    const reminder = item.amountToPay - item.paid;
    if (!auth.isAdmin() && amount > reminder) {
      amount = reminder;
    }

    if (type === 'bail') {
      setBail(prevState => ({ ...prevState, amount }));
    } else {
      setPayment(prevState => ({
        ...prevState,
        amount,
        type: amount < 0 ? PaymentType.PRICE : PaymentType.RETURN_PRICE,
      }));
    }
  };

  const onFormChange = (type: 'bail' | 'payment', e: any) => {
    if (type === 'bail') {
      setBail(prevState => ({ ...prevState, form: e.target.value }));
    } else {
      setPayment(prevState => ({ ...prevState, form: e.target.value }));
    }
  };

  const paymentAdded = (mutationData: addPayment, type: 'bail' | 'payment') => {
    const amount =
      mutationData && mutationData.addPayment && mutationData.addPayment.amount;
    const amounts = data && data.order && data.order.amounts;
    if (amount && amounts) {
      if (type === 'bail') {
        setBail(prevState => ({
          ...prevState,
          paid: prevState.paid + amount,
          amount: amounts.toReturnBail - amount,
        }));
      } else {
        setPayment(prevState => ({
          ...prevState,
          paid: prevState.paid + amount,
          amount: amounts.toPayPrice - amount,
        }));
      }
    }
  };

  const order = data?.order;

  const returnPayment = !!order && order.amounts && order.amounts.price > 0;

  const returnedItems = stockItems.filter(item => {
    if (item.secondItem) {
      return item.firstItemReturned && item.secondItemReturned;
    } else {
      return item.firstItemReturned;
    }
  });
  const allItemsReturned = returnedItems.length === stockItems.length;

  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      {loading ? (
        'Loading ..'
      ) : (
        <div>
          <DialogTitle id="form-dialog-title">
            Vrácení {returnPayment ? 'plateb' : 'zálohy'}
          </DialogTitle>
          <DialogContent>
            <div className="prices">
              <div>
                Vybraná kauce: {bail.amountToPay.toLocaleString('FR')} CZK
              </div>
              <Divider />
            </div>
            <div>
              {order && order.serviceNote != null && (
                <>
                  <div>Poznámka</div>
                  <div style={{ padding: '8px' }}>{order.serviceNote}</div>
                </>
              )}
            </div>
            <div className="dialogRow">
              <div className="paymentName">Vráceno</div>
              <div className="paymentName">
                {bail.paid.toLocaleString('FR')}
              </div>
              <div className="sign">{'+'}</div>
              <div className="dialogCell">
                <TextField
                  className={classes.textField}
                  autoFocus={true}
                  value={bail.amount.toString()}
                  onChange={e => onAmountChange('bail', e)}
                  type="number"
                />
              </div>
              <div className="dialogCell">
                <Select
                  className="selectBox"
                  value={bail.form}
                  onChange={e => onFormChange('bail', e)}
                  color="primary"
                >
                  {Object.keys(PaymentForm)
                    .filter(key => isNaN(Number(key)))
                    .map((paymentForm: string) => {
                      return (
                        <MenuItem value={paymentForm} key={paymentForm}>
                          {paymentFormCZ[paymentForm]}
                        </MenuItem>
                      );
                    })}
                </Select>
              </div>
              {order && (
                <AddPaymentButton
                  orderId={order.id}
                  onComplete={data => paymentAdded(data, 'bail')}
                  amount={bail.amount}
                  refetchQueries={[
                    { query: loadOrders, variables: loadOrdersVariables },
                  ]}
                  type={bail.type}
                  form={bail.form}
                  disabled={bail.amount > bail.amountToPay}
                />
              )}
            </div>

            {returnPayment && (
              <div>
                <div className="prices">
                  <div>
                    Zaplacené půjčovné:{' '}
                    {payment.amountToPay.toLocaleString('FR')} CZK
                  </div>
                  <Divider />
                </div>
                <div className="dialogRow">
                  <div className="paymentName">Vráceno</div>
                  <div className="paymentName">
                    {payment.paid.toLocaleString('FR')}
                  </div>
                  <div className="sign">{'+'}</div>
                  <div className="dialogCell">
                    <TextField
                      className={classes.textField}
                      autoFocus={true}
                      value={payment.amount.toString()}
                      onChange={e => onAmountChange('payment', e)}
                      type="number"
                    />
                  </div>
                  <div className="dialogCell">
                    <Select
                      className="selectBox"
                      value={payment.form}
                      onChange={e => onFormChange('payment', e)}
                      color="primary"
                    >
                      {Object.keys(PaymentForm)
                        .filter(key => isNaN(Number(key)))
                        .map((paymentForm: string) => {
                          return (
                            <MenuItem value={paymentForm} key={paymentForm}>
                              {paymentFormCZ[paymentForm]}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </div>
                  {order && (
                    <AddPaymentButton
                      type={payment.type}
                      form={payment.form}
                      amount={payment.amount}
                      refetchQueries={[
                        { query: loadOrders, variables: loadOrdersVariables },
                      ]}
                      orderId={order.id}
                      onComplete={data => paymentAdded(data, 'payment')}
                      disabled={
                        payment.amount > payment.amountToPay ||
                        payment.amount < 0
                      }
                    />
                  )}
                </div>
              </div>
            )}

            {!allItemsReturned && (
              <>
                <Divider />
                <DialogContentText>Nevrácené položky</DialogContentText>
                <StockOrderTable items={stockItems} isReturnedDateNull={true} />
              </>
            )}

            <Divider />
            <DialogContentText>Vrácené položky</DialogContentText>
            <StockOrderTable isReturnedDateNull={false} items={stockItems} />
          </DialogContent>
        </div>
      )}
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Zavřít
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    textField: {
      margin: '10px',
      width: '85px',
    },
    button: {
      marginLeft: '16px',
    },
    tableCell: {
      padding: '12px 4px 12px 4px',
    },
  }),
);

export default BailDialog;
