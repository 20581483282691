import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  withStyles,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { dissoc, uniq, omit } from 'rambda';
import * as React from 'react';
import { graphql, Mutation, withApollo, WithApolloClient } from 'react-apollo';
// @ts-ignore
import Autosuggest from 'react-autosuggest';
import { PagesNames } from '../../../lib/enums';
import pagesState, { CatalogStateDefault } from '../../../lib/pageState';
import AllowedCategoriesDialog from '../../../components/AllowedCategoriesDialog';
import {
  categories as categoriesQuery,
  getCatalogItem,
  itemPrices as itemPricesQuery,
  loadCatalogItems,
  setPrices as setPricesQuery,
  updateCatalogItem,
  createCatalogItem,
} from '../../../graphql/queries';
import {
  CatalogItemInput,
  CatalogItemType,
  CatalogPageState,
  Category,
  Price,
} from '../../../lib/types';
import './catalog.css';
import { flowRight as compose } from 'lodash';
import { RouteComponentProps, navigate, Link } from '@reach/router';
import { withSnackbar } from 'notistack';
import { ApolloError } from 'apollo-client';
import { ArrowBack } from '@material-ui/icons';

interface IProps extends RouteComponentProps<{ catalogItemId: string }> {
  catalogItems: any;
  categories: any;
  itemPrices: any;
  setPrices: any;
  classes: any;
  enqueueSnackbar: (message: string, options?: {}) => void;
}

interface IState {
  catalogItem: CatalogItemInput;
  errorOpen: boolean;
  suggestions: string[];
  CatalogState: CatalogPageState;
  allowedCategoriesDialog: boolean;
}

const emptyCatalogItem: CatalogItemInput = {
  id: null,
  categoryId: 0,
  purpose: '',
  name: '',
  size: '',
  selectSize: 0,
  variant: '',
  producer: '',
  note: '',
  pair: true,
  createsSet: false,
  settings: false,
  price: 0,
  itemPriceId: 0,
  setPriceId: 0,
  universal: false,
  seasonDisabled: false,
  barCode: '',
};

// autosuggest
const getSuggestions = (collection: string[], value: string) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  return collection.filter(
    (suggestion: string) =>
      suggestion.slice(0, inputLength).toLowerCase() === inputValue,
  );
};

function renderSuggestion(
  suggestion: string,
  { isHighlighted }: { isHighlighted: boolean },
) {
  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        <strong key={suggestion} style={{ fontWeight: 300 }}>
          {suggestion}
        </strong>
      </div>
    </MenuItem>
  );
}

function getSuggestionValue(suggestion: string) {
  return suggestion;
}

const renderInputComponent = (inputProps: any) => {
  // tslint:disable-next-line:no-empty
  const { classes, inputRef = () => {}, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth={true}
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
      }}
      {...other}
    />
  );
};

function getMutationInput(isNewItem: boolean, catalogItem: CatalogItemInput) {
  const toOmit = [];
  if (isNewItem) {
    toOmit.push('id');
  } else {
    toOmit.push('barCode');
    toOmit.push('universal');
  }
  if (!catalogItem.createsSet) {
    toOmit.push('setPriceId');
  }
  return omit(toOmit, {
    ...catalogItem,
    barCode: catalogItem.barCode?.toString(),
  });
}

class CatalogItemEdit extends React.Component<
  WithApolloClient<IProps>,
  IState
> {
  state = {
    catalogItem: emptyCatalogItem,
    errorOpen: false,
    suggestions: [],
    CatalogState: CatalogStateDefault,
    allowedCategoriesDialog: false,
  };

  componentWillMount() {
    const state: any = pagesState.getPageState(PagesNames.Catalog);
    if (state) {
      this.setState({ CatalogState: state });
    }
  }
  handleChange = (
    name: string,
    isNumber: boolean = false,
    isFloat: boolean = false,
  ) => (event: { target: { value: string }; persist: any }) => {
    event.persist();
    this.setState(prevState => ({
      catalogItem: {
        ...prevState.catalogItem,
        [name]: isNumber
          ? isFloat
            ? parseFloat(event.target.value)
            : parseInt(event.target.value, 10)
          : event.target.value,
      },
    }));
  };

  handleOpenAllowedCategories = () =>
    this.setState(prevState => ({
      allowedCategoriesDialog: !prevState.allowedCategoriesDialog,
    }));

  handleChangeAutosuggest = (name: string) => (
    event: React.FormEvent<any>,
    params?: Autosuggest.ChangeEvent,
  ) => {
    if (name === 'variant') {
      this.clearPrices();
    }
    event.persist();
    this.setState(prevState => ({
      catalogItem: {
        ...prevState.catalogItem,
        [name]: params ? params.newValue : '',
      },
    }));
  };

  handleCheckboxChange = (name: string) => (event: {
    target: { checked: boolean };
    persist: any;
  }) => {
    event.persist();
    if (name === 'universal') {
      this.setState(prevState => ({
        catalogItem: {
          ...prevState.catalogItem,
          universal: !prevState.catalogItem.universal,
          createsSet: false,
          settings: false,
          purpose: 'UNISEX',
          variant: 'BASIC',
          size: '0',
        },
      }));
    } else {
      this.setState(prevState => ({
        catalogItem: {
          ...prevState.catalogItem,
          [name]: event.target.checked,
        },
      }));
    }
  };

  handleSelectBoxChange = (name: string) => (event: any) => {
    event.persist();
    this.setState(prevState => ({
      catalogItem: {
        ...prevState.catalogItem,
        [name]: event.target.value,
      },
    }));
  };

  componentDidMount() {
    if (
      this.props.catalogItemId &&
      !isNaN(parseInt(this.props.catalogItemId, 10))
    ) {
      const item: any = this.props.client.query({
        query: getCatalogItem,
        variables: { id: this.props.catalogItemId },
      });
      item.then((data: any) => {
        const catalogItem: CatalogItemInput = dissoc(
          '__typename',
          dissoc('category', data.data.catalogItem),
        );
        catalogItem.categoryId = data.data.catalogItem.category.id;
        this.setState({
          catalogItem,
        });
      });
    } else {
      this.setState({
        catalogItem: emptyCatalogItem,
      });
    }
  }

  handleSuggestionsFetchRequested = (collection: string[]) => ({
    value,
  }: {
    value: string;
  }) => {
    this.setState({
      suggestions: getSuggestions(collection, value),
    });
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  clearPrices = () => {
    this.setState(prevState => ({
      catalogItem: {
        ...prevState.catalogItem,
        itemPriceId: undefined,
        setPriceId: undefined,
      },
    }));
  };

  validate = (postMutation: () => any) => () => {
    if (
      this.state.catalogItem.name.length === 0 ||
      this.state.catalogItem.producer.length === 0 ||
      !this.state.catalogItem.categoryId ||
      this.state.catalogItem.purpose.length === 0 ||
      this.state.catalogItem.variant.length === 0 ||
      this.state.catalogItem.size.length === 0 ||
      !this.state.catalogItem.itemPriceId
    ) {
      alert('Vyplňte povinné položky!');
      return false;
    }
    if (
      this.state.catalogItem.createsSet &&
      !this.state.catalogItem.setPriceId
    ) {
      alert('Doplňte cenu pro set!');
      return false;
    }
    if (
      this.state.catalogItem.universal &&
      this.state.catalogItem.barCode.length < 3
    ) {
      alert('Čárový kód musí mít alespoň 3 číslice');
      return false;
    }
    return postMutation();
  };

  sortPrices = (a: Price, b: Price) => {
    let result = 0;
    if (a.name < b.name) {
      result = -1;
    }
    if (a.name > b.name) {
      result = 1;
    }
    return result;
  };

  render() {
    const catalogState = this.state.CatalogState;
    const theme = {
      container: 'text-field container',
      suggestionsContainerOpen: 'suggestions-container-open',
      suggestionsList: 'suggestions-list',
      suggestion: 'suggestion',
    };

    const autosuggestProps = {
      renderSuggestion,
      renderInputComponent,
      getSuggestionValue,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      suggestions: this.state.suggestions,
      shouldRenderSuggestions: () => true,
      renderSuggestionsContainer: (options: any) => (
        <Paper {...options.containerProps} square={true}>
          {options.children}
        </Paper>
      ),
      theme,
    };
    const isLoading = !(
      !this.props.catalogItems.loading &&
      !this.props.categories.loading &&
      !this.props.itemPrices.loading &&
      !this.props.setPrices.loading
    );

    let catalogItems: CatalogItemType[] = [];
    let categories: Category[] = [];
    let itemPrices: Price[] = [];
    let setPrices: Price[] = [];
    if (!isLoading) {
      catalogItems = this.props.catalogItems.catalogItems.items;
      categories = this.props.categories.categories;
      itemPrices = JSON.parse(JSON.stringify(this.props.itemPrices.itemPrices));
      setPrices = JSON.parse(JSON.stringify(this.props.setPrices.setPrices));
    }

    const { setPriceId, universal } = this.state.catalogItem;

    const isNewItem = this.props.catalogItemId === 'NaN';

    return (
      <div>
        {isLoading ? (
          'Loading'
        ) : (
          <div>
            <Link to="/catalog/items" title="Zpět na seznam">
              <ArrowBack style={{ margin: 10 }} />
            </Link>
            <form className="form-container" autoComplete="off">
              <FormControl className="text-field">
                <InputLabel htmlFor="age-helper">Kategorie</InputLabel>
                <Select
                  value={this.state.catalogItem.categoryId}
                  onChange={this.handleSelectBoxChange('categoryId')}
                  color="primary"
                >
                  {categories.map(category => (
                    <MenuItem value={category.id} key={category.id}>
                      <em>{category.name}</em>
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>Kategorie produktu</FormHelperText>
              </FormControl>
              <Autosuggest
                {...autosuggestProps}
                onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested(
                  uniq(
                    catalogItems.map((item: CatalogItemType) => item.purpose),
                  ),
                )}
                inputProps={{
                  placeholder: 'hledej určení',
                  required: true,
                  type: 'string',
                  label: 'Určení',
                  value: this.state.catalogItem.purpose,
                  onChange: this.handleChangeAutosuggest('purpose'),
                }}
              />
              <Autosuggest
                {...autosuggestProps}
                onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested(
                  uniq(
                    catalogItems.map((item: CatalogItemType) => item.variant),
                  ),
                )}
                inputProps={{
                  placeholder: 'hledej variantu',
                  required: true,
                  label: 'Varianta',
                  type: 'string',
                  value: this.state.catalogItem.variant,
                  onChange: this.handleChangeAutosuggest('variant'),
                }}
              />
              <TextField
                label="Název"
                type="string"
                required={true}
                value={this.state.catalogItem.name}
                className="text-field"
                onChange={this.handleChange('name')}
              />
              <TextField
                label="Velikost/délka"
                type="string"
                required={true}
                value={this.state.catalogItem.size}
                className="text-field"
                onChange={this.handleChange('size')}
              />
              <TextField
                label="Číselná velikost"
                type="number"
                inputProps={{
                  step: '0.5',
                }}
                required={true}
                value={this.state.catalogItem.selectSize}
                className="text-field"
                onChange={this.handleChange('selectSize', true, true)}
              />
              <FormControl className="text-field">
                <Autosuggest
                  {...autosuggestProps}
                  onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested(
                    uniq(
                      catalogItems.map(
                        (item: CatalogItemType) => item.producer,
                      ),
                    ),
                  )}
                  inputProps={{
                    id: 'producer',
                    placeholder: 'hledej výrobce',
                    required: true,
                    type: 'string',
                    value: this.state.catalogItem.producer,
                    onChange: this.handleChangeAutosuggest('producer'),
                    label: 'Výrobce',
                  }}
                />
              </FormControl>
              <TextField
                label="Poznámka"
                type="string"
                value={this.state.catalogItem.note}
                className="text-field"
                onChange={this.handleChange('note')}
              />
              <TextField
                label="Pořizovací cena"
                type="number"
                required={true}
                value={this.state.catalogItem.price}
                className="text-field"
                onChange={this.handleChange('price', true)}
              />
              <FormControl className="select">
                <InputLabel htmlFor="age-helper">
                  Cena položky<sup>*</sup>
                </InputLabel>
                <Select
                  required
                  value={this.state.catalogItem.itemPriceId}
                  onChange={this.handleSelectBoxChange('itemPriceId')}
                  color="primary"
                >
                  {itemPrices.sort(this.sortPrices).map((price: Price) => {
                    return (
                      <MenuItem value={price.id} key={price.id}>
                        <em>
                          {price.name} ({price.oneDayPrice} Kč/den)
                        </em>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.catalogItem.pair}
                    value="pair"
                    onChange={this.handleCheckboxChange('pair')}
                    color="primary"
                  />
                }
                label="Párové"
              />
              {isNewItem && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.catalogItem.universal}
                      value="universal"
                      onChange={this.handleCheckboxChange('universal')}
                      color="primary"
                    />
                  }
                  label="Univerzální *"
                />
              )}
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.catalogItem.createsSet}
                    disabled={universal}
                    value="createsSet"
                    onChange={this.handleCheckboxChange('createsSet')}
                    color="primary"
                  />
                }
                label="Set"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.catalogItem.settings}
                    disabled={universal}
                    value="settings"
                    onChange={this.handleCheckboxChange('settings')}
                    color="primary"
                  />
                }
                label="Seřízení"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.catalogItem.seasonDisabled}
                    value="seasonDisabled"
                    onChange={this.handleCheckboxChange('seasonDisabled')}
                    color="primary"
                  />
                }
                label="Zákaz sezóny"
              />
              {universal && (
                <TextField
                  label="Čárový kód"
                  type="number"
                  required={true}
                  value={this.state.catalogItem.barCode}
                  className="text-field"
                  onChange={this.handleChange('barCode', true)}
                />
              )}
              {this.state.catalogItem.createsSet && (
                <>
                  <FormControl className="select">
                    <InputLabel htmlFor="age-helper">
                      Setová cena<sup>*</sup>
                    </InputLabel>
                    <Select
                      required
                      value={this.state.catalogItem.setPriceId}
                      onChange={this.handleSelectBoxChange('setPriceId')}
                      color="primary"
                    >
                      <MenuItem value={undefined}>
                        <em>Vyberte</em>
                      </MenuItem>
                      {setPrices.sort(this.sortPrices).map((price: Price) => {
                        return (
                          <MenuItem value={price.id} key={price.id}>
                            <em>
                              {price.name} ({price.oneDayPrice} Kč/den)
                            </em>
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText>Cena setu</FormHelperText>
                  </FormControl>
                  <Button
                    style={{ maxHeight: 50 }}
                    variant="contained"
                    color="primary"
                    disabled={this.state.catalogItem.setPriceId === undefined}
                    onClick={this.handleOpenAllowedCategories}
                  >
                    Nastavit povolené kategorie
                  </Button>
                </>
              )}
            </form>
            <div className="centerFlex">
              <Mutation
                mutation={isNewItem ? createCatalogItem : updateCatalogItem}
                variables={{
                  catalogItem: getMutationInput(
                    isNewItem,
                    this.state.catalogItem,
                  ),
                }}
                onCompleted={() => {
                  this.props.enqueueSnackbar('Uloženo', { variant: 'success' });
                  navigate('/catalog/items/');
                }}
                onError={(err: ApolloError) => {
                  const errName =
                    err.graphQLErrors && err.graphQLErrors[0].name;
                  let message = 'Záznam se nepodařilo uložit';
                  if (errName === 'UniversalCatalogItemButBarCodeInvalid') {
                    message = 'Nevalidní Čárový kód';
                  } else if (errName === 'ItemAlreadyExists') {
                    message = 'Položka s tímto čárovým kódem již existuje';
                  }
                  this.props.enqueueSnackbar(message, { variant: 'error' });
                }}
                refetchQueries={[
                  {
                    query: loadCatalogItems,
                    variables: {
                      offset:
                        catalogState.itemsCountPerPage *
                        (catalogState.activePage - 1),
                      limit: catalogState.itemsCountPerPage,
                      sort: catalogState.order,
                      order: catalogState.direction,
                      search: catalogState.search,
                      barCode: catalogState.barCode,
                    },
                  },
                ]}
              >
                {(postMutation: any) => {
                  return (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.validate(postMutation)}
                    >
                      Uložit
                    </Button>
                  );
                }}
              </Mutation>
            </div>
            {isNewItem && (
              <div className={this.props.classes.universalItemText}>
                * Univerzální položka je speciální typ katalogové položky, který
                se od klasické liší následovně:
                <ul>
                  <li>
                    Je možné jí přiřadit pouze jeden čárový kód, který nelze
                    později změnit.
                  </li>
                  <li>
                    Nekontroluje se dostupnost (systém předpokládá, že jich je
                    ve skladu vždy dostatek)
                  </li>
                  <li>Nemůže tvořit Set (automaticky se nastavuje na false)</li>
                  <li>Není možné ji seřídit</li>
                  <li>
                    Při přidávání do setu se nekontroluje správnost Určení
                  </li>
                  <li>
                    Lze jí přidat do jakékoliv varianty setu (BASIC, TOP, TEST)
                  </li>
                  <li>
                    Při přidávání do setu se nekontroluje správná velikost
                    (systém předpokládá, že ve skladu vždy bude správná
                    velikost)
                  </li>
                </ul>
              </div>
            )}
          </div>
        )}
        {this.state.allowedCategoriesDialog && setPriceId !== undefined && (
          <AllowedCategoriesDialog
            title={setPrices.find(price => price.id === setPriceId)!.name}
            open={this.state.allowedCategoriesDialog}
            onClose={this.handleOpenAllowedCategories}
            setPriceId={setPriceId}
          />
        )}
      </div>
    );
  }
}

const styles = (theme: any) => ({
  universalItemText: {
    marginTop: '32px',
    margin: '16px',
  },
});

export default compose(
  withApollo,
  graphql(categoriesQuery, { name: 'categories' }),
  graphql(itemPricesQuery, { name: 'itemPrices' }),
  graphql(setPricesQuery, { name: 'setPrices' }),
  graphql(loadCatalogItems, { name: 'catalogItems' }),
  withSnackbar,
)(withStyles(styles)(CatalogItemEdit));
