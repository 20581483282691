import { Router } from '@reach/router';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { ApolloProvider } from 'react-apollo';
import apolloClient from './graphql/client';
import {
  CashRegister,
  CashRegisterDetail,
  Catalog,
  Login,
  OrdersAndReservations,
  Paymnents,
} from './pages';
import Others from './pages/Others/Orhers';
import PriceList from './pages/PriceList/PriceList';
import ProtectedRoute from './pages/ProtectedRoute';
import Settings from './pages/Settings/Settings';
import Statistics from './pages/Statistics/Statistics';

const App = () => (
  <SnackbarProvider maxSnack={3}>
    <ApolloProvider client={apolloClient}>
      <Router>
        <Login path="/login" default={true} />
        <ProtectedRoute
          path="orders-and-reservations/*"
          component={OrdersAndReservations}
          adminRights={false}
        />
        <ProtectedRoute
          path="catalog/*"
          component={Catalog}
          adminRights={false}
        />
        <ProtectedRoute
          path="price-list/*"
          component={PriceList}
          adminRights={true}
        />
        <ProtectedRoute
          path="payments/*"
          component={Paymnents}
          adminRights={true}
        />
        <ProtectedRoute
          path="settings/"
          component={Settings}
          adminRights={true}
        />
        <ProtectedRoute
          path="cash-register/:id"
          component={CashRegisterDetail}
          adminRights={false}
        />
        <ProtectedRoute
          path="statistics/*"
          component={Statistics}
          adminRights={true}
        />
        <ProtectedRoute
          path="cash-register"
          component={CashRegister}
          adminRights={false}
        />
        <ProtectedRoute path="others/*" component={Others} adminRights={true} />
      </Router>
    </ApolloProvider>
  </SnackbarProvider>
);

export default App;
