import {
  createStyles,
  Fab,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Theme,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DescriptionIcon from '@material-ui/icons/Description';
import React, { useState } from 'react';
import { useApolloClient, useMutation, useQuery } from 'react-apollo';
import AddDescription from '../../../components/AddDescription';
import {
  GET_CATALOG_GROUP_LENGTH,
  GET_INPUT_WITH_CATALOG_ITEM_ID,
} from '../../../graphql/localQueries';
import {
  addCatalogItemToCatalogGroupMutaion,
  catalogGroupsQuery,
} from '../../../graphql/queries';
import {
  addCatalogItem as addCatalogItemType,
  addCatalogItemVariables,
} from '../../../graphql/types';
import { handleMutation } from './utils';

interface IProps {
  itemIds: string[];
  name: string;
  producer: string;
  sizes: string[];
}

const CellActionAddItem: React.FunctionComponent<IProps> = props => {
  const { data } = useQuery(GET_CATALOG_GROUP_LENGTH);
  const [addCatalogItem] = useMutation<
    addCatalogItemType,
    addCatalogItemVariables
  >(addCatalogItemToCatalogGroupMutaion, {
    refetchQueries: [{ query: catalogGroupsQuery }],
  });
  const client = useApolloClient();
  const [position, setPosition] = useState(0);
  const [addDescOpen, setAddDescOpen] = useState(false);
  const classes = useStyles();

  const handlePositionChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    child: React.ReactNode,
  ) => {
    event.preventDefault();
    setPosition(event.target.value as number);
  };

  const handleAddDescClick = () => setAddDescOpen(!addDescOpen);

  const handleAddButtonClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    const inputWith = client.readQuery({
      query: GET_INPUT_WITH_CATALOG_ITEM_ID,
    });

    const idsAsNumbers = props.itemIds.map(id => parseInt(id, 10));
    if (inputWith && inputWith.inputWithCatalogItemID.catalogGroupId) {
      handleMutation<addCatalogItemType, addCatalogItemVariables>(
        client,
        {
          catalogItemIds: idsAsNumbers,
          catalogGroupId: inputWith.inputWithCatalogItemID.catalogGroupId,
          positionInCatalogGroup: position,
        },
        addCatalogItem,
      );
    }
  };

  const id = `select-order-${props.itemIds[0]}`;
  return (
    <div className={classes.controlsWrapper}>
      <FormControl className={classes.formControl}>
        <InputLabel id={id}>Pořadí</InputLabel>
        <Select labelId={id} value={position} onChange={handlePositionChange}>
          <MenuItem value={0} disabled={true}>
            Vyberte
          </MenuItem>
          {data &&
            [...Array(data.catalogGroupLength + 1).keys()].map(
              (item: number) => (
                <MenuItem value={item + 1} key={`${id}-${item + 1}`}>
                  {item + 1}
                </MenuItem>
              ),
            )}
        </Select>
      </FormControl>
      <Fab
        color="primary"
        variant="round"
        className={classes.fab}
        disabled={position === 0}
        onClick={handleAddButtonClick}
      >
        <AddIcon />
      </Fab>
      <Fab
        variant="round"
        className={`${classes.fab} ${classes.fabDescription}`}
        onClick={handleAddDescClick}
      >
        <DescriptionIcon />
      </Fab>
      {addDescOpen && (
        <AddDescription
          open={addDescOpen}
          onClose={handleAddDescClick}
          name={props.name}
          producer={props.producer}
          sizes={props.sizes}
          itemIds={props.itemIds}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 82,
      textAlign: 'left',
    },
    controlsWrapper: {
      display: 'inline-flex',
      alignItems: 'flex-end',
    },
    fab: {
      width: 36,
      height: 36,
      marginLeft: 8,
    },
    fabDescription: {
      backgroundColor: 'green',
      color: 'white',
    },
  }),
);

export default CellActionAddItem;
