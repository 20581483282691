import React from 'react';
import { useQuery, QueryResult, QueryHookOptions } from 'react-apollo';
import { OperationVariables } from '@apollo/react-common';
import { DocumentNode } from 'graphql';
import LoadingModal from './LoadingModal';

type ChildrenArgs<TData> = Omit<
  QueryResult<TData>,
  'loading' | 'data' | 'error'
> & { data: TData };

interface Props<TData, TVariables> {
  query: DocumentNode;
  options?: QueryHookOptions<TData, TVariables>;
  children: (result: ChildrenArgs<TData>) => JSX.Element | null;
}

export default function GraphQLErrorLoadingBoundary<
  TData = any,
  TVariables = OperationVariables
>({ query, children, options }: Props<TData, TVariables>) {
  const { loading, error, data, ...others } = useQuery<TData, TVariables>(
    query,
    options,
  );

  if (loading) {
    return <LoadingModal />;
  }
  if (error || data === undefined) {
    return <>Error</>;
  }

  return children({ data, ...others });
}
