import { RouteComponentProps } from '@reach/router';
import * as React from 'react';
import { withApollo, useQuery } from 'react-apollo';
import { categoryEarningsQuery } from '../../graphql/queries';
import {
  CategoryEarningsQuery,
  CategoryEarningsQueryVariables,
} from '../../graphql/types';
import {
  Box,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  TextField,
  Button,
  withStyles,
} from '@material-ui/core';
import moment from 'moment';

interface IProps {
  classes?: any;
  client: any;
}
const styles = (theme: any) => ({
  datePicker: {
    marginLeft: 10,
    marginRight: 10,
  },
});

const CategoryEarnings = ({ classes }: IProps) => {
  const defaultTo = moment().format('YYYY-MM-DD');
  const defaultFrom = moment()
    .subtract(6, 'months')
    .format('YYYY-MM-DD');
  const [fromDate, setFromDate] = React.useState<string>(defaultFrom);
  const [toDate, setToDate] = React.useState<string>(defaultTo);
  const [params, setParams] = React.useState({
    to: defaultTo,
    from: defaultFrom,
  });
  const { data } = useQuery<
    CategoryEarningsQuery,
    CategoryEarningsQueryVariables
  >(categoryEarningsQuery, {
    variables: {
      from: params.from,
      to: params.to,
    },
    fetchPolicy: 'cache-and-network',
  });
  return (
    <Box>
      <Box display="flex" flex={1} alignItems="center" marginBottom="24px">
        <TextField
          label="Od"
          type="date"
          value={fromDate}
          className={classes.datePicker}
          onChange={event => {
            console.log(event.target.value);
            console.log(event.target.value);
            setFromDate(event.target.value);
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          label="Do"
          type="date"
          value={toDate}
          className={classes.datePicker}
          onChange={event => setToDate(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button
          variant="contained"
          onClick={() =>
            setParams({
              from: fromDate,
              to: toDate,
            })
          }
        >
          Hledat
        </Button>
      </Box>
      <Box maxHeight="calc(100vh - 240px)" overflow="auto" width="100%">
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Název kategorie</TableCell>
              <TableCell>Přijem</TableCell>
              <TableCell>Storno</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.categoryEarnings.map(category => (
              <TableRow>
                <TableCell>{category.name}</TableCell>
                <TableCell>{category.amount}</TableCell>
                <TableCell>{category.stornoAmount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

const CategoryEarningsWithStylesApollo = withStyles(styles)(
  withApollo(CategoryEarnings),
);

const WrapperComponent: React.FunctionComponent<RouteComponentProps> = () => (
  <CategoryEarningsWithStylesApollo />
);

export default WrapperComponent;
