import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Fab,
  Dialog,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import * as React from 'react';
import { Query } from 'react-apollo';
import auth from '../../../lib/auth';
import { categories } from '../../../graphql/queries';
import { Category as CategoryType } from '../../../lib/types';
import UploadImageDialog from '../../../components/UploadImageDialog';
import CategoryEditDialog from './CategoryEditDialog';

type IState = {
  open: number | null;
  categoryToEdit: Partial<CategoryType> | null;
};

class Categories extends React.Component<any, IState> {
  state: IState = {
    open: null,
    categoryToEdit: null,
  };

  sortCategory = (a: CategoryType, b: CategoryType) => {
    let result = 0;
    if (a.id < b.id) {
      result = -1;
    } else if (a.id > b.id) {
      result = 1;
    }
    return result;
  };

  handleEditCategory = (category: CategoryType) => () =>
    this.setState({
      categoryToEdit: category,
    });

  handleAddCategory = () => this.setState({ categoryToEdit: {} });
  handleCategoryEditClose = () => this.setState({ categoryToEdit: null });

  handleOpenDialog = (id: number) => () => this.setState({ open: id });
  handleClose = () => this.setState({ open: null });

  render() {
    const isAdmin = auth.isAdmin();
    return (
      <div className="Item">
        <Query query={categories}>
          {(data: any) => {
            if (data.loading) {
              return <p>Loading...</p>;
            }
            if (data.error) {
              return <p>Error :</p>;
            }

            const categoriesData = JSON.parse(
              JSON.stringify(data.data.categories),
            );
            let openedCD = null;
            if (this.state.open !== null) {
              openedCD = categoriesData.find(
                (ctd: any) => ctd.id === this.state.open,
              );
            }
            return (
              <div>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Název</TableCell>
                      {isAdmin && (
                        <>
                          <TableCell>Položky se počítají do setu</TableCell>
                          <TableCell>Úprava obrázku</TableCell>
                          <TableCell>Editace</TableCell>
                        </>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {categoriesData
                      .sort(this.sortCategory)
                      .map((item: CategoryType) => (
                        <TableRow key={item.id}>
                          <TableCell>{item.id}</TableCell>
                          <TableCell>{item.name}</TableCell>
                          {isAdmin && (
                            <>
                              <TableCell>
                                {!item.itemsExcludedFromSet ? 'Ano' : 'Ne'}
                              </TableCell>
                              <TableCell>
                                <Button
                                  onClick={this.handleOpenDialog(item.id)}
                                >
                                  <EditIcon />
                                </Button>
                              </TableCell>
                              <TableCell>
                                <Button onClick={this.handleEditCategory(item)}>
                                  <EditIcon />
                                </Button>
                              </TableCell>
                            </>
                          )}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '24px',
                  }}
                >
                  <Tooltip title="Přidat">
                    <Fab color="primary" onClick={this.handleAddCategory}>
                      <AddIcon />
                    </Fab>
                  </Tooltip>
                </div>
                {this.state.open && openedCD && (
                  <UploadImageDialog
                    id={this.state.open}
                    entity={'allowedCategories'}
                    open={this.state.open !== null}
                    label={openedCD.name}
                    onClose={this.handleClose}
                    pathToCurrentImage={openedCD.imagePath}
                    onUpdate={() => data.refetch()}
                  />
                )}
                <Dialog
                  onClose={this.handleCategoryEditClose}
                  open={!!this.state.categoryToEdit}
                >
                  <CategoryEditDialog
                    onClose={this.handleCategoryEditClose}
                    category={this.state.categoryToEdit || {}}
                  />
                </Dialog>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default Categories;
