import React from 'react';
import { CatalogItemCellType } from '../../../lib/types';
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
} from '@material-ui/core';
import { CatalogItemsInCatalogGroupVariables } from '../../../graphql/types';
import { writeInputToCache } from './utils';
import { useApolloClient } from 'react-apollo';

interface IProps {
  tableCellsDefinition: CatalogItemCellType[];
  variables: CatalogItemsInCatalogGroupVariables;
  refetch: (
    variables?: CatalogItemsInCatalogGroupVariables | undefined,
  ) => void;
}

const CatalogItemTableHead: React.FunctionComponent<IProps> = (
  props: IProps,
) => {
  const { variables } = props;
  const client = useApolloClient();

  const handleSort = (sort: string) => (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault();
    variables.input.sort = sort;
    variables.input.order = variables.input.order === 'desc' ? 'asc' : 'desc';
    props.refetch(variables);
    writeInputToCache(client, variables.input);
  };
  return (
    <TableHead>
      <TableRow>
        {props.tableCellsDefinition.map(cellDefiniton =>
          cellDefiniton.sortable ? (
            <TableCell
              style={{ padding: 8 }}
              key={cellDefiniton.entity}
              align="left"
            >
              <TableSortLabel
                active={variables.input.sort === cellDefiniton.entity}
                direction={(variables.input.order || undefined) as any}
                onClick={handleSort(cellDefiniton.entity)}
              >
                {cellDefiniton.label}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell
              style={{ padding: 8 }}
              key={cellDefiniton.entity}
              align="left"
            >
              {cellDefiniton.label}
            </TableCell>
          ),
        )}
      </TableRow>
    </TableHead>
  );
};

export default CatalogItemTableHead;
