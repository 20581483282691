import {
  CircularProgress,
  createStyles,
  makeStyles,
  Modal,
  Theme,
} from '@material-ui/core';
import React from 'react';

const LoadingModal: React.FunctionComponent = () => {
  const classes = useStyles();
  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={true}
      hideBackdrop
    >
      <CircularProgress className={classes.progress} />
    </Modal>
  );
};

export default LoadingModal;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      position: 'absolute',
      marginTop: '50vh',
      marginLeft: '50vw',
      outline: 'none',
    },
  }),
);
