import React from 'react';
import PageDrawer from '../PageDrawer';
import RouteWrapper from '../RouteWrapper';
import CategoryEarnings from './CategoryEarnings';
import StockUsage from './StockUsage';
import { Router } from '@reach/router';

type Props = {
  pathName: string;
};

const Statistics: React.FunctionComponent<Props> = ({ pathName }) => (
  <PageDrawer
    listItems={[
      {
        label: 'Výdělek za kategorii',
        to: 'earnings',
        adminRightsRequired: true,
      },
      { label: 'Použivání', to: 'usage', adminRightsRequired: true },
    ]}
    selectedItem={pathName}
  >
    <Router>
      <RouteWrapper path="statistics" default>
        <CategoryEarnings path="earnings" />
        <StockUsage path="usage" />
      </RouteWrapper>
    </Router>
  </PageDrawer>
);

export default Statistics;
