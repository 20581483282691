import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import * as React from 'react';
import {
  catalogGroupsQuery,
  filterByIdQuery,
  mutateFiltersQuery,
} from '../../../graphql/queries';
import './catalog.css';
import { useEffect, useState } from 'react';
import {
  BasketSetType,
  CCSPurpose,
  CCSStyle,
  CatalogItemType,
  Sex,
  SkiArc,
  SkiSlope,
  Skills,
} from '../../../graphql/types';
import { useMutation, useQuery } from 'react-apollo';
import { RouteComponentProps, useNavigate } from '@reach/router';

const useStyles = makeStyles({
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '100%',
    gap: '50px',
  },
  twoRowsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'flex-start',
    maxWidth: '100%',
    gap: '50px',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '40px',
    alignItems: 'center',
    maxWidth: '100%',
  },
  inputSection: {
    marginTop: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginLeft: '50px',
  },
  input: {
    maxWidth: '100%',
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

const FilterEdit = ({
  filterId,
}: RouteComponentProps<{ filterId: string | undefined }>) => {
  const classes = useStyles();

  const navigate = useNavigate();

  const { data: catalogGroupsData, loading: catalogGroupsLoading } = useQuery(
    catalogGroupsQuery,
  );

  const { loading, error } = useQuery(filterByIdQuery, {
    skip: !filterId || filterId === 'create',
    variables: {
      id: Number(filterId),
    },
    onCompleted: data => {
      setBasketSetType(BasketSetType[data?.getFilterById?.[0]?.basketSetType]);
      setCatalogItemType(
        CatalogItemType[data?.getFilterById?.[0]?.catalogItemType],
      );
      setCCSStyle(CCSStyle[data?.getFilterById?.[0]?.ccsStyle]);
      setCCSPurpose(CCSPurpose[data?.getFilterById?.[0]?.ccsPurpose]);
      setSex(Sex[data?.getFilterById?.[0]?.sex]);
      setSkills(Skills[data?.getFilterById?.[0]?.skills]);
      setSkiSlope(SkiSlope[data?.getFilterById?.[0]?.skiSlope]);
      setSkiArc(SkiArc[data?.getFilterById?.[0]?.skiArc]);
      setPrimaryCodes([
        ...new Set(
          data?.getFilterById?.[0]?.catalogGroups?.map(
            (item: { code: string }) => item.code || '',
          ),
        ),
      ] as string[]);
    },
  });

  const [editFilter] = useMutation(mutateFiltersQuery, {
    onCompleted: () => {
      filterId === 'create'
        ? window.alert('Filter created successfully')
        : window.alert('Filter edited successfully');
      navigate('/catalog/filters');
    },
    onError: error => {
      window.alert(`Error occured creating filter ${error.message}`);
    },
  });

  const [basketSetType, setBasketSetType] = useState<BasketSetType>();
  const [catalogItemType, setCatalogItemType] = useState<CatalogItemType>();
  const [ccsStyle, setCCSStyle] = useState<CCSStyle>();
  const [ccsPurpose, setCCSPurpose] = useState<CCSPurpose>();
  const [sex, setSex] = useState<Sex>();
  const [skills, setSkills] = useState<Skills>();
  const [skiSlope, setSkiSlope] = useState<SkiSlope>();
  const [skiArc, setSkiArc] = useState<SkiArc>();
  const [primaryCodes, setPrimaryCodes] = useState<string[]>([]);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [currentPrimaryCode, setCurrentPrimaryCode] = useState<
    string | undefined
  >();

  const addPrimaryCode = () => {
    if (currentPrimaryCode) {
      setPrimaryCodes([...primaryCodes, currentPrimaryCode]);
      setCurrentPrimaryCode(undefined);
    }
  };

  const removePrimaryCode = (index: number) => {
    const newPrimaryCodes = [...primaryCodes];
    newPrimaryCodes.splice(index, 1);
    setPrimaryCodes(newPrimaryCodes);
  };

  const validatePrimaryCodes = (primaryCode: string) => {
    const pattern = /^A\d+$/;
    const match = primaryCode.match(pattern);
    return Boolean(match);
  };

  useEffect(() => {
    const primaryCodesValid =
      primaryCodes.every(validatePrimaryCodes) && primaryCodes.length > 0;

    if (primaryCodesValid && basketSetType && catalogItemType) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [primaryCodes, basketSetType, catalogItemType]);

  const handleSubmit = () => {
    editFilter({
      variables: {
        input: {
          id: Number(filterId),
          primaryCodes,
          filters: {
            basketSetType,
            catalogItemType,
            ccsStyle,
            ccsPurpose,
            sex,
            skills,
            skiSlope,
            skiArc,
          },
        },
      },
    });
    navigate('/catalog/filters');
  };

  return (
    <div>
      {loading && <div>Loading...</div>}
      {error && <div>Error: {error.message}</div>}
      {!error && !loading && (
        <div>
          <form>
            <div className={classes.mainContainer}>
              <div className={classes.twoRowsContainer}>
                <div className={classes.formContainer}>
                  <FormControl>
                    <InputLabel required>Typ košíku</InputLabel>
                    <Select
                      key={basketSetType}
                      value={basketSetType}
                      onChange={e =>
                        setBasketSetType(e.target.value as BasketSetType)
                      }
                      color="primary"
                    >
                      {Object.keys(BasketSetType).map(key => (
                        <MenuItem key={key} value={key}>
                          {BasketSetType[key]}
                        </MenuItem>
                      ))}
                      <MenuItem value={undefined}>Nenastaveno</MenuItem>
                    </Select>
                    <FormHelperText error={!isValid && !basketSetType}>
                      Vyberte typ košíku<sup>*</sup>
                    </FormHelperText>
                  </FormControl>
                  <FormControl>
                    <InputLabel required>Katalogový typ</InputLabel>
                    <Select
                      key={catalogItemType}
                      value={catalogItemType}
                      onChange={e =>
                        setCatalogItemType(e.target.value as CatalogItemType)
                      }
                      color="primary"
                    >
                      {Object.keys(CatalogItemType).map(key => (
                        <MenuItem key={key} value={key}>
                          {CatalogItemType[key]}
                        </MenuItem>
                      ))}
                      <MenuItem value={undefined}>Nenastaveno</MenuItem>
                    </Select>
                    <FormHelperText error={!isValid && !catalogItemType}>
                      Vyberte Katalogový typ<sup>*</sup>
                    </FormHelperText>
                  </FormControl>
                  <FormControl>
                    <InputLabel>CCS styl</InputLabel>
                    <Select
                      key={ccsStyle}
                      value={ccsStyle}
                      onChange={e => setCCSStyle(e.target.value as CCSStyle)}
                      color="primary"
                    >
                      {Object.keys(CCSStyle).map(key => (
                        <MenuItem key={key} value={key}>
                          {CCSStyle[key]}
                        </MenuItem>
                      ))}
                      <MenuItem value={undefined}>Nenastaveno</MenuItem>
                    </Select>
                    <FormHelperText>Vyberte CCS styl</FormHelperText>
                  </FormControl>
                  <FormControl>
                    <InputLabel>CCS účel</InputLabel>
                    <Select
                      key={ccsPurpose}
                      value={ccsPurpose}
                      onChange={e =>
                        setCCSPurpose(e.target.value as CCSPurpose)
                      }
                      color="primary"
                    >
                      {Object.keys(CCSPurpose).map(key => (
                        <MenuItem key={key} value={key}>
                          {CCSPurpose[key]}
                        </MenuItem>
                      ))}
                      <MenuItem value={undefined}>Nenastaveno</MenuItem>
                    </Select>
                    <FormHelperText>Vyberte CCS účel</FormHelperText>
                  </FormControl>
                </div>
                <div className={classes.formContainer}>
                  <FormControl>
                    <InputLabel>Pohlaví</InputLabel>
                    <Select
                      key={sex}
                      value={sex}
                      onChange={e => setSex(e.target.value as Sex)}
                    >
                      {Object.keys(Sex).map(key => (
                        <MenuItem key={key} value={key}>
                          {Sex[key]}
                        </MenuItem>
                      ))}
                      <MenuItem value={undefined}>Nenastaveno</MenuItem>
                    </Select>
                    <FormHelperText>Vyberte pohlaví</FormHelperText>
                  </FormControl>
                  <FormControl>
                    <InputLabel>Dovednosti</InputLabel>
                    <Select
                      key={skills}
                      value={skills}
                      onChange={e => setSkills(e.target.value as Skills)}
                      color="primary"
                    >
                      {Object.keys(Skills).map(key => (
                        <MenuItem key={key} value={key}>
                          {Skills[key]}
                        </MenuItem>
                      ))}
                      <MenuItem value={undefined}>Nenastaveno</MenuItem>
                    </Select>
                    <FormHelperText>Vyberte dovednosti</FormHelperText>
                  </FormControl>
                  <FormControl>
                    <InputLabel>SkiSlope</InputLabel>
                    <Select
                      key={skiSlope}
                      value={skiSlope}
                      onChange={e => setSkiSlope(e.target.value as SkiSlope)}
                      color="primary"
                    >
                      {Object.keys(SkiSlope).map(key => (
                        <MenuItem key={key} value={key}>
                          {SkiSlope[key]}
                        </MenuItem>
                      ))}
                      <MenuItem value={undefined}>Nenastaveno</MenuItem>
                    </Select>
                    <FormHelperText>Vyberte SkiSlope</FormHelperText>
                  </FormControl>
                  <FormControl>
                    <InputLabel>SkiArc</InputLabel>
                    <Select
                      key={skiArc}
                      value={skiArc}
                      onChange={e => setSkiArc(e.target.value as SkiArc)}
                      color="primary"
                    >
                      {Object.keys(SkiArc).map(key => (
                        <MenuItem key={key} value={key}>
                          {SkiArc[key]}
                        </MenuItem>
                      ))}
                      <MenuItem value={undefined}>Nenastaveno</MenuItem>
                    </Select>
                    <FormHelperText>Vyberte SkiArc</FormHelperText>
                  </FormControl>
                </div>
              </div>
              <div className={classes.inputSection}>
                <FormControl>
                  {!!primaryCodes.length &&
                    primaryCodes.map((code, index) => (
                      <div className={classes.input}>
                        <TextField
                          key={`${code}-${index}`}
                          value={code}
                          disabled
                          variant="filled"
                          style={{ marginRight: '20px' }}
                        />
                        <Button
                          value={index}
                          onClick={() => removePrimaryCode(index)}
                        >
                          Odebrat
                        </Button>
                      </div>
                    ))}
                  <div className={classes.input}>
                    {!catalogGroupsLoading && catalogGroupsData && (
                      <div>
                        <Select
                          key={currentPrimaryCode}
                          value={currentPrimaryCode}
                          onChange={e =>
                            setCurrentPrimaryCode(e.target.value as string)
                          }
                          color="primary"
                          style={{ width: '100%' }}
                        >
                          {catalogGroupsData.catalogGroups.map(
                            (item: {
                              id: number;
                              name: string;
                              code: string;
                            }) => (
                              <MenuItem key={item.id} value={item.code}>
                                {item.code}
                              </MenuItem>
                            ),
                          )}
                          <MenuItem value={undefined}>Nenastaveno</MenuItem>
                        </Select>
                        <FormHelperText
                          error={!isValid || !primaryCodes.length}
                        >
                          Vložte primární kódy<sup>*</sup>
                        </FormHelperText>
                        {(!primaryCodes.length || !isValid) && (
                          <FormHelperText
                            error={!isValid || !primaryCodes.length}
                          >
                            Primární kódy musí být ve tvaru [písmeno][číslo]
                          </FormHelperText>
                        )}
                      </div>
                    )}
                    <Button
                      disabled={!currentPrimaryCode}
                      onClick={addPrimaryCode}
                    >
                      Přidat
                    </Button>
                  </div>
                </FormControl>
                <div className={classes.formContainer}>
                  <Button
                    style={{ marginTop: '80px', color: 'grey' }}
                    variant="outlined"
                    onClick={() => navigate('/catalog/filters')}
                  >
                    Zpět
                  </Button>
                  <Button
                    style={{ marginTop: '80px' }}
                    type="submit"
                    variant="outlined"
                    onClick={handleSubmit}
                    disabled={!isValid}
                  >
                    Uložit
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default FilterEdit;
