import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { Link, RouteComponentProps } from '@reach/router';
import moment from 'moment';
import * as React from 'react';
import { Query } from 'react-apollo';
import { catalogItemDetail } from '../../../graphql/queries';
import { CatalogItem_catalogItem } from '../../../graphql/types';
import { orderStateSingularCZ } from '../../../lib/localization';
import { parseBarcode } from '../../../lib/utils';

interface IProps extends RouteComponentProps<{ catalogItemId: string }> {
  classes: any;
}

class ItemDetail extends React.Component<IProps> {
  render() {
    const { classes, catalogItemId } = this.props;
    if (!catalogItemId) {
      return null;
    }
    return (
      <Query query={catalogItemDetail} variables={{ id: catalogItemId }}>
        {(data: any) => {
          if (data.loading) {
            return <p>Loading...</p>;
          }
          if (data.error) {
            return <p>Error :</p>;
          }
          const catalogItem: CatalogItem_catalogItem = data.data.catalogItem;
          return (
            <div>
              <Link to="/catalog/items" title="Zpět na seznam">
                <ArrowBack style={{ margin: 10 }} />
              </Link>
              <Paper className={classes.paper}>
                <div className="flexRow">
                  <div className="flexColumn">
                    <div className="flexRow">
                      <div className="property">Kategorie:</div>
                      <div className="propertyValue">
                        {catalogItem.categoryName}
                      </div>
                    </div>
                    <div className="flexRow">
                      <div className="property">Varianta:</div>
                      <div className="propertyValue">{catalogItem.variant}</div>
                    </div>
                    <div className="flexRow">
                      <div className="property">Výrobce:</div>
                      <div className="propertyValue">
                        {catalogItem.producer}
                      </div>
                    </div>
                    <div className="flexRow">
                      <div className="property">Název:</div>
                      <div className="propertyValue">{catalogItem.name}</div>
                    </div>
                    <div className="flexRow">
                      <div className="property">Velikost:</div>
                      <div className="propertyValue">{catalogItem.size}</div>
                    </div>
                    <div className="flexRow">
                      <div className="property">Číselná velikost:</div>
                      <div className="propertyValue">
                        {catalogItem.selectSize}
                      </div>
                    </div>
                    <div className="flexRow">
                      <div className="property">Univerzální položka:</div>
                      <div className="propertyValue">
                        {catalogItem.universal ? 'ANO' : 'NE'}
                      </div>
                    </div>
                    <div className="flexRow">
                      <div className="property">Půjčujeme na sezónu</div>
                      <div className="propertyValue">
                        {catalogItem.seasonDisabled ? 'NE' : 'ANO'}
                      </div>
                    </div>
                  </div>
                  <div className="flexColumn" style={{ marginLeft: 32 }}>
                    <div>Popisek:</div>
                    <div>{catalogItem.description}</div>
                  </div>
                </div>
              </Paper>
              <h2>Skladové položky</h2>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Kód</TableCell>
                    <TableCell>Vytvořeno</TableCell>
                    <TableCell>Aktualizováno</TableCell>
                    <TableCell>Poslední úpravu provedl</TableCell>
                    <TableCell>Smazaná položka</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(catalogItem.stockItems || [])?.map((stockItem, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {parseBarcode(stockItem?.code ?? '')}
                      </TableCell>
                      <TableCell>
                        {moment(stockItem.createdAt).format('DD.MM.YYYY')}
                      </TableCell>
                      <TableCell>
                        {moment(stockItem.updatedAt).format('DD.MM.YYYY')}
                      </TableCell>
                      <TableCell>{stockItem?.author?.user?.username}</TableCell>
                      <TableCell>{stockItem.deleted ? 'Ano' : 'Ne'}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <h2>Aktivní objednávky</h2>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Od</TableCell>
                    <TableCell>Do</TableCell>
                    <TableCell>Zákazník</TableCell>
                    <TableCell>Stav</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(catalogItem.orders || []).map(order => (
                    <TableRow key={order.id}>
                      <TableCell>{order.id}</TableCell>
                      <TableCell>
                        {moment(order.from).format('DD.MM.YYYY')}
                      </TableCell>
                      <TableCell>
                        {moment(order.to).format('DD.MM.YYYY')}
                      </TableCell>
                      <TableCell>
                        {`${order.customer?.name} (${order.customer?.phone})`}
                      </TableCell>
                      <TableCell>{orderStateSingularCZ[order.state]}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          );
        }}
      </Query>
    );
  }
}

const styles = (theme: any) => ({
  paper: {
    display: 'flex',
    padding: '16px',
    flexFlow: 'wrap',
  },
});

const ItemDateilWithStyles = withStyles(styles)(ItemDetail);

const WrapperComponent: React.FunctionComponent<RouteComponentProps> = props => (
  <ItemDateilWithStyles {...props} />
);

export default WrapperComponent;
