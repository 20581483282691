import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  withStyles,
} from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';
import { parseBarcode } from '../../lib/utils';
import { OrderBail_order_sets_items } from '../../graphql/types';

type IProps = {
  items: OrderBail_order_sets_items[];
  isReturnedDateNull: boolean;
  classes: any;
};
class StockOrderTable extends React.Component<IProps> {
  render() {
    const { items, classes, isReturnedDateNull } = this.props;
    let firstItem = false;
    let secondItem = false;
    return (
      <Table>
        <TableBody>
          {items.map((item, index) => {
            if (isReturnedDateNull) {
              firstItem = !item.firstItemReturnedDate;
              secondItem = !item.secondItemReturnedDate;
            } else {
              firstItem = item.firstItemReturnedDate !== null;
              secondItem = item.secondItemReturnedDate !== null;
            }
            if (item == null || item.firstItem == null) {
              return null;
            }
            return (
              <React.Fragment key={index}>
                {firstItem && item.firstItem && (
                  <TableRow>
                    <TableCell className={classes.barcodeCell}>
                      {parseBarcode(item.firstItem.code || '')}
                    </TableCell>
                    <TableCell className={classes.categoryCell}>
                      {item.firstItem.catalogItem &&
                        item.firstItem.catalogItem.categoryName}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {item.firstItem.catalogItem &&
                        item.firstItem.catalogItem.name}
                    </TableCell>
                    {item.firstItemReturnedDate && (
                      <TableCell className={classes.tableCell}>
                        {moment(item.firstItemReturnedDate).format(
                          'DD.MM.YYYY HH:mm',
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                )}
                {secondItem && item.secondItem && (
                  <TableRow>
                    <TableCell className={classes.barcodeCell}>
                      {parseBarcode(item.secondItem.code || '')}
                    </TableCell>
                    <TableCell className={classes.categoryCell}>
                      {item.secondItem.catalogItem &&
                        item.secondItem.catalogItem.categoryName}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {item.secondItem.catalogItem &&
                        item.secondItem.catalogItem.name}
                    </TableCell>
                    {item.secondItemReturnedDate && (
                      <TableCell className={classes.tableCell}>
                        {moment(item.secondItemReturnedDate).format(
                          'DD.MM.YYYY HH:mm',
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                )}
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    );
  }
}

const styles = (theme: any) => ({
  tableCell: {
    padding: '12px 4px 12px 4px',
  },
  barcodeCell: {
    padding: '12px 4px 12px 4px',
    whiteSpaces: 'nowrap',
    width: '95px',
  },
  categoryCell: {
    padding: '12px 4px 12px 4px',
    whiteSpaces: 'nowrap',
    width: '110px',
  },
});

export default withStyles(styles)(StockOrderTable);
