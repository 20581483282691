import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';
import { Query } from 'react-apollo';
import { accountingSummary } from '../../../graphql/queries';
import IntervalPicker from './IntervalPicker';
import { RouteComponentProps } from '@reach/router';

const styles = (theme: any) => ({
  paper: {
    // width: '100%',
    display: 'flex',
    flexFlow: 'wrap',
    justifyContent: 'center',
    padding: '16px',
    alignItems: 'center',
  },
  paperSums: {
    // width: '100%',
    display: 'flex',
    flexFlow: 'wrap',
    justifyContent: 'space-around',
    padding: '16px',
  },
  checkBoxCustom: {
    marginTop: 10,
  },
  datePicker: {
    marginLeft: 10,
    marginRight: 10,
  },
});

interface IPropos extends RouteComponentProps {
  classes: any;
}

interface IState {
  from: string;
  to: string;
}

class Accounting extends React.Component<IPropos, IState> {
  state: IState = {
    from: moment().format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
  };

  handleFromChange = (from: string) => this.setState({ from });
  handleToChange = (to: string) => this.setState({ to });
  handleIntervalChange = (from: string, to: string) =>
    this.setState({ from, to });

  render() {
    const { from, to } = this.state;
    const priceLocale = (price: number) => `${price.toLocaleString('FR')} CZK`;
    const dphLocale = (price: number) =>
      `${price.toLocaleString('FR', { minimumFractionDigits: 3 })} CZK`;
    return (
      <>
        <IntervalPicker
          DateFrom={from}
          DateTo={to}
          handleDateFromChange={this.handleFromChange}
          handleDateToChange={this.handleToChange}
          handleIntervalChange={this.handleIntervalChange}
        />
        <Query
          query={accountingSummary}
          variables={{
            from,
            to: moment(to, 'YYYY-MM-DD')
              .add(1, 'd')
              .format('YYYY-MM-DD'),
          }}
          fetchPolicy={'cache-and-network'}
        >
          {(data: any) => {
            if (data.loading) {
              return <p>Loading...</p>;
            }
            if (data.error) {
              return <p>'Error'</p>;
            }
            return (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell size="small" align="right">
                      Číslo zakázky
                    </TableCell>
                    <TableCell size="small" align="right">
                      Jméno zákazníka
                    </TableCell>
                    <TableCell size="small" align="right">
                      Číslo zákazníka
                    </TableCell>
                    <TableCell size="small" align="right">
                      Půjčovné hotově
                    </TableCell>
                    <TableCell size="small" align="right">
                      Půjčovné kartou
                    </TableCell>
                    <TableCell size="small" align="right">
                      Půjčovné faktura
                    </TableCell>
                    <TableCell size="small" align="right">
                      Půjčovné online
                    </TableCell>
                    <TableCell size="small" align="right">
                      Půjčovné DPH
                    </TableCell>
                    <TableCell size="small" align="right">
                      Kauce hotově
                    </TableCell>
                    <TableCell size="small" align="right">
                      Kauce kartou
                    </TableCell>
                    <TableCell size="small" align="right">
                      Kauce faktura
                    </TableCell>
                    <TableCell size="small" align="right">
                      Vrácená kauce
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.data.accountingSummary.ordersSummary.map(
                    (order: any) => (
                      <TableRow key={order.orderId}>
                        <TableCell size="small" align="right">
                          {order.orderId}
                        </TableCell>
                        <TableCell size="small" align="right">
                          {order.customerName}
                        </TableCell>
                        <TableCell size="small" align="right">
                          {order.customerPhone}
                        </TableCell>
                        <TableCell size="small" align="right">
                          {priceLocale(order.priceCash)}
                        </TableCell>
                        <TableCell size="small" align="right">
                          {priceLocale(order.priceCard)}
                        </TableCell>
                        <TableCell size="small" align="right">
                          {priceLocale(order.priceInvoice)}
                        </TableCell>
                        <TableCell size="small" align="right">
                          {priceLocale(order.priceOnline)}
                        </TableCell>
                        <TableCell size="small" align="right">
                          {dphLocale(order.priceDPH)}
                        </TableCell>
                        <TableCell size="small" align="right">
                          {priceLocale(order.bailCash)}
                        </TableCell>
                        <TableCell size="small" align="right">
                          {priceLocale(order.bailCard)}
                        </TableCell>
                        <TableCell size="small" align="right">
                          {priceLocale(order.bailInvoice)}
                        </TableCell>
                        <TableCell size="small" align="right">
                          {priceLocale(order.bailReturnedSum)}
                        </TableCell>
                      </TableRow>
                    ),
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell size="small">{''}</TableCell>
                    <TableCell size="small">{''}</TableCell>
                    <TableCell size="small" align="right">
                      Celkem:
                    </TableCell>
                    <TableCell size="small" align="right">
                      {priceLocale(data.data.accountingSummary.sums.priceCash)}
                    </TableCell>
                    <TableCell size="small" align="right">
                      {priceLocale(data.data.accountingSummary.sums.priceCard)}
                    </TableCell>
                    <TableCell size="small" align="right">
                      {priceLocale(
                        data.data.accountingSummary.sums.priceInvoice,
                      )}
                    </TableCell>
                    <TableCell size="small" align="right">
                      {priceLocale(
                        data.data.accountingSummary.sums.priceOnline,
                      )}
                    </TableCell>
                    <TableCell size="small" align="right">
                      {dphLocale(data.data.accountingSummary.sums.priceDPH)}
                    </TableCell>
                    <TableCell size="small" align="right">
                      {priceLocale(data.data.accountingSummary.sums.bailCash)}
                    </TableCell>
                    <TableCell size="small" align="right">
                      {priceLocale(data.data.accountingSummary.sums.bailCard)}
                    </TableCell>
                    <TableCell size="small" align="right">
                      {priceLocale(
                        data.data.accountingSummary.sums.bailInvoice,
                      )}
                    </TableCell>
                    <TableCell size="small" align="right">
                      {priceLocale(
                        data.data.accountingSummary.sums.bailReturnedSum,
                      )}
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            );
          }}
        </Query>
      </>
    );
  }
}

export default withStyles(styles)(Accounting);
