import { saveAs } from 'file-saver';
import { OrderState } from '../graphql/types';
import auth from './auth';
import { OrderStateFilters, ReservationStateFilters } from './types';
import moment from 'moment';
import fetch from 'cross-fetch';

export const downloadPDF = async (
  invoiceID: number,
  openAndPrint: boolean,
  url: string = 'pdfFile',
) => {
  try {
    const response = await fetch(`/api/${url}/${invoiceID}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${auth.getAccessToken()}`,
        'Content-Type': 'application/pdf',
      },
    });
    try {
      const pdf = await response.blob();
      if (openAndPrint) {
        const fileURL = window.URL.createObjectURL(pdf);
        const newWindow = await window.open(fileURL);
        if (newWindow) {
          newWindow.print();
        }
      } else {
        saveAs(pdf, `CZSKI_Objednavka_${invoiceID}.pdf`);
      }
    } catch (e) {
      console.log(e);
      window.alert('Nezdařilo se uložit pdf');
    }
  } catch (e) {
    window.alert('Nezdařilo se stáhnout pdf se serveru');
  }
};

export const parseBarcode = (barCode: string) => {
  let result = barCode;
  const barcodeWithoutspaces = barCode.replace(/ /g, '');
  if (barCode.indexOf('<<') === -1) {
    if (barcodeWithoutspaces.length > 3) {
      result = `${barcodeWithoutspaces.slice(0, 3)}`;

      if (barcodeWithoutspaces.length > 6) {
        result += ` ${barcodeWithoutspaces.slice(3, 6)}`;

        if (barcodeWithoutspaces.length > 9) {
          result += ` ${barcodeWithoutspaces.slice(6, 9)}`;
          if (barcodeWithoutspaces.length > 13) {
            result += ` ${barcodeWithoutspaces.slice(9)}`;
          } else {
            result += ` ${barcodeWithoutspaces.slice(9)}`;
          }
        } else {
          result += ` ${barcodeWithoutspaces.slice(6)}`;
        }
      } else {
        result += ` ${barcodeWithoutspaces.slice(3)}`;
      }
    }
  }
  return result;
};

export const stylePhoneNumber = (phoneNumber: string) => {
  const index = phoneNumber.indexOf('+');
  const hasPlus = index >= 0 ? true : false;
  let newNumber = phoneNumber;
  if (hasPlus) {
    newNumber = phoneNumber.slice(1, phoneNumber.length);
  }
  newNumber = parseBarcode(newNumber);

  return hasPlus ? `+${newNumber}` : newNumber;
};

export function getNthOfpathname(pathName: string, nth: number) {
  const splited = pathName.split('/');
  return splited[nth + 1];
}

export const getStates = (
  orderFilters: OrderStateFilters,
  reservationFilters: ReservationStateFilters,
  activeEntity: 'ORDER' | 'RESERVATION',
) => {
  if (!orderFilters || !reservationFilters || !activeEntity) {
    return [];
  }
  const result: string[] = [];
  const isOrder = activeEntity === 'ORDER';
  const filters = isOrder ? orderFilters : reservationFilters;
  Object.keys(filters).forEach(state => {
    if (filters[state] && state !== 'unreturned') {
      result.push(
        isOrder ? state.toUpperCase() : `RESERVATION_${state.toUpperCase()}`,
      );
    }
  });
  if (result.length === 0) {
    Object.keys(filters).forEach(state => {
      if (state !== 'unreturned') {
        result.push(
          isOrder ? state.toUpperCase() : `RESERVATION_${state.toUpperCase()}`,
        );
      }
    });
  }
  if (
    Object.keys(filters).filter(f => filters[f]).length === 0 &&
    activeEntity === 'ORDER'
  ) {
    result.push('CHANGE');
  }
  return result;
};

export function getIsOrder(state: OrderState) {
  return ![
    OrderState.RESERVATION_NEW,
    OrderState.RESERVATION_PAID,
    OrderState.RESERVATION_PREPARED,
  ].includes(state);
}

export const formatTime = (date: string) =>
  moment(date).format('HH:mm - DD/MM/YYYY');

export const getGraphqlErrorMessage = (error: any) =>
  error?.message.split(':')[1];

export function notEmpty<TValue>(
  value: TValue | null | undefined,
): value is TValue {
  return value !== null && value !== undefined;
}
