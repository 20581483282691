/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Button,
  Fab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PhotoIcon from '@material-ui/icons/Photo';
import { Link, RouteComponentProps } from '@reach/router';
import * as React from 'react';
import { Mutation, Query, withApollo } from 'react-apollo';
import Pagination from 'react-js-pagination';
import UploadImageDialog from '../../../components/UploadImageDialog';
import { deleteCatalogItem, loadCatalogItems } from '../../../graphql/queries';
import auth from '../../../lib/auth';
import { PagesNames } from '../../../lib/enums';
import pagesState from '../../../lib/pageState';
import { CatalogItemListType } from '../../../lib/types';
import './catalog.css';
import ItemCell from './ItemCell';

type IProps = {
  client: any;
  classes?: any;
};

interface IState {
  activePage: number;
  order: string;
  direction: string;
  search: string;
  barCode: string;
  itemsCountPerPage: number;
  uploadImageCatalog: CatalogItemListType | null;
}

class Catalog extends React.Component<IProps, IState> {
  state: IState = {
    activePage: 0,
    order: 'asc',
    direction: '',
    search: '',
    barCode: '',
    itemsCountPerPage: 12,
    uploadImageCatalog: null,
  };

  componentWillMount() {
    const state: any = pagesState.getPageState(PagesNames.Catalog);
    if (state !== null) {
      this.setState({
        activePage: state.activePage,
        order: state.order,
        direction: state.direction,
        search: state.search,
        barCode: state.barCode,
        itemsCountPerPage: state.itemsCountPerPage,
      });
    }
  }
  componentWillUnmount() {
    pagesState.setPageState(PagesNames.Catalog, this.state);
  }

  handleOpenImageUpload = (catalog: CatalogItemListType) => () =>
    this.setState({ uploadImageCatalog: catalog });
  handleCloseImageUpload = () => this.setState({ uploadImageCatalog: null });

  handlePageChange = (pageNumber: number) => {
    this.setState({ activePage: pageNumber });
  };

  deleteCatalogItem = (catalogItemId: number) => {
    const { mutate } = this.props.client;
    if (mutate) {
      mutate({
        variables: {
          catalogItemId,
        },
        update: (store: any) => {
          const newData = store.readQuery({
            query: loadCatalogItems,
            variables: {
              offset:
                this.state.itemsCountPerPage * (this.state.activePage - 1),
              limit: this.state.itemsCountPerPage,
              sort: this.state.order,
              order: this.state.direction,
              search: this.state.search,
            },
          });
          newData.catalogItems.items = newData.catalogItems.items.filter(
            (item: any) => item.id !== catalogItemId,
          );
          store.writeQuery({
            query: loadCatalogItems,
            data: newData,
          });
        },
      });
    }
  };

  sortBy = (name: string) => () => {
    this.setState(oldState => {
      return {
        order: name,
        direction:
          oldState.order === name
            ? oldState.direction === 'asc'
              ? 'desc'
              : 'asc'
            : 'asc',
      };
    });
  };

  debounce(func: Function, delay: number) {
    let timerId: ReturnType<typeof setTimeout>;

    return (event: { persist: () => void }, ...args: any) => {
      const context = this;
      event.persist();

      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func.call(context, event, ...args);
      }, delay);
    };
  }

  search = this.debounce((event: { target: { value: string } }) => {
    this.setState({ search: event.target.value, activePage: 1 });
  }, 500);

  barCodeChange = (event: { target: { value: string } }) => {
    this.setState({ barCode: event.target.value, activePage: 1 });
  };

  render() {
    const { classes } = this.props;
    const isAdmin = auth.isAdmin();
    return (
      <div className="Catalog">
        <TextField
          className={classes.textField}
          label="Hledání"
          type="string"
          defaultValue={this.state.search}
          onChange={this.search}
        />
        <TextField
          className={classes.textField}
          label="Hledej dle kódu"
          type="number"
          defaultValue={this.state.barCode}
          onChange={this.barCodeChange}
        />
        <Query
          query={loadCatalogItems}
          fetchPolicy={'network-only'}
          variables={{
            offset: this.state.itemsCountPerPage * (this.state.activePage - 1),
            limit: this.state.itemsCountPerPage,
            sort: this.state.order,
            order: this.state.direction,
            search: this.state.search,
            barCode: this.state.barCode,
          }}
        >
          {(data: any) => {
            if (data.loading) {
              return <p>Loading...</p>;
            }
            if (data.error) {
              return <p>Error :</p>;
            }

            return (
              <div>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ textAlign: 'start' }}>
                        <a
                          onClick={this.sortBy('name')}
                          className={
                            this.state.order === 'name' ? 'ordered' : ''
                          }
                        >
                          Název
                        </a>
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        Katalogová skupina
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        <a
                          onClick={this.sortBy('producer')}
                          className={
                            this.state.order === 'producer' ? 'ordered' : ''
                          }
                        >
                          Výrobce
                        </a>
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        <a
                          onClick={this.sortBy('category')}
                          className={
                            this.state.order === 'category' ? 'ordered' : ''
                          }
                        >
                          Kategorie
                        </a>
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        <a
                          onClick={this.sortBy('purpose')}
                          className={
                            this.state.order === 'purpose' ? 'ordered' : ''
                          }
                        >
                          Určení
                        </a>
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        <a
                          onClick={this.sortBy('variant')}
                          className={
                            this.state.order === 'variant' ? 'ordered' : ''
                          }
                        >
                          Varianta
                        </a>
                      </TableCell>

                      <TableCell style={{ textAlign: 'center' }}>
                        <a
                          onClick={this.sortBy('size')}
                          className={
                            this.state.order === 'size' ? 'ordered' : ''
                          }
                        >
                          Velikost
                        </a>
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        <a
                          onClick={this.sortBy('selectSize')}
                          className={
                            this.state.order === 'selectSize' ? 'ordered' : ''
                          }
                        >
                          Číselná velikost
                        </a>
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        Počet položek / půjčeno
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        Editace
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        Úprava obrázku
                      </TableCell>
                      {isAdmin && (
                        <TableCell style={{ textAlign: 'center' }}>
                          Smazání
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.data.catalogItems.items.map(
                      (catalogItem: CatalogItemListType) => (
                        <TableRow key={catalogItem.id}>
                          <ItemCell
                            textAlign="start"
                            catalogItemId={catalogItem.id}
                          >{`${catalogItem.name}`}</ItemCell>
                          <ItemCell
                            textAlign="center"
                            catalogItemId={catalogItem.id}
                          >{`${(catalogItem.catalogGroup &&
                            catalogItem.catalogGroup.name) ||
                            ''} ${
                            catalogItem.catalogGroup?.code
                              ? `(${catalogItem.catalogGroup &&
                                  catalogItem.catalogGroup?.code})`
                              : ''
                          }`}</ItemCell>
                          <ItemCell
                            textAlign="center"
                            catalogItemId={catalogItem.id}
                          >{`${catalogItem.producer}`}</ItemCell>
                          <ItemCell
                            textAlign="center"
                            catalogItemId={catalogItem.id}
                          >{`${catalogItem.category.name}`}</ItemCell>
                          <ItemCell
                            textAlign="center"
                            catalogItemId={catalogItem.id}
                          >{`${catalogItem.purpose}`}</ItemCell>
                          <ItemCell
                            textAlign="center"
                            catalogItemId={catalogItem.id}
                          >{`${catalogItem.variant}`}</ItemCell>
                          <ItemCell
                            textAlign="center"
                            catalogItemId={catalogItem.id}
                          >{`${catalogItem.size}`}</ItemCell>
                          <ItemCell
                            textAlign="center"
                            catalogItemId={catalogItem.id}
                          >{`${catalogItem.selectSize}`}</ItemCell>
                          <ItemCell
                            textAlign="center"
                            catalogItemId={catalogItem.id}
                          >
                            {`${catalogItem.stockItemsCount}`} /{' '}
                            {catalogItem.borrowed}
                          </ItemCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            <Link to={`item-edit/${catalogItem.id}`}>
                              <Button>
                                <EditIcon />
                              </Button>
                            </Link>
                          </TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            <Button
                              onClick={this.handleOpenImageUpload(catalogItem)}
                            >
                              <PhotoIcon />
                            </Button>
                          </TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            {isAdmin && (
                              <Mutation
                                mutation={deleteCatalogItem}
                                onCompleted={data.refetch}
                              >
                                {(deleteItem: any) => (
                                  <Button>
                                    <DeleteIcon
                                      onClick={() => {
                                        if (
                                          // eslint-disable-next-line no-restricted-globals
                                          confirm(
                                            `Opravdu chcete smazat položku? \n \nPři smazání položky bude smazáno všech jejich ${catalogItem.stockItemsCount} kódů`,
                                          )
                                        ) {
                                          deleteItem({
                                            variables: {
                                              catalogItemId: catalogItem.id,
                                            },
                                          });
                                        }
                                      }}
                                    />
                                  </Button>
                                )}
                              </Mutation>
                            )}
                          </TableCell>
                        </TableRow>
                      ),
                    )}
                  </TableBody>
                </Table>
                {data.data.catalogItems.totalCount >
                this.state.itemsCountPerPage ? (
                  <div style={{ float: 'right' }}>
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={this.state.itemsCountPerPage}
                      totalItemsCount={data.data.catalogItems.totalCount}
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange}
                    />
                  </div>
                ) : (
                  <div />
                )}
                <div className="addButton" style={{ marginTop: '80px' }}>
                  <Link to={'item-edit/NaN'}>
                    <Tooltip title="Přidat">
                      <Fab color="primary">
                        <AddIcon />
                      </Fab>
                    </Tooltip>
                  </Link>
                </div>
                {this.state.uploadImageCatalog && (
                  <UploadImageDialog
                    onClose={this.handleCloseImageUpload}
                    onUpdate={() => data.refetch()}
                    id={this.state.uploadImageCatalog.id}
                    entity="catalog"
                    open={true}
                    label={this.state.uploadImageCatalog.name}
                    pathToCurrentImage={this.state.uploadImageCatalog.imagePath}
                  />
                )}
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

const styles = (theme: any) => ({
  textField: {
    margin: '8px',
  },
});

const ItemsWithStyles = withApollo(withStyles(styles)(Catalog));

const ComponentWrapper: React.FunctionComponent<RouteComponentProps> = () => (
  <ItemsWithStyles />
);

export default ComponentWrapper;
