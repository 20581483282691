import React, { ReactElement } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab, { TabTypeMap } from '@material-ui/core/Tab';
import { navigate, Link } from '@reach/router';
import auth from '../lib/auth';
import Button from '@material-ui/core/Button';
import { getNthOfpathname } from '../lib/utils';
import { ExtendButtonBase } from '@material-ui/core/ButtonBase';
import { useApolloClient } from '@apollo/react-hooks';

type Props = {
  children: ReactElement;
  pathName: string;
};

const tabs = [
  {
    label: 'Objednávky a rezervace',
    to: '/orders-and-reservations/',
    protected: false,
  },
  {
    label: 'Katalog',
    to: '/catalog/items/',
    protected: false,
  },
  {
    label: 'Ceny',
    to: '/price-list/items/',
    protected: true,
  },
  {
    label: 'Kasa',
    to: '/cash-register/',
    protected: false,
  },
  {
    label: 'Transakce',
    to: '/payments/',
    protected: true,
  },
  {
    label: ' Ostatní',
    to: '/others/accounting/',
    protected: true,
  },
  {
    label: 'Statistiky',
    to: '/statistics/earnings',
    protected: true,
  },
  {
    label: ' Nastavení',
    to: '/settings/',
    protected: true,
  },
];

const PageHeader = ({ children, pathName }: Props) => {
  const client = useApolloClient();
  const classes = useStyles();

  const ProtectedTab: ExtendButtonBase<TabTypeMap> = (props: any) => {
    const isAdmin = auth.isAdmin();
    return isAdmin ? <Tab {...props} /> : <></>;
  };

  const onLogoutClick = async () => {
    await auth.logout();
    client.resetStore();
    navigate('/login');
  };

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <div className={classes.barContentWrapper}>
          <Tabs
            variant="scrollable"
            value={pathName}
            className={classes.toolbar}
          >
            {tabs.map(tab => {
              return tab.protected ? (
                <ProtectedTab
                  label={tab.label}
                  value={getNthOfpathname(tab.to, 0)}
                  component={Link}
                  to={tab.to}
                  key={tab.to}
                />
              ) : (
                <Tab
                  component={Link}
                  to={tab.to}
                  label={tab.label}
                  value={getNthOfpathname(tab.to, 0)}
                  key={tab.to}
                />
              );
            })}
          </Tabs>
          <div className={classes.barRigthWrapper}>
            <span className={classes.name}>{auth.getUserName()}</span>
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              onClick={onLogoutClick}
            >
              Odhlásit
            </Button>
          </div>
        </div>
      </AppBar>
      <div className={classes.childrenWrapper}>{children}</div>
    </div>
  );
};

export default PageHeader;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  toolbar: {
    marginLeft: 16,
    maxWidth: 'calc(100% - 134px)',
  },
  barContentWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  barRigthWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    marginRight: 16,
  },
  name: {
    marginRight: 8,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  childrenWrapper: {
    padding: 24,
    marginTop: 48,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
}));
