import { Checkbox, FormControlLabel } from '@material-ui/core';
import * as React from 'react';
import { labelPlacement } from '../lib/enums';

interface IProps {
  checked: boolean;
  onChange: () => void;
  name: string;
  className?: any;
  labelPlacement: labelPlacement;
  color?: 'primary' | 'secondary';
  disabled?: boolean;
}

class CheckBoxCustom extends React.Component<IProps> {
  render() {
    return (
      <FormControlLabel
        className={this.props.className}
        labelPlacement={this.props.labelPlacement}
        label={this.props.name}
        control={
          <Checkbox
            checked={this.props.checked}
            onChange={this.props.onChange}
            value={this.props.name}
            color={this.props.color}
            disabled={this.props.disabled}
          />
        }
      />
    );
  }
}

export default CheckBoxCustom;
