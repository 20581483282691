import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import {
  getCatalogItemDescription,
  setCatalogItemsDescription,
} from '../graphql/queries';
import {
  GetCatalogItemDescription,
  GetCatalogItemDescriptionVariables,
  SetItemDescription,
  SetItemDescriptionVariables,
} from '../graphql/types';
import GraphQLErrorLoadingBoundary from './GraphQLErrorLoadingBoundary';

interface IWrapperProps {
  open: boolean;
  onClose: () => void;
  name: string;
  producer: string;
  sizes: string[];
  itemIds: string[];
}

const AddDescriptionWrapper: React.FC<IWrapperProps> = props => {
  return (
    <GraphQLErrorLoadingBoundary<
      GetCatalogItemDescription,
      GetCatalogItemDescriptionVariables
    >
      query={getCatalogItemDescription}
      options={{ variables: { id: props.itemIds[0] }, fetchPolicy: 'no-cache' }}
    >
      {({ data }) => (
        <AddDescription
          {...props}
          initValue={(data.catalogItem && data.catalogItem.description) || ''}
        />
      )}
    </GraphQLErrorLoadingBoundary>
  );
};

interface IProps {
  initValue: string;
}

const maxLenght = 350;
const AddDescription: React.FC<IProps & IWrapperProps> = props => {
  const [description, setDescription] = useState(props.initValue);
  const [setItemDescription] = useMutation<
    SetItemDescription,
    SetItemDescriptionVariables
  >(setCatalogItemsDescription);

  const handleDescriptionChange = (e: any) => {
    const newDescription = e.target.value;
    if (newDescription.length <= maxLenght) {
      setDescription(e.target.value);
    }
  };

  const handeOnSaveClick = () => {
    setItemDescription({
      variables: { catalogItemIds: props.itemIds, description },
    });
    props.onClose();
  };

  return (
    <Dialog open={true} aria-labelledby="fadd-description-dialog">
      <DialogTitle style={{ textAlign: 'center' }}>
        Přidáváte popis pro {props.producer} {props.name}{' '}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Pro velikosti {props.sizes.join(', ')}
        </DialogContentText>
        <DialogContentText style={{ color: 'black' }}>
          Délka popisku je limitována na {maxLenght} znaků včetně mezer.{' '}
          {description.length} / {maxLenght}
        </DialogContentText>
        <TextField
          multiline
          rows={5}
          style={{ width: '100%' }}
          value={description}
          onChange={handleDescriptionChange}
        />
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between' }}>
        <div className="invisible">{}</div>
        <Button variant="contained" onClick={handeOnSaveClick} color="primary">
          Uložit
        </Button>
        <Button onClick={props.onClose} color="primary">
          Zavřít
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddDescriptionWrapper;
