import {
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  withStyles,
  Fab,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import * as React from 'react';
import { Query } from 'react-apollo';
import auth from '../../../lib/auth';
import { loadEmployees } from '../../../graphql/queries';
import { EmployeeType } from '../../../lib/types';

import './Employees.css';
import { RouteComponentProps } from '@reach/router';

interface IState {
  openedEmployee: number;
  name: string;
  username: string;
  password: string;
  confrimPassword: string;
  code: string;
  confrimCode: string;
  role: string;
  userId: number;
}

interface IProps extends RouteComponentProps {
  classes: any;
}

const styles = (theme: any) => ({
  textField: {
    width: 250,
    marginLeft: 30,
    marginRight: 30,
  },
  formControl: {
    minWidth: 160,
    marginTop: 32,
    marginRight: 30,
    width: 80,
  },
  button: {
    width: 150,
    marginLeft: 60,
    marginRight: 60,
  },
});

class EditEmployees extends React.Component<IProps, IState> {
  state: IState = {
    openedEmployee: -2,
    name: '',
    username: '',
    password: '',
    confrimPassword: '',
    code: '',
    confrimCode: '',
    role: 'EMPLOYEE',
    userId: -1,
  };

  handleDialogClose = () => this.setState({ openedEmployee: -2 });

  handleDialogOpen = (
    employeeId: number,
    name: string,
    username: string,
    userId: number,
    role: string,
  ) =>
    this.setState({
      name,
      username,
      userId,
      role,
      openedEmployee: employeeId,
      password: '',
      confrimPassword: '',
      code: '',
      confrimCode: '',
    });

  handleNameChange = (event: any) =>
    this.setState({ name: event.target.value });
  handleUsernameChange = (event: any) =>
    this.setState({ username: event.target.value });
  handlePasswordChange = (event: any) =>
    this.setState({ password: event.target.value });
  handleConfirmPasswordChange = (event: any) =>
    this.setState({ confrimPassword: event.target.value });
  handleCodeChange = (event: any) =>
    this.setState({ code: event.target.value });
  handleConfirmCode = (event: any) =>
    this.setState({ confrimCode: event.target.value });
  handleRoleChange = (event: any) =>
    this.setState({ role: event.target.value });

  saveEmployee = async (refetch: any) => {
    const { name, username, password, code, role } = this.state;
    let checkResult = false;

    if (this.checkPassword()) {
      if (this.checkCode()) {
        if (name.length !== 0) {
          if (username.length !== 0) {
            checkResult = true;
          } else {
            window.alert('Uživastelské jméno nemůže být prázdné');
          }
        } else {
          window.alert('Jméno pro terminál nemůže být prázdné');
        }
      }
    }

    if (checkResult) {
      const result = await auth.register(
        name,
        username,
        password,
        parseInt(code, 10),
        role,
      );
      if (result.ok) {
        this.handleDialogClose();
        refetch();
      }
    }
  };

  checkPassword = () => {
    const { password, confrimPassword } = this.state;
    let result = false;
    if (password === confrimPassword) {
      if (password.length > 7) {
        result = true;
      } else {
        window.alert('Heslo musí mít minimálně 8 znaků');
      }
    } else {
      window.alert('Zadaná hesla se neshodují');
    }
    return result;
  };

  checkCode = () => {
    const { code, confrimCode } = this.state;
    let result = false;
    if (code === confrimCode) {
      if (code.length === 4) {
        if (code.match(/^[0-9]+$/)) {
          result = true;
        } else {
          window.alert('Kód může pouze obsahovat číslice 0 až 9');
        }
      } else {
        window.alert('Kód pro terminál musí být čtyř místný');
      }
    } else {
      window.alert('Zadané kódy pro terminál se neshodují');
    }
    return result;
  };

  changeCode = async (refetch: any) => {
    if (this.checkCode()) {
      const response = await auth.changeCode(
        parseInt(this.state.code, 10),
        this.state.openedEmployee,
      );
      if (response.ok) {
        this.handleDialogClose();
        refetch();
      }
    }
  };

  changePassword = async (refetch: any) => {
    if (this.checkPassword()) {
      const response = await auth.changePassword(
        this.state.password,
        this.state.userId,
      );
      if (response.ok) {
        this.handleDialogClose();
        refetch();
      }
    }
  };

  changeRole = async (refetch: any) => {
    const response = await auth.changeRole(
      this.state.role,
      this.state.openedEmployee,
    );
    if (response.ok) {
      this.handleDialogClose();
      refetch();
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Query query={loadEmployees}>
        {(data: any) => {
          if (data.loading) {
            return <p>Loading...</p>;
          }
          if (data.error) {
            return <p>Error :</p>;
          }
          const employees: EmployeeType[] = data.data.employees.employees;
          return (
            <div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Jméno terminál</TableCell>
                    <TableCell>Přihlašovací jméno</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell>Akce</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {employees.map(employee => (
                    <TableRow key={employee.id}>
                      <TableCell>{employee.id}</TableCell>
                      <TableCell>{employee.user.name}</TableCell>
                      <TableCell>{employee.user.username}</TableCell>
                      <TableCell>{employee.role}</TableCell>
                      <TableCell>
                        <Button
                          onClick={() =>
                            this.handleDialogOpen(
                              employee.id,
                              employee.user.name,
                              employee.user.username,
                              employee.user.id,
                              employee.role,
                            )
                          }
                        >
                          <EditIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <div className="addButton" style={{ marginTop: '80px' }}>
                <Tooltip title="Přidat">
                  <Fab
                    color="primary"
                    onClick={() =>
                      this.handleDialogOpen(-1, '', '', -1, 'EMPLOYEE')
                    }
                  >
                    <AddIcon />
                  </Fab>
                </Tooltip>
              </div>
              <Dialog
                onClose={this.handleDialogClose}
                aria-labelledby="simple-dialog-title"
                fullWidth={false}
                maxWidth={false}
                open={this.state.openedEmployee !== -2}
              >
                <DialogTitle>
                  {this.state.openedEmployee === -1
                    ? 'Přidání nového zaměstnance'
                    : 'Editace zaměstnance'}
                </DialogTitle>
                <div className="diablogWrapper">
                  <div className="dialogRowEmp">
                    <TextField
                      required={true}
                      label="Jméno terminál"
                      value={this.state.name}
                      className={classes.textField}
                      onChange={this.handleNameChange}
                      disabled={this.state.openedEmployee > -1}
                      margin="normal"
                    />
                    <TextField
                      required={true}
                      label="Přihlašovací jméno"
                      disabled={this.state.openedEmployee > -1}
                      value={this.state.username}
                      className={classes.textField}
                      onChange={this.handleUsernameChange}
                      margin="normal"
                    />
                  </div>
                  <div className="dialogRowEmp">
                    <TextField
                      required={true}
                      label="Kód terminál"
                      type="password"
                      value={this.state.code}
                      className={classes.textField}
                      onChange={this.handleCodeChange}
                      margin="normal"
                    />
                    <TextField
                      required={true}
                      label="Heslo"
                      type="password"
                      value={this.state.password}
                      className={classes.textField}
                      onChange={this.handlePasswordChange}
                      margin="normal"
                    />
                    <FormControl className={classes.formControl}>
                      <Select
                        value={this.state.role}
                        onChange={this.handleRoleChange}
                        name="Role"
                      >
                        <MenuItem value={'EMPLOYEE'}>Employee</MenuItem>
                        <MenuItem value={'ADMIN'}>Admin</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="dialogRowEmp">
                    <TextField
                      required={true}
                      label="Zadejte kód terminálu znovu"
                      type="password"
                      value={this.state.confrimCode}
                      className={classes.textField}
                      onChange={this.handleConfirmCode}
                      margin="normal"
                    />
                    <TextField
                      required={true}
                      label="Zadejte heslo znovu"
                      type="password"
                      className={classes.textField}
                      value={this.state.confrimPassword}
                      onChange={this.handleConfirmPasswordChange}
                      margin="normal"
                    />
                  </div>
                  {this.state.openedEmployee === -1 ? (
                    <div className="saveButton">
                      <Button
                        className={classes.button}
                        onClick={() => this.saveEmployee(data.refetch)}
                        variant="contained"
                      >
                        Uložit
                      </Button>
                    </div>
                  ) : (
                    <div className="dialogRowEmp margin">
                      <Button
                        className={classes.button}
                        onClick={() => this.changeCode(data.refetch)}
                        variant="contained"
                      >
                        Změnit kód
                      </Button>
                      <Button
                        className={classes.button}
                        onClick={() => this.changePassword(data.refetch)}
                        variant="contained"
                      >
                        Změnit heslo
                      </Button>
                      <Button
                        className={classes.button}
                        onClick={() => this.changeRole(data.refetch)}
                        variant="contained"
                      >
                        Změnit roli
                      </Button>
                    </div>
                  )}
                </div>
              </Dialog>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default withStyles(styles)(EditEmployees);
