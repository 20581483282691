import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import { RouteComponentProps, useParams } from '@reach/router';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import Barcode from 'react-barcode';
import { getOrderDetail, setOrderNoteMutation } from '../../graphql/queries';
import {
  GetOrderDetail,
  GetOrderDetailVariables,
  OrderState,
  SetOrderNote,
  SetOrderNoteVariables,
} from '../../graphql/types';
import { paymentFormCZ, paymentTypeCZ } from '../../lib/localization';

const OrderDetail: React.FunctionComponent<RouteComponentProps> = () => {
  const params = useParams();
  const classes = useStyles();
  const [editing, setEditing] = useState(false);
  const [note, setNote] = useState('');

  const orderId = Number(params.orderId);
  const invalidOrderId = isNaN(orderId);
  const [setOrderNote] = useMutation<SetOrderNote, SetOrderNoteVariables>(
    setOrderNoteMutation,
  );
  const data = useQuery<GetOrderDetail, GetOrderDetailVariables>(
    getOrderDetail,
    {
      variables: {
        id: orderId,
      },
      skip: invalidOrderId,
    },
  );

  const order = data.data?.order;

  useEffect(() => {
    setNote(order?.note || '');
  }, [order, setNote]);

  if (invalidOrderId) {
    return <div>Not found</div>;
  }

  if (data.loading) {
    return <p>Loading...</p>;
  }
  if (data.error) {
    return <p>Error :</p>;
  }
  if (!order) {
    return <p>Failed to load data :</p>;
  }

  const handleSave = () => {
    setEditing(false);
    if (order.id) {
      setOrderNote({
        variables: {
          orderId: order.id,
          note,
        },
      });
    }
  };

  const price = order.payments
    ?.map(payment => {
      if (payment?.type === 'PRICE') {
        return payment.amount;
      }
      return 0;
    })
    .reduce((acc, curr) => acc + curr, 0);

  const bail = order.payments
    ?.map(payment => {
      if (payment?.type === 'BAIL') {
        return payment.amount;
      }
      return 0;
    })
    .reduce((acc, curr) => acc + curr, 0);

  const isReservation = [
    OrderState.RESERVATION_NEW,
    OrderState.RESERVATION_PAID,
    OrderState.RESERVATION_PREPARED,
    OrderState.RESERVATION_CANCELED,
    // @ts-ignore
  ].includes(order.state);

  return (
    <Box className={classes.container}>
      <Paper className={classes.paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Číslo zakázky</TableCell>
              <TableCell>{order.id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Nájemce</TableCell>
              <TableCell>{order.customer?.name ?? 'Nevyplněno'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Telefon</TableCell>
              <TableCell>{order.customer?.phone ?? 'Nevyplněno'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>E-mail</TableCell>
              <TableCell>{order.customer?.email ?? 'Nevyplněno'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Poznámka</TableCell>
              <TableCell>
                {editing ? (
                  <>
                    <TextField
                      value={note}
                      onChange={event => {
                        setNote(event.target.value || '');
                      }}
                    ></TextField>
                    <IconButton size="small" onClick={handleSave}>
                      <SaveIcon />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <Box mr={2} component="span">
                      {order.note ?? 'Nevyplněno'}
                    </Box>
                    <IconButton size="small" onClick={() => setEditing(true)}>
                      <EditIcon />
                    </IconButton>
                  </>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
      <Paper className={classes.paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell align="center" colSpan={3}>
                Termín nájmu od <b>{moment(order.from).format('DD.MM.YYYY')}</b>{' '}
                do <b>{moment(order.to).format('DD.MM.YYYY')}</b>
              </TableCell>
            </TableRow>
            {!!order.sets?.length && (
              <TableRow>
                <TableCell align="center">Předmět nájmu</TableCell>
                <TableCell align="center">Cena</TableCell>
                <TableCell align="center">Kauce</TableCell>
              </TableRow>
            )}
            {order.sets?.map((set, setIdx) => (
              <Fragment key={set?.id || setIdx}>
                <TableRow>
                  <TableCell className={classes.boldCell}>
                    {set?.category}
                  </TableCell>
                  <TableCell className={classes.boldCell} align="center">
                    {set?.price?.toLocaleString('FR')} Kč
                  </TableCell>
                  <TableCell className={classes.boldCell} align="center">
                    {set?.bail?.toLocaleString('FR')} Kč
                  </TableCell>
                </TableRow>
                {set?.items?.map((item, itemIdx) => {
                  const firstItem = item?.firstItem;
                  const secondItem = item?.secondItem;
                  const catalogItem = item?.firstItem.catalogItem;
                  return (
                    <Fragment key={item?.id || itemIdx}>
                      <TableRow>
                        <TableCell className={classes.tableCellName}>
                          Název:
                        </TableCell>
                        <TableCell
                          className={classes.tableCellName}
                          colSpan={2}
                        >
                          {catalogItem?.name}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableCell}>
                          Kategorie:
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {catalogItem?.categoryName}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <Box display={'flex'} justifyContent={'space-evenly'}>
                            <Barcode value={firstItem?.code} />
                            {secondItem && <Barcode value={secondItem?.code} />}
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableCell}>
                          Varianta:
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          colSpan={secondItem ? 1 : 2}
                        >
                          {catalogItem?.variant}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableCell}>
                          Výrobce:
                        </TableCell>
                        <TableCell className={classes.tableCell} colSpan={2}>
                          {catalogItem?.producer}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableCell}>
                          Velikost:{' '}
                        </TableCell>
                        <TableCell className={classes.tableCell} colSpan={2}>
                          {catalogItem?.size}
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  );
                })}
              </Fragment>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <Paper className={classes.paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Kauce</TableCell>
              <TableCell>{order.bail?.toLocaleString('FR')} Kč</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Půjčovné:</TableCell>
              <TableCell>{order.price?.toLocaleString('FR')} Kč</TableCell>
            </TableRow>
            {order.discount && (
              <TableRow>
                <TableCell>Sleva půjčovné:</TableCell>
                <TableCell>{order.discount.discount} %</TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>
                {isReservation ? 'Kauce zaplaceno:' : 'Kauce celkem:'}
              </TableCell>
              <TableCell>
                {!order.withoutBail || isReservation
                  ? `${bail?.toLocaleString('FR')} Kč`
                  : 'Neplaceno'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {isReservation ? 'Půjčovné zaplaceno:' : 'Půjčovné celkem:'}
              </TableCell>
              <TableCell>{price?.toLocaleString('FR')} Kč</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {isReservation ? 'Zbývá doplatit:' : 'Celkem:'}
              </TableCell>
              <TableCell>
                {isReservation
                  ? (order?.price || 0) +
                    (order.bail || 0) -
                    ((price || 0) + (bail || 0))
                  : ((price || 0) + (bail || 0)).toLocaleString('FR')}{' '}
                Kč
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
      <Paper className={classes.paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell align="center" colSpan={4}>
                Provedené platby
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Částka</TableCell>
              <TableCell>Platba dne</TableCell>
              <TableCell>Platební metoda</TableCell>
              <TableCell>Platba za</TableCell>
            </TableRow>
            {order.payments?.map(payment => (
              <TableRow key={payment?.id}>
                <TableCell>{payment?.amount.toLocaleString('FR')} Kč</TableCell>
                <TableCell>
                  {moment(payment?.createdAt).format('DD.MM.YYYY HH:mm')}
                </TableCell>
                <TableCell>{paymentFormCZ[payment?.form || '']}</TableCell>
                <TableCell>{paymentTypeCZ[payment?.type || '']}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Box>
  );
};

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    paper: {
      minWidth: '900px',
      margin: '24px 24px 0px 24px',
      padding: '16px',
    },
    tableCell: {
      border: 'none',
      padding: '4px 4px 4px 8px',
    },
    boldCell: {
      borderTop: '1px solid rgba(224, 224, 224, 1)',
      fontWeight: 'bold',
    },
    tableCellName: {
      borderTop: '1px solid rgba(224, 224, 224, 1)',
      borderBottom: 'none',
      padding: '4px 4px 4px 8px',
    },
  }),
);

export default OrderDetail;
