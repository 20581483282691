import {
  createStyles,
  Fab,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Theme,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React, { useState } from 'react';
import AddNewEntityDialog from '../../../components/AddNewEntityDialog';
import { CatalogGroups_catalogGroups } from '../../../graphql/types';

interface IProps {
  catalogGroups: CatalogGroups_catalogGroups[];
  selectedCatalogGroupId: string | null;
  setCatalogGroup: (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    child: React.ReactNode,
  ) => void;
}

const GroupSelector: React.FunctionComponent<IProps> = ({
  catalogGroups,
  selectedCatalogGroupId,
  setCatalogGroup,
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleAddButtonClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    setOpen(!open);
  };

  const handleModalClose = () => setOpen(!open);

  return (
    <>
      <div className={classes.title}>Katalogové skupiny</div>
      <div className={classes.controlsWrapper}>
        <FormControl className={classes.formControl}>
          <InputLabel id="select-catalog-group">Skupina</InputLabel>
          <Select
            labelId="select-catalog-group"
            value={selectedCatalogGroupId}
            onChange={setCatalogGroup}
          >
            <MenuItem value="" disabled={true}>
              Vyberte
            </MenuItem>
            {catalogGroups.map(catalogGroup => (
              <MenuItem value={catalogGroup.id} key={catalogGroup.id}>
                {`${catalogGroup.name || ''} ${
                  catalogGroup.name
                    ? `(${catalogGroup.code})`
                    : `${catalogGroup.code}`
                }`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Fab
          className={classes.fab}
          size="small"
          color="primary"
          onClick={handleAddButtonClick}
        >
          <AddIcon />
        </Fab>
      </div>
      {open && (
        <AddNewEntityDialog
          open={true}
          onClose={handleModalClose}
          entity={'CATALOG_GROUP'}
        />
      )}
    </>
  );
};

export default GroupSelector;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: 24,
      textAlign: 'center',
      marginTop: 8,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 240,
      textAlign: 'left',
      marginTop: 16,
    },
    controlsWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
    },
    fab: {
      marginBottom: 4,
    },
  }),
);
