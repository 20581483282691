import {
  createStyles,
  makeStyles,
  Paper,
  TextField,
  Theme,
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import React from 'react';
import CheckBoxCustom from '../../components/CheckBox';
import { labelPlacement } from '../../lib/enums';
import { orderStatePlural } from '../../lib/localization';
import { OrderStateFilters, ReservationStateFilters } from '../../lib/types';

interface IProps {
  search: string;
  handleSearchChange: (event: any) => void;
  orderFilters: OrderStateFilters;
  reservationFilters: ReservationStateFilters;
  activeEntity: 'ORDER' | 'RESERVATION';
  source: ('ADMINISTRATION' | 'ONLINE_RESERVATION')[];
  handleEntityChange: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: any,
  ) => void;
  handleSourceChange: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: any,
  ) => void;
  handleStateCahnge: (state: string) => void;
}

const OrdersAndReservationsFilters: React.FunctionComponent<IProps> = (
  props: IProps,
) => {
  const classes = useStyles();
  const isOrderActive = props.activeEntity === 'ORDER';
  const activeFilters = isOrderActive
    ? props.orderFilters
    : props.reservationFilters;
  return (
    <Paper className={classes.paper}>
      <div className={classes.searchToggleBttonGroupWrapper}>
        <ToggleButtonGroup
          size="medium"
          exclusive
          value={props.activeEntity}
          onChange={props.handleEntityChange}
        >
          <ToggleButton
            classes={{ selected: classes.toggleButtonSelectedOrder }}
            value="ORDER"
          >
            Objednávky
          </ToggleButton>
          <ToggleButton
            classes={{ selected: classes.toggleButtonSelectedReservation }}
            value="RESERVATION"
          >
            Rezervace
          </ToggleButton>
        </ToggleButtonGroup>
        <TextField
          style={{ marginLeft: 24 }}
          label="Hledání"
          type="string"
          value={props.search}
          onChange={props.handleSearchChange}
        />
      </div>
      <div>
        {Object.keys(activeFilters).map(state => (
          <CheckBoxCustom
            key={state}
            checked={activeFilters[state]}
            labelPlacement={labelPlacement.END}
            onChange={() => props.handleStateCahnge(state)}
            name={orderStatePlural[state]}
            color={isOrderActive ? 'primary' : 'secondary'}
          />
        ))}
        {!isOrderActive && (
          <ToggleButtonGroup
            size="small"
            value={props.source}
            onChange={props.handleSourceChange}
          >
            <ToggleButton
              classes={{
                selected: classes.toggleButtonSelectedOnlineReservation,
              }}
              value="ONLINE_RESERVATION"
            >
              Online
            </ToggleButton>
            <ToggleButton
              classes={{ selected: classes.toggleButtonSelectedAdministration }}
              value="ADMINISTRATION"
            >
              Prodejna
            </ToggleButton>
          </ToggleButtonGroup>
        )}
      </div>
    </Paper>
  );
};
export default OrdersAndReservationsFilters;

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    paper: {
      padding: 16,
      display: 'flex',
      justifyContent: 'left',
      flexFlow: 'column',
      minWidth: 562,
    },
    toggleButtonSelectedOrder: {
      color: 'white !important',
      backgroundColor: '#3f51b5 !important',
    },
    toggleButtonSelectedReservation: {
      color: 'white !important',
      backgroundColor: '#f50057 !important',
    },
    toggleButtonSelectedAdministration: {
      color: 'white !important',
      backgroundColor: '#006400 !important',
    },
    toggleButtonSelectedOnlineReservation: {
      color: 'white !important',
      backgroundColor: '#00e676 !important',
    },
    searchToggleBttonGroupWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
    },
  }),
);
