export const newOrderErrorsMap = {
  OpenIdNotFound: 'Open id nenalezen.',
  CanNotWriteBalanceClose:
    'Pokoušíte se zapsat "balance_close", ale chybí "balance_open".',
  CanNotRemoveFromCatalogGroup:
    'Nelze odebrat ze skupiny katalogu, protože není v žádné skupině katalogu.',
  CanNotUpdatePositionInCatalogGroup:
    'Nelze aktualizovat pozici v skupině katalogu, protože položka katalogu není v žádné skupině katalogu.',
  EmployeeNotFoundError: 'Zaměstnanec nenalezen.',
  StockItemNotFound: 'Položka skladu nenalezena.',
  AccessTokenNotFound: 'Přístupový token nenalezen.',
  AllowedCategoriesNotFound: 'Povolené kategorie nenalezeny.',
  CashRegisterNotFound: 'Pokladna nenalezena.',
  CatalogNotFound: 'Katalog nenalezen.',
  CatalogGroupNotFound: 'Skupina katalogu nenalezena.',
  CatalogGroupSizeNotFound: 'Velikost skupiny katalogu nenalezena.',
  CatalogSetNotFound: 'Nastavení katalogu nenalezeno.',
  ClientNotFound: 'Klient nenalezen.',
  CustomerNotFound: 'Zákazník nenalezen.',
  DeliveryAddressNotFound: 'Adresa doručení nenalezena.',
  DiscountNotFound: 'Sleva nenalezena.',
  FilterToCodesNotFound: 'Kódy filtru nenalezeny.',
  ImageNotFound: 'Obrázek nenalezen.',
  ItemPriceNotFound: 'Cena položky nenalezena.',
  OrderNotFound: 'Objednávka nenalezena.',
  PaymentNotFound: 'Platba nenalezena.',
  PickupTimeNotFound: 'Čas vyzvednutí nenalezen.',
  SetNotFound: 'Sada nenalezena.',
  SetPriceNotFound: 'Cena sady nenalezena.',
  SetPriceToAllowedCategoriesNotFound:
    'Cena sady pro povolené kategorie nenalezena.',
  SizeNotFound: 'Velikost nenalezena.',
  StockOrderNotFound: 'Skladová objednávka nenalezena.',
  UserNotFound: 'Uživatel nenalezen.',
  ToBeforeFrom: 'Konec objednávky nemůže být před začátkem objednávky.',
  OrderInPast: 'Datum objednávky je v minulosti.',
  OrderInClosedDay:
    'Nelze provést tuto akci v dny, kdy je provozovna uzavřena.',
};

export const getErrorMessage = (key: string) => {
  return newOrderErrorsMap[key] || 'Záznam se nepodařilo uložit';
};
