import {
  createStyles,
  Fab,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
} from '@material-ui/core';
import { Link, RouteComponentProps } from '@reach/router';
import { all } from 'rambda';
import React, { useState } from 'react';
import { Query } from 'react-apollo';
import { filtersQuery } from '../../../graphql/queries';
import {
  Filters as FiltersType,
  Filters_getFilters,
} from '../../../graphql/types';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';

export const AllFilters = {
  basketSetType: {
    CCS: 'CCS',
    SKI: 'SKI',
    SKIALP_FREERIDE: 'SKIALP_FREERIDE',
    SNOWBOARD: 'SNOWBOARD',
  },
  catalogItemType: {
    BACKPACK: 'BACKPACK',
    BAG: 'BAG',
    BAG_WITH_ABS: 'BAG_WITH_ABS',
    BOOTS: 'BOOTS',
    FINDER: 'FINDER',
    HEAD: 'HEAD',
    MAIN_ITEM: 'MAIN_ITEM',
    POLES: 'POLES',
  },
  sex: {
    CHILD: 'CHILD',
    MAN: 'MAN',
    WOMAN: 'WOMAN',
  },
  skills: {
    BEGINNER: 'BEGINNER',
    RECREATIONAL: 'RECREATIONAL',
    SPORT: 'SPORT',
    FREERIDE: 'FREERIDE',
  },
  skiSlope: {
    FREE: 'FREE',
    GROOMED: 'GROOMED',
  },
  skiArc: {
    LONG: 'LONG',
    SHORT: 'SHORT',
    UNIVERSAL: 'UNIVERSAL',
  },
  ccsStyle: {
    KLASIC: 'KLASIC',
    SCATE: 'SCATE',
  },
  ccsPurpose: {
    SKIN: 'SKIN',
    GRIP: 'GRIP',
  },
};

const Filters: React.FunctionComponent<RouteComponentProps> = () => {
  const [input, setInput] = useState<any>(null);
  const [filter, setFilter] = useState<boolean>(false);
  const classes = useStyles();

  const onChange = (type: string) => (event: any) => {
    const data = { ...input, [type]: event.target.value };
    setInput(Object.values(data).filter(o => !!o).length > 0 ? data : null);
  };

  const frontendFilter = (size: Filters_getFilters | null) => {
    if (filter && size && input) {
      return all(
        (key: string) => size[key] === input[key] || input[key] === undefined,
      )(Object.keys(input));
    }
    if (!size) {
      return false;
    }
    return true;
  };

  const onFilterChange = () => setFilter(!filter);

  return (
    <div className={classes.pageWrapper}>
      <Paper className={classes.container}>
        {Object.keys(AllFilters).map(filter => (
          <FormControl className="text-field" key={filter}>
            <InputLabel>{filter}</InputLabel>
            <Select
              required={
                filter === 'BasketSetType' || filter === 'CatalogItemType'
              }
              value={input ? input[filter] : undefined}
              onChange={onChange(filter)}
              color="primary"
            >
              {[...Object.keys(AllFilters[filter]), undefined].map(key => (
                <MenuItem value={key} key={key || 99999}>
                  {key || 'Nenastaveno'}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ))}
        <FormControlLabel
          control={
            <Switch
              checked={filter}
              value="filter"
              onChange={onFilterChange}
              color="primary"
            />
          }
          label="Filtrovat"
          title="bude pouze filtrovat (takto se to nechová v online půjčovně)"
        />
        <Query<FiltersType>
          query={filtersQuery}
          variables={{ input: !filter ? input : null }}
        >
          {({ data, loading, error }) => {
            if (loading) {
              return <p>Loading...</p>;
            }
            if (error) {
              return <p>Nenalezen žádný odpovídající filter</p>;
            }
            if (data && data.getFilters) {
              return (
                <>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Pravidla</TableCell>
                        <TableCell>Primární skupiny</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.getFilters
                        .filter(frontendFilter)
                        .map((filter, index) => (
                          <TableRow
                            style={
                              index === 0 ? { backgroundColor: 'yellow' } : {}
                            }
                            key={index}
                          >
                            <TableCell
                              style={{
                                display: 'flex',
                                alignItems: 'space-between',
                              }}
                            >
                              <div>
                                {Object.keys(filter || {})
                                  .filter(
                                    key =>
                                      filter?.[key] !== null &&
                                      key !== 'catalogGroups' &&
                                      key !== '__typename' &&
                                      key !== 'id',
                                  )
                                  .map(key => (
                                    <div
                                      key={key}
                                    >{`${key}="${filter?.[key]}"`}</div>
                                  ))}
                              </div>
                              <Link to={`filter-edit/${filter?.id}`}>
                                <Tooltip title="Upravit">
                                  <IconButton>
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>
                              </Link>
                            </TableCell>
                            <TableCell>
                              {filter?.catalogGroups.length
                                ? [
                                    ...new Set(
                                      filter.catalogGroups.map(
                                        item => item.code,
                                      ),
                                    ),
                                  ].join(', ')
                                : ''}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </>
              );
            }
            return <p>No Filters</p>;
          }}
        </Query>
        <div
          className="addButton"
          style={{
            marginTop: '80px',
            position: 'fixed',
            bottom: 20,
            right: 20,
          }}
        >
          <Link to={'filter-edit/create'}>
            <Tooltip title="Přidat">
              <Fab color="primary">
                <AddIcon />
              </Fab>
            </Tooltip>
          </Link>
        </div>
      </Paper>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageWrapper: {
      padding: 16,
      display: 'flex',
    },
    container: {
      flex: '1 1 0px',
      marginRight: 16,
      textAlign: 'center',
      padding: 8,
    },
  }),
);

export default Filters;
