import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink, split } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import * as Raven from 'raven-js';
import auth from '../lib/auth';
import { errorMessages } from '../lib/errorMessages';
import introspectionQueryResultData from './fragmentTypes.json';
import {
  GET_CATALOG_GROUP_LENGTH,
  GET_INPUT_WITHOUT_CATALOG_ITEM_ID,
  GET_INPUT_WITH_CATALOG_ITEM_ID,
} from './localQueries';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from 'apollo-utilities';
import { Observable } from 'apollo-client/util/Observable';
import fetch from 'cross-fetch';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(error => {
      const alertText = errorMessages[error.name];
      if (alertText) {
        alert(alertText.message);
      }
      // this is in fact NOT an error object despite ts type so use captureMessage instead
      Raven.captureMessage(`GraphQL error: ${error.message}`, {
        level: 'error',
        extra: { error },
      });
      // tslint:disable-next-line no-console
      console.error('[GraphQL error]:', error);
    });
  }
  if (networkError) {
    // tslint:disable-next-line no-console
    Raven.captureException(networkError, { extra: { error: networkError } });
    console.error('[Network error]:', networkError);
  }
});

const GRAPHQL_URI = '/api/graphql';

const wsLink = new WebSocketLink({
  options: {
    reconnect: true,
    connectionParams: {
      authToken: auth.getAccessToken(),
    },
  },
  uri: `${window.location.protocol === 'http:' ? 'ws' : 'wss'}://${
    window.location.hostname
  }${window.location.port ? `:${window.location.port}` : ''}/api/subscriptions`,
});

const httpLink = new HttpLink({
  fetch,
  uri: GRAPHQL_URI,
});

const authLink = setContext((_: any, { headers }: any) => {
  const token = auth.getAccessToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const loggingLink = new ApolloLink((operation, forward) => {
  Raven.captureBreadcrumb({
    message: `Starting request for ${operation.operationName}`,
    category: 'graphql',
    data: { variables: operation.variables },
  });
  if (forward == null) {
    return null;
  }

  return (forward(operation).map != null
    ? forward(operation)
    : Observable.from(forward(operation))
  ).map(data => {
    Raven.captureBreadcrumb({
      message: `Ending request for ${operation.operationName}`,
      category: 'graphql',
      data: {
        variables: operation.variables,
        result: data,
      },
    });
    return data;
  });
});

const requestLink = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink as any,
  authLink.concat(httpLink),
);

const client = new ApolloClient({
  cache: new InMemoryCache({
    fragmentMatcher,
  }),
  link: ApolloLink.from([errorLink, loggingLink, requestLink]),
  resolvers: {
    Query: {
      catalogGroupLength: (root, args, { cache }) => {
        return cache.readQuery({ query: GET_CATALOG_GROUP_LENGTH });
      },
      inputWithCatalogItemID: (root, args, { cache }) => {
        return cache.readQuery({ query: GET_INPUT_WITH_CATALOG_ITEM_ID });
      },
      inputWithoutCatalogItemID: (root, args, { cache }) => {
        return cache.readQuery({ query: GET_INPUT_WITHOUT_CATALOG_ITEM_ID });
      },
    },
  },
});

export default client;
