import { Button, Paper, TextField, withStyles } from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';

const styles = (theme: any) => ({
  paper: {
    // width: '100%',
    display: 'flex',
    flexFlow: 'wrap',
    justifyContent: 'center',
    padding: '16px',
    alignItems: 'center',
  },
  buttonsWrapper: {
    display: 'flex',
    marginLeft: 60,
  },
});

interface IProps {
  classes: any;
  DateFrom: string;
  DateTo: string;
  handleDateFromChange: (from: string) => void;
  handleDateToChange: (to: string) => void;
  handleIntervalChange: (from: string, to: string) => void;
}

class IntervalPicker extends React.Component<IProps> {
  handleFromChange = (e: any) =>
    this.props.handleDateFromChange(e.target.value);
  handleToChange = (e: any) => this.props.handleDateToChange(e.target.value);

  handleDateChange = (date: string) => {
    let from = '';
    let to = '';
    switch (date) {
      case 'yesterday':
        from = moment()
          .subtract(1, 'd')
          .format('YYYY-MM-DD');
        to = moment()
          .subtract(1, 'd')
          .format('YYYY-MM-DD');
        break;
      case 'today':
        from = moment().format('YYYY-MM-DD');
        to = moment().format('YYYY-MM-DD');
        break;
      case 'thisWeek':
        from = moment()
          .startOf('isoWeek')
          .format('YYYY-MM-DD');
        to = moment()
          .endOf('isoWeek')
          .format('YYYY-MM-DD');
        break;
      case 'lastWeek':
        from = moment()
          .startOf('isoWeek')
          .subtract(7, 'd')
          .format('YYYY-MM-DD');
        to = moment()
          .endOf('isoWeek')
          .subtract(7, 'd')
          .format('YYYY-MM-DD');
        break;
      case 'thisMonth':
        from = moment()
          .startOf('month')
          .format('YYYY-MM-DD');
        to = moment()
          .endOf('month')
          .format('YYYY-MM-DD');
        break;
      case 'lastMonth':
        from = moment()
          .startOf('month')
          .subtract(1, 'month')
          .format('YYYY-MM-DD');
        to = moment()
          .endOf('month')
          .subtract(1, 'month')
          .format('YYYY-MM-DD');
    }
    this.props.handleIntervalChange(from, to);
  };

  render() {
    const { classes, DateFrom, DateTo } = this.props;
    return (
      <Paper className={classes.paper}>
        <div>
          <TextField
            label="Od"
            type="date"
            value={DateFrom}
            className={classes.datePicker}
            onChange={this.handleFromChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="Do"
            type="date"
            value={DateTo}
            className={classes.datePicker}
            onChange={this.handleToChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: DateTo.split('T')[0],
            }}
          />
        </div>
        <div className={classes.buttonsWrapper}>
          <div className="directionColumn">
            <Button
              onClick={() => this.handleDateChange('yesterday')}
              variant="outlined"
            >
              Včera
            </Button>
            <Button
              onClick={() => this.handleDateChange('today')}
              variant="outlined"
            >
              Dnes
            </Button>
          </div>
          <div className="directionColumn">
            <Button
              onClick={() => this.handleDateChange('thisWeek')}
              variant="outlined"
            >
              Tento týden
            </Button>
            <Button
              onClick={() => this.handleDateChange('lastWeek')}
              variant="outlined"
            >
              Minulý týden
            </Button>
          </div>
          <div className="directionColumn">
            <Button
              onClick={() => this.handleDateChange('thisMonth')}
              variant="outlined"
            >
              Tento měsíc
            </Button>
            <Button
              onClick={() => this.handleDateChange('lastMonth')}
              variant="outlined"
            >
              Minulý měsíc
            </Button>
          </div>
        </div>
      </Paper>
    );
  }
}

export default withStyles(styles)(IntervalPicker);
