import React, { useEffect } from 'react';
import {
  CatalogItemsInCatalogGroup,
  CatalogItemsInCatalogGroupVariables,
} from '../../../graphql/types';
import GraphQLErrorLoadingBoundary from '../../../components/GraphQLErrorLoadingBoundary';
import { catalogItemsInCatalogGroupQuery } from '../../../graphql/queries';
import {
  CatalogItemCellType,
  CatalogItemTableActionsType,
} from '../../../lib/types';
import CatalogItemTableWrapper from './CatalogItemTableWrapper';
import { writeInputToCache } from './utils';
import { useApolloClient } from 'react-apollo';

interface IProps {
  catalogGroupId?: number;
  withoutCatalogGroup?: boolean;
  search?: string;
  showItemPositionInGroup?: boolean;
  actionsDefinition: CatalogItemTableActionsType;
}

const CatalogItemTable: React.FunctionComponent<IProps> = (props: IProps) => {
  const client = useApolloClient();

  let queryVariables: CatalogItemsInCatalogGroupVariables = { input: {} };
  if (props.catalogGroupId) {
    queryVariables.input.catalogGroupId = props.catalogGroupId;
  }
  if (props.withoutCatalogGroup) {
    queryVariables.input.withoutCatalogGroup = true;
  }
  if (props.search) {
    queryVariables.input.search = props.search;
  }
  queryVariables.input.limit = 10;
  queryVariables.input.offset = 0;
  queryVariables.input.order = 'asc';

  useEffect(() => {
    writeInputToCache(client, queryVariables.input);
  }, [client, queryVariables]);

  const tableCellsDefinition: CatalogItemCellType[] = [
    {
      entity: 'name',
      label: 'Název',
      sortable: true,
    },
    {
      entity: 'producer',
      label: 'Výrobce',
      sortable: true,
    },
    {
      entity: 'category',
      label: 'Kategorie',
      sortable: true,
    },
    {
      entity: 'purpose',
      label: 'Určení',
      sortable: true,
    },
    {
      entity: 'variant',
      label: 'Varianta',
      sortable: true,
    },
    {
      entity: 'sizes',
      label: 'Velikosti',
      sortable: false,
    },
    {
      entity: 'actions',
      label: 'Akce',
      sortable: false,
    },
  ];

  if (props.showItemPositionInGroup) {
    tableCellsDefinition.unshift({
      entity: 'positionInCatalogGroup',
      label: 'Pořadí',
      sortable: true,
    });
  }
  return (
    <GraphQLErrorLoadingBoundary<
      CatalogItemsInCatalogGroup,
      CatalogItemsInCatalogGroupVariables
    >
      query={catalogItemsInCatalogGroupQuery}
      options={{ variables: queryVariables }}
    >
      {({ data, refetch, variables }) => (
        <CatalogItemTableWrapper
          tableCellsDefinition={tableCellsDefinition}
          variables={variables as any}
          data={data}
          refetch={refetch}
          actionsDefinition={props.actionsDefinition}
        />
      )}
    </GraphQLErrorLoadingBoundary>
  );
};
export default CatalogItemTable;
