import React, { useState } from 'react';
import {
  Paper,
  makeStyles,
  createStyles,
  Theme,
  TextField,
  IconButton,
  Button,
} from '@material-ui/core';
import DayOverview from './DayOverview';
import RecordsList from './RecordsList';
import { useQuery } from 'react-apollo';
import {
  getLastCashRecord,
  getCashRegisterRecords,
} from '../../graphql/queries';
import {
  GetLastCashRecord,
  GetCashRegisterRecordsVariables,
  GetCashRegisterRecords,
} from '../../graphql/types';
import CloseIcon from '@material-ui/icons/Close';
import OpenCloseCashRegisterModal from './OpenCloseCashRegisterModal';

const CashRegister = () => {
  const [date, setDate] = useState<null | string>(null);
  const classes = useStyles();
  const [modal, setModal] = useState<null | string>(null);
  const { data, refetch: lastRefetch } = useQuery<GetLastCashRecord>(
    getLastCashRecord,
    { fetchPolicy: 'cache-and-network' },
  );

  const { data: listData, refetch } = useQuery<
    GetCashRegisterRecords,
    GetCashRegisterRecordsVariables
  >(getCashRegisterRecords, {
    variables: {
      date,
    },
    fetchPolicy: 'cache-and-network',
  });

  const handleRefetch = () => {
    refetch({ date });
    lastRefetch();
  };

  if (data && data.lastCashRecord == null) {
    return (
      <div className={classes.center}>
        <div className={classes.title}>
          Vítej při prvním otevření kasy v tomto systému.
        </div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setModal('opening')}
        >
          Otevřít kasu
        </Button>
        <OpenCloseCashRegisterModal
          isOpen={!!modal}
          onClose={() => setModal(null)}
          isOpening={modal === 'opening'}
          onRefetch={handleRefetch}
        />
      </div>
    );
  }

  return (
    <div>
      <div className={classes.flex}>
        <Paper className={classes.paper}>
          <Paper component="form" className={classes.datePaper}>
            <TextField
              label="Den"
              type="date"
              value={date || undefined}
              className="text-field"
              onChange={e => setDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <IconButton
              aria-label="delete"
              color="primary"
              className={classes.iconButton}
              onClick={() => setDate(null)}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>
          </Paper>
        </Paper>
        <Paper className={classes.paper}>
          {data && (
            <DayOverview
              amountInStart={data.lastCashRecord?.openWithAmount}
              amountInEnd={data.lastCashRecord?.closedWithAmount}
            />
          )}
        </Paper>
      </div>
      {listData && <RecordsList data={listData} />}
      <div className={classes.bottomBar}>
        {data?.lastCashRecord?.closedWithAmount != null ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setModal('opening')}
          >
            Otevřít kasu
          </Button>
        ) : (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setModal('closing')}
          >
            Zavřít kasu
          </Button>
        )}
      </div>
      <OpenCloseCashRegisterModal
        isOpen={!!modal}
        onClose={() => setModal(null)}
        isOpening={modal === 'opening'}
        onRefetch={handleRefetch}
        disabled={modal === 'opening' && !!data?.lastCashRecord}
        initAmount={
          modal === 'opening' && data?.lastCashRecord?.closedWithAmount
            ? data?.lastCashRecord?.closedWithAmount.toString()
            : ''
        }
      />
    </div>
  );
};

export default CashRegister;

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    title: {
      fontSize: 20,
      margin: '32px 0px',
    },
    center: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    bottomBar: {
      display: 'flex',
      justifyContent: 'flex-end',
      flex: 1,
      marginTop: 16,
    },
    paper: {
      padding: 16,
      display: 'flex',
      alignItems: 'center',
      margin: '0 32px',
    },
    iconButton: {
      position: 'absolute',
      right: 40,
      bottom: 16,
    },
    flex: {
      display: 'flex',
      justifyContent: 'center',
      flex: 1,
      marginBottom: 32,
    },
    datePaper: {
      boxShadow: 'none',
      position: 'relative',
    },
    icon: {
      width: '16px',
      height: '16px',
    },
  }),
);
