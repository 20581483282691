import React, { useState, useEffect } from 'react';
import Modal from '../../components/Modal';
import {
  FormControl,
  TextField,
  makeStyles,
  createStyles,
  Theme,
  Button,
} from '@material-ui/core';
import { useMutation } from 'react-apollo';
import { addRecordToCashRegister } from '../../graphql/queries';
import {
  AddRecordToCashRegister,
  AddRecordToCashRegisterVariables,
  RecordType,
} from '../../graphql/types';
import auth from '../../lib/auth';
import { useSnackbar } from 'notistack';
import { getGraphqlErrorMessage } from '../../lib/utils';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  isOpening?: boolean;
  onRefetch: () => void;
  initAmount?: string;
  disabled?: boolean;
}

const OpenCloseCashRegisterModal = ({
  isOpen,
  onClose,
  isOpening = false,
  onRefetch,
  disabled = false,
  initAmount = '',
}: Props) => {
  const [amount, setAmount] = useState(initAmount);
  const isAdmin = auth.isAdmin();
  const [addRecord] = useMutation<
    AddRecordToCashRegister,
    AddRecordToCashRegisterVariables
  >(addRecordToCashRegister, {
    onError: error => {
      enqueueSnackbar(getGraphqlErrorMessage(error), { variant: 'error' });
    },
    onCompleted: () => {
      onRefetch();
      onClose();
    },
  });

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setAmount(initAmount);
  }, [initAmount]);

  const handleAddRecord = async () => {
    addRecord({
      variables: {
        recordType: isOpening
          ? RecordType.BALANCE_OPEN
          : RecordType.BALANCE_CLOSE,
        amount: Number(amount),
      },
    });
  };

  const classes = useStyles();
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={isOpening ? 'Otevřít kasu' : 'Zavřít kasu'}
    >
      <FormControl className={classes.form}>
        <TextField
          label="Suma (CZK)"
          type="number"
          onChange={e => setAmount(e.target.value)}
          disabled={isAdmin ? false : disabled}
          value={amount}
        />
        <Button
          onClick={handleAddRecord}
          variant="contained"
          color="secondary"
          disabled={!amount.length}
          className={classes.saveButt}
        >
          {isOpening ? 'Otevřít kasu' : 'Zavřít kasu'}
        </Button>
      </FormControl>
    </Modal>
  );
};

export default OpenCloseCashRegisterModal;

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    form: {
      display: 'flex',
    },
    saveButt: {
      marginTop: 16,
    },
  }),
);
