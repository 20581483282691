export enum PaymentType {
  BAIL = 'BAIL',
  PRICE = 'PRICE',
  RETRUN = 'RETURN',
  RETURN_PRICE = 'RETURN_PRICE',
}

export enum PaymentForm {
  CARD = 'CARD',
  CASH = 'CASH',
  INVOICE = 'INVOICE',
}

export enum labelPlacement {
  START = 'start',
  END = 'end',
}

export enum PagesNames {
  Catalog = 'Catalog',
  Order = 'Order',
}

export enum PriceEntity {
  ITEM_PRICE = 'itemPrice',
  SET_PRICE = 'setPRice',
}
