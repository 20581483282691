export const errorMessages: any = Object.freeze({
  ItemsAreNotAvailableInSpecifiedDate: {
    message:
      'Nebylo možné změnit termín objednávky, jelikož v novém termínu nejsou dostupné všechny položky.',
  },
  CatalogItemInActiveOrder: {
    message:
      'Některá z položek je v aktivní objednávce. Položku půjde smazat až budou všechny její kódy vráceny.',
  },
});
