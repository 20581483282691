import { TableBody, TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import { CatalogItemsInCatalogGroup_catalogItemsSizeIndependent } from '../../../graphql/types';
import {
  CatalogItemCellType,
  CatalogItemTableActionsType,
} from '../../../lib/types';
import CellActionAddItem from './CellActionAddItem';
import CellActionRemoveAndReorderItem from './CellActionRemoveAndReorderItem';

interface IProps {
  tableCellsDefinition: CatalogItemCellType[];
  data: CatalogItemsInCatalogGroup_catalogItemsSizeIndependent;
  actionsDefinition: CatalogItemTableActionsType;
}

const CatalogItemTableContent: React.FunctionComponent<IProps> = (
  props: IProps,
) => {
  return (
    <TableBody>
      {props.data.items.map((catalogItem, idx) => (
        <TableRow hover key={catalogItem.id}>
          {props.tableCellsDefinition.map(cellDefinition => {
            const items: any = catalogItem;
            let label = '';
            if (cellDefinition.entity === 'category') {
              label = items[cellDefinition.entity].name;
            } else if (cellDefinition.entity === 'sizes') {
              label = items[cellDefinition.entity]
                .map((size: string) => size.trim())
                .join(', ');
            } else {
              label = items[cellDefinition.entity];
            }
            const key = `${cellDefinition.entity}_${catalogItem.id}}`;
            if (cellDefinition.entity !== 'actions') {
              return (
                <TableCell style={{ padding: 8 }} key={key}>
                  {label}
                </TableCell>
              );
            } else {
              let content = null;
              if (props.actionsDefinition.addItem) {
                content = (
                  <CellActionAddItem
                    itemIds={catalogItem.ids}
                    name={catalogItem.name}
                    producer={catalogItem.producer}
                    sizes={catalogItem.sizes}
                  />
                );
              }
              if (props.actionsDefinition.removeItemAndReorder) {
                content = (
                  <CellActionRemoveAndReorderItem
                    itemIds={catalogItem.ids}
                    isFirst={catalogItem.positionInCatalogGroup === 1}
                    isLast={
                      catalogItem.positionInCatalogGroup ===
                      props.data.totalCount
                    }
                    positionInGroup={catalogItem.positionInCatalogGroup || 1}
                    name={catalogItem.name}
                    producer={catalogItem.producer}
                    sizes={catalogItem.sizes}
                  />
                );
              }
              return (
                <TableCell style={{ padding: 8 }} key={key}>
                  {content}
                </TableCell>
              );
            }
          })}
        </TableRow>
      ))}
    </TableBody>
  );
};

export default CatalogItemTableContent;
