import {
  createStyles,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  makeStyles,
  Switch,
  TextField,
  Theme,
} from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import {
  addCategoryMutation,
  categories,
  editCategoryMutation,
} from '../../../graphql/queries';
import {
  AddCategory,
  AddCategoryVariables,
  EditCategory,
  EditCategoryVariables,
} from '../../../graphql/types';
import { Category as CategoryType } from '../../../lib/types';

interface Props {
  onClose: () => void;
  category: Partial<CategoryType>;
}

const CategoryEditDialog = ({ onClose, category: categoryInit }: Props) => {
  const [category, setCategory] = useState(categoryInit);
  const { enqueueSnackbar } = useSnackbar();
  const [addCategory] = useMutation<AddCategory, AddCategoryVariables>(
    addCategoryMutation,
    {
      onCompleted: () => {
        enqueueSnackbar('Kategorie založena', { variant: 'success' });
        onClose();
      },
      onError: () =>
        enqueueSnackbar('Kategorii se nepodařilo založit', {
          variant: 'error',
        }),
      refetchQueries: [{ query: categories }],
    },
  );
  const [editCategory] = useMutation<EditCategory, EditCategoryVariables>(
    editCategoryMutation,
    {
      onCompleted: () => {
        enqueueSnackbar('Kategorie úspěšně upravena', { variant: 'success' });
        onClose();
      },
      onError: () =>
        enqueueSnackbar('Kategorii se nepodařilo upravit', {
          variant: 'error',
        }),
    },
  );
  const classes = useStyles();

  const handleNameChange = (event: { target: { value: string } }) =>
    setCategory({ ...category, name: event.target.value });

  const handleExcludedFromSetChange = () =>
    setCategory(prev => ({
      ...prev,
      itemsExcludedFromSet: !prev.itemsExcludedFromSet,
    }));

  const handleSave = () => {
    const categoryId = category.id;
    if (categoryId != null) {
      editCategory({
        variables: {
          input: {
            name: category.name,
            itemsExcludedFromSet: category.itemsExcludedFromSet,
            id: categoryId.toString(),
          },
        },
      });
    } else {
      addCategory({
        variables: {
          name: category.name || '',
          itemsExcludedFromSet: category.itemsExcludedFromSet,
        },
      });
    }
  };
  return (
    <>
      <DialogTitle id="form-dialog-title">
        {category.id ? 'Editace Kategorie' : 'Vytvoříte novou Kategorii'}
      </DialogTitle>
      <DialogContent className={classes.content}>
        <TextField
          label="Název"
          className={classes.textField}
          value={category.name || ''}
          onChange={handleNameChange}
        />
        <FormControlLabel
          control={
            <Switch
              checked={!category.itemsExcludedFromSet}
              value="itemsExcludedFromSet"
              onChange={handleExcludedFromSetChange}
              color="primary"
            />
          }
          label="Položky kategorie se počítají do setu *"
        />
        <div>
          * Při výpočtu ceny se použije setová cena pokud v setu je právě jedna
          položka, která tvoří set a dvě a více položek, jejichž kategorie se
          počítá do setu.
        </div>
        <DialogActions className={classes.actions}>
          <Button
            variant="contained"
            color="primary"
            disabled={!(!!category.name && category.name.length > 2)}
            onClick={handleSave}
          >
            Uložit
          </Button>
        </DialogActions>
      </DialogContent>
    </>
  );
};

export default CategoryEditDialog;

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '16px',
    },
    textField: {
      margin: '8px',
      width: '80%',
    },
    actions: {
      marginTop: '16px',
    },
  }),
);
