import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles,
  TextField,
  Divider,
} from '@material-ui/core';
import * as React from 'react';
import { Mutation } from 'react-apollo';
import { Sizes_getSizes } from '../../../graphql/types';
import { sizesQuery, setSize } from '../../../graphql/queries';

type IProps = {
  size: Sizes_getSizes | null | undefined;
  open: boolean;
  onClose: () => void;
  classes: any;
};

const EditSizeDialog: React.FunctionComponent<IProps> = props => {
  const [newSize, setNewSize] = React.useState<any | null | undefined>(
    convertPropSize(props.size),
  );

  function convertPropSize(size: Sizes_getSizes | null | undefined) {
    const copy: any = Object.assign({}, size);
    delete copy.__typename;
    copy.catalogGroupNames = size?.catalogGroups.map(item => item.code);
    delete copy.catalogGroups;
    return copy;
  }

  const handleChange = (propName: string, number: boolean = true) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setNewSize({
      ...newSize,
      [propName]: number
        ? Number.parseFloat(event.target.value) || undefined
        : event.target.value,
    });
  };

  const handleGroupsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewSize({
      ...newSize,
      catalogGroupNames: event.target.value
        .split(',')
        .map(group => group.trim()),
    });
  };

  const { open, onClose, size, classes } = props;
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {size ? `Editace velikosti ${size?.id}` : 'Vytvoření nové velikosti'}
      </DialogTitle>
      <DialogContent>
        <div className="checkBoxsGroup">
          <TextField
            label="Min. výška"
            type="number"
            inputProps={{
              min: 0,
            }}
            value={newSize?.heightMin}
            className="text-field"
            onChange={handleChange('heightMin')}
          />
          <TextField
            label="Max. výška"
            type="number"
            inputProps={{
              min: 0,
            }}
            value={newSize?.heightMax}
            className="text-field"
            onChange={handleChange('heightMax')}
          />
        </div>
        <div className="checkBoxsGroup">
          <TextField
            label="Min. váha"
            type="number"
            inputProps={{
              min: 0,
            }}
            value={newSize?.weightMin}
            className="text-field"
            onChange={handleChange('weightMin')}
          />
          <TextField
            label="Max. váha"
            type="number"
            inputProps={{
              min: 0,
            }}
            value={newSize?.weightMax}
            className="text-field"
            onChange={handleChange('weightMax')}
          />
        </div>
        <div className="checkBoxsGroup">
          <TextField
            label="Velikost chodidla"
            type="number"
            inputProps={{
              step: 0.5,
              min: 0,
            }}
            value={newSize?.footSize}
            className="text-field"
            onChange={handleChange('footSize')}
          />
        </div>
        <Divider />
        <div className="checkBoxsGroup">
          <TextField
            label="Min. velikost"
            type="number"
            inputProps={{
              step: 0.5,
              min: 0,
            }}
            value={newSize?.min}
            className="text-field"
            onChange={handleChange('min')}
          />
          <TextField
            label="Max. velikost"
            type="number"
            inputProps={{
              step: 0.5,
              min: 0,
            }}
            value={newSize?.max}
            className="text-field"
            onChange={handleChange('max')}
          />
        </div>
        <div className="checkBoxsGroup">
          <TextField
            label="Velikost"
            type="text"
            value={newSize?.size}
            className="text-field"
            onChange={handleChange('size', false)}
          />
        </div>
        <Divider />
        <div className="checkBoxsGroup">
          <TextField
            label="Katalogové položky"
            type="text"
            value={newSize?.catalogGroupNames?.join(', ')}
            className="text-field"
            placeholder={'A1, A13'}
            onChange={handleGroupsChange}
          />
        </div>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Mutation
          mutation={setSize}
          variables={{ input: newSize }}
          onCompleted={onClose}
          onError={window.alert}
          refetchQueries={[
            {
              query: sizesQuery,
            },
          ]}
        >
          {(postMutation: () => void) => (
            <Button
              variant="contained"
              color={'secondary'}
              onClick={postMutation}
            >
              Uložit
            </Button>
          )}
        </Mutation>
        <Button onClick={onClose} color="primary">
          Zavřít
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const styles = (theme: any) => ({
  textField: {
    margin: '16px',
    width: '85px',
  },
  button: {
    marginLeft: '16px',
  },
  dialogActions: {
    justifyContent: 'space-between',
  },
});

export default withStyles(styles)(EditSizeDialog);
