import {
  Button,
  FormControl,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-apollo';
import Modal from '../../components/Modal';
import { addPaymentsAndCancelOrder } from '../../graphql/queries';
import {
  addPaymentsAndCancelOrder as AddPaymentsType,
  PaymentForm,
  PaymentType,
  PaymentType as PaymentTypeEnum,
  addPaymentsAndCancelOrderVariables,
} from '../../graphql/types';
import { Payment } from '../../lib/types';
import { getGraphqlErrorMessage } from '../../lib/utils';

interface Props {
  orderId: number;
  isOpen: boolean;
  onClose: () => void;
  payments: Payment[];
}

interface ReturnPayment {
  amount: number;
  type: PaymentType;
  form: PaymentForm;
}

const CancelPaymentsModal = ({ orderId, isOpen, onClose, payments }: Props) => {
  const [type, setType] = useState(PaymentType.RETURN);
  const [paymentForm, setPaymentForm] = useState(PaymentForm.CASH);
  const [amount, setAmount] = useState(0);
  const classes = useStyles();
  const [returnPayments, setReturnPayments] = useState<ReturnPayment[]>([]);
  const [showError, setShowError] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const [createPaymentsAndCancelReservation] = useMutation<
    AddPaymentsType,
    addPaymentsAndCancelOrderVariables
  >(addPaymentsAndCancelOrder, {
    onError: err =>
      enqueueSnackbar(getGraphqlErrorMessage(err), { variant: 'error' }),
  });

  useEffect(() => {
    if (type === PaymentType.RETURN_PRICE) {
      setAmount(
        payments.find(x => x.type === PaymentTypeEnum.PRICE)?.amount || 0,
      );
    }

    if (type === PaymentType.RETURN) {
      setAmount(
        payments.find(x => x.type === PaymentTypeEnum.BAIL)?.amount || 0,
      );
    }
  }, [payments, type]);

  const checkPaymentsAmount = () => {
    const filteredReturnPricePayments = filterPaymentsByType(
      payments,
      PaymentType.PRICE,
    );
    const sumOfPayments = reducePaymentsAmount(filteredReturnPricePayments);

    // Calculate the total sum of returned price
    const filteredReturnPayments = filterPaymentsByType(
      returnPayments,
      PaymentType.PRICE,
    );
    const sumOfReturnedPrice = reducePaymentsAmount(filteredReturnPayments);

    // Check if the returned price amount is not greater than the sum of payments
    return sumOfReturnedPrice <= sumOfPayments;
  };

  const reducePaymentsAmount = (payments: any) => {
    return payments.reduce((sum: any, payment: any) => sum + payment.amount, 0);
  };

  const filterPaymentsByType = (payments: any, type: PaymentType) => {
    return payments.filter((payment: any) => payment.type === type);
  };

  const handleAddPayment = async () => {
    const isValid = checkPaymentsAmount();

    const newPayment = { amount: amount, type: type, form: paymentForm };

    if (type === PaymentType.RETURN_PRICE) {
      const filteredPricePayments = filterPaymentsByType(
        payments,
        PaymentTypeEnum.PRICE,
      );
      const sumOfPayments = reducePaymentsAmount(filteredPricePayments);

      const filteredReturnPricePayments = filterPaymentsByType(
        returnPayments,
        PaymentType.RETURN_PRICE,
      );
      const sumOfExistingReturnedPrice = reducePaymentsAmount(
        filteredReturnPricePayments,
      );

      const totalReturnedPrice = sumOfExistingReturnedPrice + newPayment.amount;
      if (isValid && totalReturnedPrice <= sumOfPayments) {
        const updatedReturnPayments = [...returnPayments, newPayment];
        setReturnPayments(updatedReturnPayments);
        setShowError(false);
      } else {
        setShowError(true);
      }
    }

    if (type === PaymentType.RETURN) {
      const filteredReturnPayments = filterPaymentsByType(
        returnPayments,
        PaymentType.RETURN,
      );
      const sumOfExistingReturnedBails = reducePaymentsAmount(
        filteredReturnPayments,
      );
      const filteredBailPayments = filterPaymentsByType(
        payments,
        PaymentTypeEnum.BAIL,
      );
      const sumOfPaymentsBails = reducePaymentsAmount(filteredBailPayments);
      const totalReturnedPrice2 =
        sumOfExistingReturnedBails + newPayment.amount;
      if (isValid && totalReturnedPrice2 <= sumOfPaymentsBails) {
        const updatedReturnPayments = [...returnPayments, newPayment];
        setReturnPayments(updatedReturnPayments);
      } else {
        setShowError(true);
      }
    }
  };

  const handleOnClose = () => {
    onClose();
    setReturnPayments([]);
  };

  const handleDelete = (index: number) => {
    const updatedPayments = returnPayments.filter((_, i) => i !== index);
    setReturnPayments(updatedPayments);
  };

  const handleSave = async () => {
    await createPaymentsAndCancelReservation({
      variables: {
        orderId,
        isUpdate: true,
        payments: returnPayments,
      },
    });

    handleOnClose();
  };

  const getPaymentTranslation = (paymentForm: string): string => {
    if (paymentForm === PaymentTypeEnum.BAIL) return 'Kauce';
    if (paymentForm === PaymentTypeEnum.RETURN) return 'Vrácená kauce';
    if (paymentForm === PaymentTypeEnum.PRICE) return 'Půjčovné';
    if (paymentForm === PaymentTypeEnum.RETURN_PRICE) return 'Vrácené půjčovné';
    if (paymentForm === PaymentForm.CARD) return 'Převod na účet';
    if (paymentForm === PaymentForm.CASH) return 'Hotovost';

    return '';
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Vrácení platby">
      <div className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Typ</TableCell>
              <TableCell>Částka</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payments.map((payment: Payment, index: number) => (
              <React.Fragment key={`payment-wrap-${index}`}>
                {
                  <TableRow key={`payment-${index}`}>
                    <TableCell>{getPaymentTranslation(payment.type)}</TableCell>
                    <TableCell>{payment.amount}</TableCell>
                  </TableRow>
                }
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </div>
      <div className={classes.tableReturnPayments}>
        <>
          {!!returnPayments.length && (
            <>
              <Typography variant="h6" gutterBottom>
                Platby k vrácení
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Vráceno</TableCell>
                    <TableCell>Způsob vrácení</TableCell>
                    <TableCell>Částka</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {returnPayments.map(
                    (payment: ReturnPayment, index: number) => (
                      <TableRow key={`return-payment-${index}`}>
                        <TableCell>
                          {getPaymentTranslation(payment.type)}
                        </TableCell>
                        <TableCell>
                          {getPaymentTranslation(payment.form)}
                        </TableCell>
                        <TableCell>{payment.amount}</TableCell>
                        <TableCell>
                          <Button onClick={() => handleDelete(index)}>
                            <DeleteIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ),
                  )}
                </TableBody>
              </Table>
            </>
          )}
        </>
      </div>
      <div className={classes.form}>
        <FormControl>
          <Select
            labelId="select-label"
            id="select"
            value={type}
            className={classes.inputSelect}
            onChange={e =>
              setType(
                e.target.value as
                  | PaymentTypeEnum.RETURN_PRICE
                  | PaymentTypeEnum.RETURN,
              )
            }
          >
            <MenuItem value="RETURN_PRICE">Půjčovné</MenuItem>
            <MenuItem value="RETURN">Kauce</MenuItem>
          </Select>
        </FormControl>

        <FormControl>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={paymentForm}
            className={classes.input}
            onChange={e =>
              setPaymentForm(
                e.target.value as PaymentForm.CASH | PaymentForm.CARD,
              )
            }
          >
            <MenuItem value="CASH">V hotovosti</MenuItem>
            <MenuItem value="CARD">Převod na účet</MenuItem>
          </Select>
        </FormControl>

        <FormControl>
          <TextField
            label="Suma (CZK)"
            className={classes.priceInput}
            type="number"
            onChange={e => setAmount(Number(e.target.value))}
            value={amount}
          />
        </FormControl>

        <Button
          variant="outlined"
          color="secondary"
          disabled={!amount}
          onClick={handleAddPayment}
          className={classes.addButton}
        >
          Vrátit
        </Button>
      </div>
      {showError ? (
        <Typography align="center" color="error">
          Nelze vrátit více než bylo zaplaceno
        </Typography>
      ) : null}
      {!!returnPayments.length && (
        <Button
          variant="contained"
          color="primary"
          disabled={!returnPayments.length}
          onClick={handleSave}
          className={classes.saveButton}
        >
          Uložit
        </Button>
      )}
      <Button
        color="secondary"
        onClick={handleOnClose}
        className={classes.closeButton}
      >
        Zavřít
      </Button>
    </Modal>
  );
};

export default CancelPaymentsModal;

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    input: {
      marginLeft: 10,
      marginTop: 15,
    },
    inputSelect: {
      marginTop: 15,
    },
    table: {
      maxHeight: '300px',
      overflow: 'auto',
      marginBottom: 24,
    },
    tableReturnPayments: {
      maxHeight: '200px',
      overflow: 'auto',
    },
    priceInput: {
      marginLeft: 24,
    },
    form: {
      marginTop: 24,
      display: 'flex',
      flexDirection: 'row',
    },
    addButton: {
      marginLeft: 24,
    },
    saveButton: {
      display: 'flex',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 28,
    },
    closeButton: {
      display: 'flex',
      marginLeft: 'auto',
      marginTop: 28,
    },
  }),
);
