import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  withStyles,
} from '@material-ui/core';
import * as React from 'react';
import { Mutation } from 'react-apollo';
import { PriceEntity } from '../lib/enums';
import {
  addItemPrice,
  addSetPrice,
  itemPrices,
  setPrices,
  addCatalogGroup,
  catalogGroupsQuery,
} from '../graphql/queries';

type IState = {
  name: string;
};

type IProps = {
  classes: any;
  open: boolean;
  onClose: () => void;
  entity: PriceEntity | 'CATEGORY' | 'CATALOG_GROUP';
};

class AddNewEntityDialog extends React.Component<IProps, IState> {
  state: IState = {
    name: '',
  };

  handleNameChange = (event: { target: { value: string } }) =>
    this.setState({ name: event.target.value });

  render() {
    let title = '';
    let mutation = null;
    let refetchQuery = null;
    switch (this.props.entity) {
      case PriceEntity.ITEM_PRICE:
        title = 'Zadejte název pro novou položkovou cenu';
        mutation = addItemPrice;
        refetchQuery = itemPrices;
        break;
      case PriceEntity.SET_PRICE:
        title = 'Zadejte název pro novou setovou cenu';
        mutation = addSetPrice;
        refetchQuery = setPrices;
        break;
      case 'CATALOG_GROUP':
        title = 'Zadejte název nové katalogové skupiny';
        mutation = addCatalogGroup;
        refetchQuery = catalogGroupsQuery;
    }
    return (
      <Dialog
        onClose={this.props.onClose}
        open={this.props.open}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent style={{ padding: '0px' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '16px',
            }}
          >
            <TextField
              label="Název"
              className={this.props.classes.textField}
              value={this.state.name}
              onChange={this.handleNameChange}
            />
          </div>
        </DialogContent>
        <DialogActions className={this.props.classes.dialogActions}>
          <div className="invisible">{}</div>
          <Mutation
            mutation={mutation}
            variables={{
              name: this.state.name,
            }}
            onCompleted={this.props.onClose}
            refetchQueries={[{ query: refetchQuery }]}
          >
            {(postMutation: any) => (
              <Button
                variant="contained"
                color="primary"
                className={this.props.classes.button}
                onClick={postMutation}
              >
                Uložit
              </Button>
            )}
          </Mutation>
          <Button onClick={this.props.onClose} color="primary">
            Zavřít
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
const styles = (theme: any) => ({
  textField: {
    margin: '8px',
    width: '80%',
  },
  select: {
    marginTop: '14px',
  },
  button: {
    width: '150px',
    marginTop: '16px',
  },
  dialogActions: {
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
});

export default withStyles(styles)(AddNewEntityDialog);
