import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Fab,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import * as React from 'react';
import { Query } from 'react-apollo';
import { PriceEntity } from '../../lib/enums';
import auth from '../../lib/auth';
import { itemPrices } from '../../graphql/queries';
import { Price } from '../../lib/types';
import AddPriceDialog from '../../components/AddNewEntityDialog';
import PriceEditDialog from './PriceEditDialog';
import { RouteComponentProps } from '@reach/router';

type IState = {
  item: Price | null;
  addPriceDialogOpened: boolean;
};

class Items extends React.Component<RouteComponentProps, IState> {
  state: IState = {
    item: null,
    addPriceDialogOpened: false,
  };

  sortPrice = (a: Price, b: Price) => {
    let result = 0;
    if (a.id < b.id) {
      result = -1;
    } else if (a.id > b.id) {
      result = 1;
    }
    return result;
  };

  openPriceEdit = (item: Price) => {
    this.setState({ item });
  };

  closePriceEdit = () => {
    this.setState({ item: null });
  };

  handleAddPriceDialog = () => {
    this.setState(prevSate => ({
      addPriceDialogOpened: !prevSate.addPriceDialogOpened,
    }));
  };

  render() {
    return (
      <div className="Item">
        <Query query={itemPrices}>
          {(data: any) => {
            if (data.loading) {
              return <p>Loading...</p>;
            }
            if (data.error) {
              return <p>Error</p>;
            }
            const itemPricesData: Price[] = JSON.parse(
              JSON.stringify(data.data.itemPrices),
            );
            const isAdmin = auth.isAdmin();
            return (
              <div>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Název</TableCell>
                      <TableCell>Fixní cena</TableCell>
                      <TableCell>Varianta</TableCell>
                      <TableCell>1 den</TableCell>
                      <TableCell>2 den</TableCell>
                      <TableCell>Základ</TableCell>
                      <TableCell>Záloha</TableCell>
                      <TableCell>1 Sezóna</TableCell>
                      <TableCell>2 Sezóny</TableCell>
                      <TableCell>Sezónní záloha</TableCell>
                      {isAdmin && <TableCell>Upravit ceny</TableCell>}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {itemPricesData.sort(this.sortPrice).map((item: Price) => (
                      <TableRow key={item.id}>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item.fixedPrice ? 'ANO' : 'NE'}</TableCell>
                        <TableCell>{item.variant}</TableCell>
                        <TableCell>
                          {item.oneDayPrice}
                          &nbsp;Kč
                        </TableCell>
                        <TableCell>
                          {item.fixedPrice
                            ? '-'
                            : `${item.twoDayPrice} \u00A0Kč`}
                        </TableCell>
                        <TableCell>
                          {item.fixedPrice ? '-' : `${item.basePrice} \u00A0Kč`}
                        </TableCell>
                        <TableCell>
                          {item.bail}
                          &nbsp;Kč
                        </TableCell>
                        <TableCell>
                          {item.seasonPrice}
                          &nbsp;Kč
                        </TableCell>
                        <TableCell>
                          {item.fixedPrice
                            ? '-'
                            : `${item.twoSeasonPrice} \u00A0Kč`}
                        </TableCell>
                        <TableCell>
                          {item.seasonBail}
                          &nbsp;Kč
                        </TableCell>
                        {isAdmin && (
                          <TableCell>
                            <Button onClick={() => this.openPriceEdit(item)}>
                              <EditIcon />
                            </Button>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '24px',
                  }}
                >
                  <Tooltip title="Přidat">
                    <Fab color="primary" onClick={this.handleAddPriceDialog}>
                      <AddIcon />
                    </Fab>
                  </Tooltip>
                </div>
                {this.state.item && (
                  <PriceEditDialog
                    item={this.state.item}
                    onClose={this.closePriceEdit}
                    open={this.state.item ? true : false}
                    entity={PriceEntity.ITEM_PRICE}
                  />
                )}
                {this.state.addPriceDialogOpened && (
                  <AddPriceDialog
                    open={this.state.addPriceDialogOpened}
                    onClose={this.handleAddPriceDialog}
                    entity={PriceEntity.ITEM_PRICE}
                  />
                )}
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default Items;
