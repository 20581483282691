import React, { useState } from 'react';
import { useParams } from '@reach/router';
import {
  getCashRegister,
  cashRegisterDifferenceResolved,
} from '../../graphql/queries';
import {
  GetCashRegisterVariables,
  GetCashRegister,
  RecordType,
  GetCashRegister_cashRegister,
  CashRegisterDifferenceResolved,
  CashRegisterDifferenceResolvedVariables,
} from '../../graphql/types';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  Paper,
  makeStyles,
  createStyles,
  Theme,
  Button,
} from '@material-ui/core';
import DayOverview from '../CashRegister/DayOverview';
import ItemsList from './ItemsList';
import OpenCloseCashRegisterModal from '../CashRegister/OpenCloseCashRegisterModal';
import AddRecordModal from './AddRecordModal';

const CashRegisterDetail = () => {
  const params = useParams();
  const [isAddRecordModalOpen, setIsAddRecordModalOpen] = useState(false);
  const [
    isCloseCashRegisterModalOpen,
    setIsCloseCashRegisterModalOpen,
  ] = useState(false);
  const queryVariables = {
    cashRegisterRecordId: params.id,
  };
  const { data, refetch } = useQuery<GetCashRegister, GetCashRegisterVariables>(
    getCashRegister,
    {
      variables: queryVariables,
      fetchPolicy: 'cache-and-network',
    },
  );

  const classes = useStyles();

  const [resolveDifferent] = useMutation<
    CashRegisterDifferenceResolved,
    CashRegisterDifferenceResolvedVariables
  >(cashRegisterDifferenceResolved);

  if (!data) return null;

  const opening = data.cashRegister[data.cashRegister.length - 1];
  let closing: null | GetCashRegister_cashRegister = data.cashRegister[0];
  closing =
    closing.__typename === 'Payment'
      ? null
      : closing.recordType === RecordType.BALANCE_CLOSE
      ? closing
      : null;

  const handleResolve = () => {
    if (closing) {
      resolveDifferent({
        variables: {
          id: closing.id,
        },
        refetchQueries: [{ query: getCashRegister, variables: queryVariables }],
      });
    }
  };

  const handleRefetch = () => {
    refetch(queryVariables);
  };

  return (
    <div>
      <div className={classes.flex}>
        <Paper className={classes.paper}>
          <DayOverview
            amountInStart={opening.amount}
            amountInEnd={closing ? closing.amount : null}
            detail={{
              closedWithDifference: closing?.closedWithDifference,
              closedDifference: closing?.closedDifference,
              onResolve: handleResolve,
              differenceResolved: closing?.differenceResolved,
            }}
          />
        </Paper>
      </div>
      {data && <ItemsList data={data} />}
      {closing == null && (
        <div className={classes.bottomBar}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsAddRecordModalOpen(true)}
          >
            Přidat záznam
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={classes.closeButton}
            onClick={() => setIsCloseCashRegisterModalOpen(true)}
          >
            Zavřít kasu
          </Button>
        </div>
      )}
      <OpenCloseCashRegisterModal
        isOpen={isCloseCashRegisterModalOpen}
        onClose={() => setIsCloseCashRegisterModalOpen(false)}
        onRefetch={handleRefetch}
      />
      <AddRecordModal
        isOpen={isAddRecordModalOpen}
        onClose={() => setIsAddRecordModalOpen(false)}
      />
    </div>
  );
};

export default CashRegisterDetail;

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    closeButton: {
      marginLeft: 16,
    },
    bottomBar: {
      display: 'flex',
      justifyContent: 'flex-end',
      flex: 1,
      marginTop: 16,
    },
    paper: {
      padding: 16,
      display: 'flex',
      alignItems: 'center',
      margin: '0 32px',
    },
    flex: {
      display: 'flex',
      justifyContent: 'center',
      flex: 1,
      marginBottom: 32,
    },
  }),
);
