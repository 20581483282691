export const paymentTypeCZ: any = Object.freeze({
  BAIL: 'Kauce(přijatá)',
  PRICE: 'Půjčovné',
  RETURN: 'Kauce(vrácená)',
  RETURN_PRICE: 'Půjčovné(vrácené)',
});

export const paymentTypeCZShorted: any = Object.freeze({
  BAIL: 'Kauce',
  PRICE: 'Půjčovné',
});

export const paymentFormCZ: any = Object.freeze({
  CASH: 'Hotovost',
  CARD: 'Karta',
  INVOICE: 'Faktura',
  ONLINE: 'Online',
});

export const orderStateSingularCZ: any = Object.freeze({
  NEW: 'Nová',
  PREPARED: 'Připravená',
  ACTIVE: 'Aktivní',
  FINISHED: 'Dokončená',
  CHANGE: 'Výměna',
  RESERVATION_NEW: 'Nová',
  RESERVATION_PREPARED: 'Připravená',
  RESERVATION_PAID: 'Zaplacená',
  RESERVATION_CANCELED: 'Stornovaná',
  CANCELED: 'Stornovaná',
});

export const orderStatePlural = Object.freeze({
  new: 'Nové',
  prepared: 'Připravené',
  active: 'Aktivní',
  finished: 'Dokončené',
  unreturned: 'Nevrácené',
  paid: 'Zaplacené',
  canceled: 'Stornované',
});
