import gql from 'graphql-tag';

export const GET_CATALOG_GROUP_LENGTH = gql`
  {
    catalogGroupLength @client
  }
`;

export const GET_INPUT_WITH_CATALOG_ITEM_ID = gql`
  {
    inputWithCatalogItemID @client {
      limit
      offset
      sort
      order
      search
      catalogGroupId
      withoutCatalogGroup
    }
  }
`;

export const GET_INPUT_WITHOUT_CATALOG_ITEM_ID = gql`
  {
    inputWithoutCatalogItemID @client {
      limit
      offset
      sort
      order
      search
      catalogGroupId
      withoutCatalogGroup
    }
  }
`;
