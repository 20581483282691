import React from 'react';
import { makeStyles, Theme, createStyles, Button } from '@material-ui/core';

interface Props {
  amountInStart?: number;
  amountInEnd?: number | null;
  isDetail?: boolean;
  detail?: {
    closedWithDifference?: boolean | null;
    differenceResolved?: boolean | null;
    closedDifference?: number | null;
    onResolve: () => void;
  };
}

const DayOverview = ({ amountInEnd, amountInStart, detail }: Props) => {
  const classes = useStyles();

  const renderMaybeResolveButton = () => {
    if (!detail) return null;
    if (detail.closedWithDifference && !detail.differenceResolved) {
      return (
        <Button
          variant="contained"
          color="secondary"
          onClick={e => detail.onResolve()}
          className={classes.resolveButton}
        >
          Vyřešit
        </Button>
      );
    }
    return detail.differenceResolved ? 'Ano' : '-';
  };

  return (
    <div>
      <span className={classes.title}>{detail ? 'Stav' : 'Aktuální stav'}</span>
      <div className={classes.flex}>
        {detail && (
          <>
            <div className={classes.column}>
              <div>Zavřeno s rozdílem</div>
              <div className={classes.item}>
                Rozdíl{' '}
                {detail?.closedWithDifference && !detail.differenceResolved ? (
                  <b>({detail.closedDifference})</b>
                ) : (
                  'vyřešen'
                )}
              </div>
            </div>
            <div className={classes.valueColumn}>
              <div>
                {detail.closedWithDifference == null
                  ? '-'
                  : detail.closedWithDifference
                  ? 'Ano'
                  : 'Ne'}
              </div>
              <div className={classes.item}>{renderMaybeResolveButton()}</div>
            </div>
          </>
        )}
        <div className={classes.column}>
          <div>Počáteční zůstatek</div>
          <div className={classes.item}>Konečný zůčastek</div>
        </div>
        <div className={classes.valueColumn}>
          <div>{amountInStart} CZK</div>
          <div className={classes.item}>
            {amountInEnd != null ? `${amountInEnd} CZK` : '-'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DayOverview;

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    column: {
      marginRight: 16,
      fontSize: 20,
    },
    title: {
      fontSize: 12,
      color: '#757575',
    },
    date: {
      fontSize: 22,
      fontWeight: 700,
      marginTop: 8,
    },
    flex: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 8,
    },
    resolveButton: {
      height: 25,
    },
    rightBox: {
      marginLeft: 16,
      paddingLeft: 16,
      borderLeft: '1px solid rgba(0, 0, 0, 0.42)',
      display: 'flex',
      alignItems: 'center',
    },
    valueColumn: {
      margin: '0 32px',
      fontWeight: 700,
      fontSize: 20,
    },
    labelBox: {
      fontSize: 20,
    },
    item: {
      marginTop: 8,
    },
  }),
);
