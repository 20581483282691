import Button from '@material-ui/core/Button';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React, { useEffect } from 'react';

interface Props {
  id: string;
  anchorEl: HTMLElement | null;
  open: boolean;
  children: React.ReactNode;
}

interface PropsMenu {
  children: React.ReactNode;
  open?: boolean;
  setMenuOpen?: (isOpen: boolean) => void;
}

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(({ children, id, ...props }: Props & MenuProps) => (
  <Menu getContentAnchorEl={null} key={id} {...props}>
    {children}
  </Menu>
));

const ContextMenu = ({ open, children, setMenuOpen }: PropsMenu) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (open) {
      handleCloseMenu();
      setMenuOpen?.(false);
    }
  }, [open, setMenuOpen]);

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="outlined"
        color="default"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {children}
      </StyledMenu>
    </div>
  );
};

export default ContextMenu;
