import { Redirect, RouteComponentProps } from '@reach/router';
import React from 'react';
import auth from '../lib/auth';
import ProtectedPageHeader from './PageHeader';
import { getNthOfpathname } from '../lib/utils';

type Props = {
  component: React.ComponentType<RouteComponentProps & { pathName: string }>;
  adminRights: boolean;
};

const ProtectedRoute = ({
  component: Component,
  adminRights,
  ...props
}: Props & RouteComponentProps) => {
  const isLoggedIn = auth.isLoggedIn();
  if (!isLoggedIn || Component === null || (adminRights && !auth.isAdmin())) {
    return <Redirect to="/login" noThrow />;
  }

  const pathname = props.location && props.location.pathname;
  return (
    <ProtectedPageHeader
      pathName={
        getNthOfpathname(pathname || '', 0) || 'orders-and-reservations'
      }
    >
      <Component
        pathName={getNthOfpathname(pathname || '', 1) || '/'}
        {...props}
      />
    </ProtectedPageHeader>
  );
};

export default ProtectedRoute;
