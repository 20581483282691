import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from '@material-ui/core';
import * as React from 'react';
import auth from '../lib/auth';
import './UploadImageDialog.css';
import fetch from 'cross-fetch';

type IProps = {
  onClose: () => void;
  onUpdate: () => void;
  id: number;
  entity: string;
  open: boolean;
  label: string;
  pathToCurrentImage?: string;
};

type IState = {
  file: object | null;
  pathToCurrentImage: string | undefined;
};

class UploadImageDialog extends React.Component<IProps, IState> {
  state: IState = {
    file: null,
    pathToCurrentImage: this.props.pathToCurrentImage,
  };

  handleClose = () => this.props.onClose();

  fileChangeHandlerer = (event: { target: any }) => {
    const file = event.target.files[0];
    this.setState({ file });
  };

  onUpload = async () => {
    if (this.state.file) {
      const data = new FormData();
      data.append('file', this.state.file as Blob);
      const response = await fetch(
        `/api/imageUpload/${this.props.entity}/${this.props.id}`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${auth.getAccessToken()}`,
          },
          body: data,
        },
      );
      const responseText = await response.text();
      const body = JSON.parse(responseText);
      if (response.ok) {
        this.props.onUpdate();
        this.setState({
          pathToCurrentImage: body.imagePath,
          file: null,
        });
      } else {
        alert(body.message || 'Neznámá chyba');
      }
    }
  };

  render() {
    const { open, label } = this.props;
    const { pathToCurrentImage } = this.state;
    return (
      <Dialog
        open={open}
        onClose={this.handleClose}
        aria-labelledby="simple-dialog-title"
        fullWidth={true}
      >
        <DialogTitle id="simple-dialog-title">{`Nastavení obrázku pro ${label}`}</DialogTitle>
        <DialogContent>
          {pathToCurrentImage && (
            <div>
              <DialogContentText>Současný obrázek</DialogContentText>
              <div className={'flex'}>
                <img src={pathToCurrentImage} className="image" alt="" />
              </div>
            </div>
          )}
          <Divider />
          <div className={'infoText'}>
            Velikost obrázků je omezena na 100kB. <br />
            Podporované fromáty jsou .png, .jpg, .jpeg
          </div>
          <Divider />
          <div className={'buttons'}>
            <input
              type="file"
              accept="image/png, image/jpeg"
              onChange={this.fileChangeHandlerer}
            />
            <Button
              color="primary"
              variant="contained"
              onClick={this.onUpload}
              disabled={this.state.file === null}
            >
              Nahrát
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={this.handleClose}
            >
              Zavřít
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default UploadImageDialog;
