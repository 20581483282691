import {
  Button,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import CloudDownload from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import { RouteComponentProps } from '@reach/router';
import moment from 'moment';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import CheckBoxCustom from '../../components/CheckBox';
import ConfirmDialog from '../../components/ConfirmDialog';
import { deletePayment, PaymentsQuery } from '../../graphql/queries';
import auth from '../../lib/auth';
import { labelPlacement } from '../../lib/enums';
import { paymentFormCZ, paymentTypeCZ } from '../../lib/localization';
import { Payment, PaymentsType } from '../../lib/types';
import { downloadPDF } from '../../lib/utils';
import { PaymentExport } from './PaymentExport';
import './Payments.css';

const useStyles = makeStyles({
  paper: {
    // width: '100%',
    display: 'flex',
    flexFlow: 'wrap',
    justifyContent: 'center',
    padding: '16px',
    alignItems: 'center',
  },
  paperSums: {
    // width: '100%',
    display: 'flex',
    flexFlow: 'wrap',
    justifyContent: 'space-around',
    padding: '16px',
  },
  checkBoxCustom: {
    marginTop: 10,
  },
  datePicker: {
    marginLeft: 10,
    marginRight: 10,
  },
  exportButton: {
    float: 'right' as 'right',
    marginTop: '20px',
  },
});

interface IState {
  from: string;
  to: string;
  price: boolean;
  bail: boolean;
  repay: boolean;
  repayPrice: boolean;
  cash: boolean;
  card: boolean;
  invoice: boolean;
  online: boolean;
}

const PaymentsList = ({ state }: { state: IState }) => {
  const {
    from,
    to,
    price,
    bail,
    repay,
    repayPrice,
    cash,
    card,
    invoice,
    online,
  } = state;

  const { data, loading, refetch } = useQuery(PaymentsQuery, {
    variables: {
      from,
      to: moment(to, 'YYYY-MM-DD')
        .add(1, 'd')
        .format('YYYY-MM-DD'),
      price,
      bail,
      repay,
      repayPrice,
      cash,
      card,
      invoice,
      online,
    },
    fetchPolicy: moment(to).isBefore(moment(), 'day')
      ? 'cache-first'
      : 'cache-and-network',
  });
  const [deleteMutation] = useMutation(deletePayment, {
    onError: error => {
      window.alert(error.message);
      refetch();
    },
  });

  const isOwner = auth.isOwner();

  const classes = useStyles();

  const getCSVData = (payments: Payment[]) => [
    [
      'Datum',
      'Částka',
      'Účel',
      'Typ transakce',
      'Jméno pracovníka',
      'Číslo zakázky',
    ],
    ...payments.map(payment => [
      moment(payment.createdAt, 'YYYY-MM-DD').format('DD. MM. YYYY'),
      `${payment.amount.toString()} CZK`,
      paymentTypeCZ[payment.type],
      paymentFormCZ[payment.form],
      payment.user ? payment.user.username : '',
      payment.order ? payment.order.id.toString() : '',
    ]),
  ];

  const [
    confirmDialogPayment,
    setConfirmDialogPayment,
  ] = useState<Payment | null>(null);

  const handleConfirmDialogOpen = (payment: Payment) => () =>
    setConfirmDialogPayment(payment);
  const handleConfirmDialogClose = () => setConfirmDialogPayment(null);

  const onDeleteAgree = async () => {
    if (confirmDialogPayment) {
      await deleteMutation({
        variables: {
          paymentId: confirmDialogPayment.id,
        },
      });
      refetch();
      handleConfirmDialogClose();
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }
  const payments: PaymentsType = data.payments;
  let dialogContent = '';
  if (confirmDialogPayment) {
    const date = moment(confirmDialogPayment.createdAt, 'YYYY-MM-DD').format(
      'DD.MM.YYYY',
    );
    const emp = confirmDialogPayment.user
      ? confirmDialogPayment.user.username
      : 'online';
    const amount = confirmDialogPayment.amount;
    const orderId = confirmDialogPayment.order.id;
    dialogContent = `Opravdu chcete smazat transakci vytvořenou dne ${date}, uživatelem ${emp}, ve výši ${amount}, která
    patří k zakázce číslo  ${orderId}`;
  }

  return (
    <div>
      <Paper className={classes.paperSums}>
        <div>{`Suma půjčovné: ${payments.sums.priceSum.toLocaleString(
          'FR',
        )} CZK`}</div>
        <div>{`Suma kauce(přijatá): ${payments.sums.bailSum.toLocaleString(
          'FR',
        )} CZK`}</div>
        <div>{`Suma kauce(vrácená): ${payments.sums.repaySum.toLocaleString(
          'FR',
        )} CZK`}</div>
        <div>{`Suma pújčovné(vrácené): ${payments.sums.repaySumPayment.toLocaleString(
          'FR',
        )} CZK`}</div>
        <div>{`Suma hotovost: ${payments.sums.cashSum.toLocaleString(
          'FR',
        )} CZK`}</div>
        <div>{`Suma karta: ${payments.sums.cardSum.toLocaleString(
          'FR',
        )} CZK`}</div>
        <div>{`Suma faktura: ${payments.sums.invoiceSum.toLocaleString(
          'FR',
        )} CZK`}</div>
        <div>{`Suma online: ${payments.sums.onlineSum.toLocaleString(
          'FR',
        )} CZK`}</div>
        <div>{`Celkový příjem: ${(
          payments.sums.priceSum +
          payments.sums.bailSum -
          (payments.sums.repaySum + payments.sums.repaySumPayment)
        ).toLocaleString('FR')} CZK`}</div>
      </Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Datum</TableCell>
            <TableCell>Částka</TableCell>
            <TableCell>Účel</TableCell>
            <TableCell>Typ transakce</TableCell>
            <TableCell>Jméno pracovníka</TableCell>
            <TableCell>Číslo zakázky</TableCell>
            {isOwner && <TableCell>Smazání transakce</TableCell>}
            <TableCell>PDF zakázky</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {payments.payments.map((payment: any, index: any) => (
            <TableRow key={index}>
              <TableCell>
                {moment(payment.createdAt, 'YYYY-MM-DD').format('DD/MM/YYYY')}
              </TableCell>
              <TableCell
                style={{ whiteSpace: 'nowrap' }}
              >{`${payment.amount.toLocaleString('FR')} CZK`}</TableCell>
              <TableCell>{paymentTypeCZ[payment.type]}</TableCell>
              <TableCell>{paymentFormCZ[payment.form]}</TableCell>
              <TableCell>{payment.user ? payment.user.username : ''}</TableCell>
              <TableCell>{payment.order.id}</TableCell>
              {isOwner && (
                <TableCell>
                  <Button onClick={handleConfirmDialogOpen(payment)}>
                    <DeleteIcon />
                  </Button>
                </TableCell>
              )}
              <TableCell>
                <Button onClick={() => downloadPDF(payment.order.id, false)}>
                  <CloudDownload />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <div className={classes.exportButton}>
        <PaymentExport
          fileName={`platby_${from}-${to}`}
          csvData={getCSVData(payments.payments)}
        />
      </div>

      {confirmDialogPayment && (
        <ConfirmDialog
          open={true}
          onClose={handleConfirmDialogClose}
          onAgree={onDeleteAgree}
          titleLabel="Mazání transakce"
          dialogContent={dialogContent}
        />
      )}
    </div>
  );
};

const Payments = (props: RouteComponentProps) => {
  const [price, setPrice] = useState<boolean>(false);
  const [bail, setBail] = useState<boolean>(false);
  const [repay, setRepay] = useState<boolean>(false);
  const [repayPrice, setRepayPrice] = useState<boolean>(false);
  const [cash, setCash] = useState<boolean>(false);
  const [card, setCard] = useState<boolean>(false);
  const [invoice, setInvoice] = useState<boolean>(false);
  const [online, setOnline] = useState<boolean>(false);
  const [from, setFrom] = useState<string>(moment().format('YYYY-MM-DD'));
  const [to, setTo] = useState<string>(moment().format('YYYY-MM-DD'));

  const state = {
    price,
    bail,
    repay,
    repayPrice,
    cash,
    card,
    invoice,
    online,
    from,
    to,
  };

  const handlePriceChange = () => setPrice(price => !price);
  const handleBailChange = () => setBail(bail => !bail);
  const handleRepayChange = () => setRepay(repay => !repay);
  const handleRepayPriceChange = () => setRepayPrice(repayPrice => !repayPrice);
  const handleCashChange = () => setCash(cash => !cash);
  const handleCardChange = () => setCard(card => !card);
  const handleInvoiceChange = () => setInvoice(invoice => !invoice);
  const handleOnlineChange = () => setOnline(online => !online);
  const handleFromChange = (e: any) => setFrom(e.target.value);
  const handleToChange = (e: any) => setTo(e.target.value);

  const handleDateChange = (date: string) => {
    let from = '';
    let to = '';
    switch (date) {
      case 'yesterday':
        from = moment()
          .subtract(1, 'd')
          .format('YYYY-MM-DD');
        to = moment()
          .subtract(1, 'd')
          .format('YYYY-MM-DD');
        break;
      case 'today':
        from = moment().format('YYYY-MM-DD');
        to = moment().format('YYYY-MM-DD');
        break;
      case 'thisWeek':
        from = moment()
          .startOf('isoWeek')
          .format('YYYY-MM-DD');
        to = moment()
          .endOf('isoWeek')
          .format('YYYY-MM-DD');
        break;
      case 'lastWeek':
        from = moment()
          .startOf('isoWeek')
          .subtract(7, 'd')
          .format('YYYY-MM-DD');
        to = moment()
          .endOf('isoWeek')
          .subtract(7, 'd')
          .format('YYYY-MM-DD');
        break;
      case 'thisMonth':
        from = moment()
          .startOf('month')
          .format('YYYY-MM-DD');
        to = moment()
          .endOf('month')
          .format('YYYY-MM-DD');
        break;
      case 'lastMonth':
        from = moment()
          .startOf('month')
          .subtract(1, 'month')
          .format('YYYY-MM-DD');
        to = moment()
          .endOf('month')
          .subtract(1, 'month')
          .format('YYYY-MM-DD');
    }
    setFrom(from);
    setTo(to);
  };

  const classes = useStyles();

  return (
    <div>
      <Paper className={classes.paper}>
        <div className="section">
          <div className="filterColumn">
            <div className="centerText">Účel</div>
            <CheckBoxCustom
              className={classes.checkBoxCustom}
              checked={price}
              onChange={handlePriceChange}
              name="Pujčovné"
              labelPlacement={labelPlacement.END}
            />
            <CheckBoxCustom
              className={classes.checkBoxCustom}
              checked={bail}
              onChange={handleBailChange}
              labelPlacement={labelPlacement.END}
              name="Kauce(přijatá)"
            />
            <CheckBoxCustom
              className={classes.checkBoxCustom}
              checked={repay}
              labelPlacement={labelPlacement.END}
              onChange={handleRepayChange}
              name="Kauce(vrácená)"
            />
            <CheckBoxCustom
              className={classes.checkBoxCustom}
              checked={repayPrice}
              labelPlacement={labelPlacement.END}
              onChange={handleRepayPriceChange}
              name="Půjčovné(vrácené)"
            />
          </div>
        </div>
        <div className="section">
          <div className="filterColumn">
            <div className="centerText"> Typ transakce</div>
            <CheckBoxCustom
              className={classes.checkBoxCustom}
              checked={cash}
              labelPlacement={labelPlacement.END}
              onChange={handleCashChange}
              name="Hotovost"
            />
            <CheckBoxCustom
              className={classes.checkBoxCustom}
              checked={card}
              onChange={handleCardChange}
              labelPlacement={labelPlacement.END}
              name="Karta"
            />
            <CheckBoxCustom
              className={classes.checkBoxCustom}
              checked={invoice}
              onChange={handleInvoiceChange}
              labelPlacement={labelPlacement.END}
              name="Faktura"
            />
            <CheckBoxCustom
              className={classes.checkBoxCustom}
              checked={online}
              onChange={handleOnlineChange}
              labelPlacement={labelPlacement.END}
              name="Online"
            />
          </div>
        </div>
        <div className="section">
          <div className="filterColumn">
            <TextField
              label="Od"
              type="date"
              value={from}
              className={classes.datePicker}
              onChange={handleFromChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              label="Do"
              type="date"
              value={to}
              className={classes.datePicker}
              onChange={handleToChange}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: from.split('T')[0],
              }}
            />
          </div>
        </div>
        <div className="section">
          <div className="filterColumn">
            <div className="directionColumn">
              <Button
                onClick={() => handleDateChange('yesterday')}
                variant="outlined"
              >
                Včera
              </Button>
              <Button
                onClick={() => handleDateChange('today')}
                variant="outlined"
              >
                Dnes
              </Button>
            </div>
            <div className="directionColumn">
              <Button
                onClick={() => handleDateChange('thisWeek')}
                variant="outlined"
              >
                Tento týden
              </Button>
              <Button
                onClick={() => handleDateChange('lastWeek')}
                variant="outlined"
              >
                Minulý týden
              </Button>
            </div>
            <div className="directionColumn">
              <Button
                onClick={() => handleDateChange('thisMonth')}
                variant="outlined"
              >
                Tento měsíc
              </Button>
              <Button
                onClick={() => handleDateChange('lastMonth')}
                variant="outlined"
              >
                Minulý měsíc
              </Button>
            </div>
          </div>
        </div>
      </Paper>
      <PaymentsList state={state} />
    </div>
  );
};

export default Payments;
