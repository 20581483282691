import { createStyles, makeStyles, TextField, Theme } from '@material-ui/core';
import React from 'react';

interface IProps {
  search: string;
  handeSearchChange: any;
}

const CatalogItemSearch: React.FunctionComponent<IProps> = ({
  search,
  handeSearchChange,
}) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.title}>Katalogové položky bez skupiny</div>
      <TextField
        className={classes.textField}
        label="Hledání"
        type="string"
        defaultValue={search}
        onChange={handeSearchChange}
      />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: 24,
      textAlign: 'center',
      marginTop: 8,
    },
    textField: {
      margin: theme.spacing(1),
      minWidth: 240,
      textAlign: 'left',
      marginTop: 16,
    },
  }),
);

export default CatalogItemSearch;
