import React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
import { GetCashRegister, PaymentType, RecordType } from '../../graphql/types';
import { formatTime } from '../../lib/utils';
import { includes } from 'rambda';

interface Props {
  data: GetCashRegister;
}

const mapTypes = {
  RETURN: 'Vrácení kauce',
  RETURN_PRICE: 'Vrácení půjčovného',
  BAIL: 'Příjem kauce',
  PRICE: 'Příjem půjčovného',
  BALANCE_CLOSE: 'Zavření kasy',
  BALANCE_OPEN: 'Otevření kasy',
  EXPENDITURE: 'Výběr',
  INCOME: 'Vklad',
};

const ItemsList = ({ data }: Props) => {
  const classes = useStyles();
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell className={classes.tableCellHead}>Čas</TableCell>
          <TableCell className={classes.tableCellHead}>Typ akce</TableCell>
          <TableCell className={classes.tableCellHead}>Částka</TableCell>
          <TableCell className={classes.tableCellHead}>Zaměstnanec</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.cashRegister.map(item => {
          const type =
            item.__typename === 'Payment' ? item.type : item.recordType;
          const userName =
            item.__typename === 'Payment'
              ? item.user && item.user.name
              : item.employee.user && item.employee.user.name;
          return (
            <TableRow key={item.id}>
              <TableCell className={classes.tableCell}>
                {formatTime(item.createdAt)}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {mapTypes[type]}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {includes(type, [
                  PaymentType.RETURN,
                  PaymentType.RETURN_PRICE,
                  RecordType.BALANCE_CLOSE,
                ])
                  ? -1 * item.amount
                  : item.amount}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {userName || '-'}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default ItemsList;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: 16,
      display: 'flex',
      justifyContent: 'space-around',
      flexFlow: 'wrap',
    },
    customWidth: {
      maxWidth: 190,
    },
    datePicker: {
      marginLeft: 64,
      marginRight: 32,
    },
    right: {
      paddingRight: 15,
    },
    tableCellHead: {
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: '0.75rem',
      padding: '4px 24px 4px 24px;',
    },
    tableCell: {
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: '14px',
      padding: '8px 24px 8px 24px',
    },
  }),
);
