import {
  createStyles,
  Drawer,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import React from 'react';
import { PageDrawerItem } from '../lib/types';
import { Link } from '@reach/router';
import { ListItemTypeMap } from '@material-ui/core/ListItem';
import { ExtendButtonBase } from '@material-ui/core/ButtonBase';
import auth from '../lib/auth';

type Props = {
  children: any;
  listItems: PageDrawerItem[];
  selectedItem: string;
};

const PageDrawer: React.FunctionComponent<Props> = props => {
  const classes = useStyles();

  const ProtectedListItem: OverridableComponent<
    ListItemTypeMap<{ button?: false }, 'li'>
  > &
    ExtendButtonBase<ListItemTypeMap<{ button: true }, 'div'>> = (
    props: any,
  ) => {
    const isAdmin = auth.isAdmin();
    return isAdmin ? <ListItem {...props} /> : <></>;
  };

  return (
    <>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.toolbar} />
        <List>
          {props.listItems.map(item => {
            const params = {
              key: item.to,
              component: Link,
              to: item.to,
              selected: item.to === props.selectedItem,
              children: <ListItemText primary={item.label} />,
            };
            return item.adminRightsRequired ? (
              <ProtectedListItem {...params} />
            ) : (
              <ListItem {...params} />
            );
          })}
        </List>
      </Drawer>
      <main className={classes.content}>{props.children}</main>
    </>
  );
};

const drawerWidth = 180;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      marginLeft: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
  }),
);

export default PageDrawer;
