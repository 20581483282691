import {
  Button,
  Fab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import PhotoIcon from '@material-ui/icons/Photo';
import { RouteComponentProps } from '@reach/router';
import * as React from 'react';
import { Query } from 'react-apollo';
import AddPriceDialog from '../../components/AddNewEntityDialog';
import AllowedCategoriesDialog from '../../components/AllowedCategoriesDialog';
import UploadImageDialog from '../../components/UploadImageDialog';
import { setPrices } from '../../graphql/queries';
import auth from '../../lib/auth';
import { PriceEntity } from '../../lib/enums';
import { Price } from '../../lib/types';
import PriceEditDialog from './PriceEditDialog';
import './price.css';

type IState = {
  open: Price | null;
  openUploadImage: Price | null;
  setPriceDialog: Price | null;
  addSetPriceDialogOpened: boolean;
};

class Sets extends React.Component<RouteComponentProps, IState> {
  state: IState = {
    open: null,
    openUploadImage: null,
    setPriceDialog: null,
    addSetPriceDialogOpened: false,
  };

  handleOpenEditPrices = (item: Price) =>
    this.setState({ setPriceDialog: item });

  handleCloseEditPrices = () => this.setState({ setPriceDialog: null });

  handleClickOpen = (item: Price) => () => {
    this.setState({ open: item });
  };

  handleAddSetPriceDialog = () => {
    this.setState(prevSate => ({
      addSetPriceDialogOpened: !prevSate.addSetPriceDialogOpened,
    }));
  };

  handleClose = () => {
    this.setState({ open: null });
  };

  handleOpenUploadImage = (item: Price) => () =>
    this.setState({ openUploadImage: item });
  handleCloseUploadImage = () => this.setState({ openUploadImage: null });

  sortSetPrices = (a: Price, b: Price) => {
    let result = 0;
    if (a.id < b.id) {
      result = -1;
    } else if (a.id > b.id) {
      result = 1;
    }
    return result;
  };

  render() {
    const isAdmin = auth.isAdmin();
    const { openUploadImage, setPriceDialog } = this.state;
    return (
      <div className="Item">
        <Query query={setPrices}>
          {(data: any) => {
            if (data.loading) {
              return <p>Loading...</p>;
            }
            if (data.error) {
              return <p>Error :</p>;
            }
            const setPricesData = JSON.parse(
              JSON.stringify(data.data.setPrices),
            );
            return (
              <div>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Název</TableCell>
                      <TableCell>Varianta</TableCell>
                      <TableCell>1 den</TableCell>
                      <TableCell>2 dny</TableCell>
                      <TableCell>Základ</TableCell>
                      <TableCell>Záloha</TableCell>
                      <TableCell>1 Sezóna</TableCell>
                      <TableCell>2 Sezóny</TableCell>
                      <TableCell>Sezónní záloha</TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        Nastavení kategorií
                      </TableCell>
                      {isAdmin && (
                        <>
                          <TableCell style={{ textAlign: 'center' }}>
                            Upravit ceny
                          </TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            Úprava obrázku
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {setPricesData
                      .sort(this.sortSetPrices)
                      .map((item: Price) => (
                        <TableRow key={item.id}>
                          <TableCell>{item.name}</TableCell>
                          <TableCell>{item.variant}</TableCell>
                          <TableCell>
                            {item.oneDayPrice}
                            &nbsp;Kč
                          </TableCell>
                          <TableCell>
                            {item.twoDayPrice}
                            &nbsp;Kč
                          </TableCell>
                          <TableCell>
                            {item.basePrice}
                            &nbsp;Kč
                          </TableCell>
                          <TableCell>
                            {item.bail}
                            &nbsp;Kč
                          </TableCell>
                          <TableCell>
                            {item.seasonPrice}
                            &nbsp;Kč
                          </TableCell>
                          <TableCell>
                            {item.twoSeasonPrice}
                            &nbsp;Kč
                          </TableCell>
                          <TableCell>
                            {item.seasonBail}
                            &nbsp;Kč
                          </TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            <Button onClick={this.handleClickOpen(item)}>
                              <EditIcon />
                            </Button>
                          </TableCell>
                          {isAdmin && (
                            <>
                              <TableCell style={{ textAlign: 'center' }}>
                                <Button
                                  onClick={() =>
                                    this.handleOpenEditPrices(item)
                                  }
                                >
                                  <EditIcon />
                                </Button>
                              </TableCell>
                              <TableCell style={{ textAlign: 'center' }}>
                                <Button
                                  onClick={this.handleOpenUploadImage(item)}
                                >
                                  <PhotoIcon />
                                </Button>
                              </TableCell>
                            </>
                          )}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '24px',
                  }}
                >
                  <Tooltip title="Přidat">
                    <Fab color="primary" onClick={this.handleAddSetPriceDialog}>
                      <AddIcon />
                    </Fab>
                  </Tooltip>
                </div>
                {openUploadImage && (
                  <UploadImageDialog
                    id={openUploadImage.id}
                    entity={'setPrice'}
                    open={openUploadImage !== null}
                    label={openUploadImage.name}
                    onClose={this.handleCloseUploadImage}
                    pathToCurrentImage={openUploadImage.imagePath}
                    onUpdate={() => data.refetch()}
                  />
                )}
                {setPriceDialog && (
                  <PriceEditDialog
                    open={true}
                    item={setPriceDialog}
                    onClose={this.handleCloseEditPrices}
                    entity={PriceEntity.SET_PRICE}
                  />
                )}
                {this.state.open && (
                  <AllowedCategoriesDialog
                    title={this.state.open.name}
                    setPriceId={this.state.open.id}
                    open={true}
                    onClose={this.handleClose}
                  />
                )}
                {this.state.addSetPriceDialogOpened && (
                  <AddPriceDialog
                    open={this.state.addSetPriceDialogOpened}
                    onClose={this.handleAddSetPriceDialog}
                    entity={PriceEntity.SET_PRICE}
                  />
                )}
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default Sets;
