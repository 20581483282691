import {
  createStyles,
  Fab,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Theme,
} from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import EditIcon from '@material-ui/icons/Edit';
import RemoveIcon from '@material-ui/icons/Remove';
import React, { useState } from 'react';
import { useApolloClient, useMutation, useQuery } from 'react-apollo';
import AddDescription from '../../../components/AddDescription';
import { GET_CATALOG_GROUP_LENGTH } from '../../../graphql/localQueries';
import {
  removeCatalogItemFromCatalogGroupMutation,
  setPositionOfCatalogItemsInCatalogGroupMutation,
} from '../../../graphql/queries';
import {
  removeCatalogItem as removeCatalogItemType,
  removeCatalogItemVariables,
  setCatalogItem as setCatalogItemType,
  setCatalogItemVariables,
} from '../../../graphql/types';
import { handleMutation } from './utils';

interface IProps {
  itemIds: string[];
  isFirst: boolean;
  isLast: boolean;
  positionInGroup: number;
  name: string;
  producer: string;
  sizes: string[];
}

const CellActionRemoveAndReorderItem: React.FunctionComponent<IProps> = (
  props: IProps,
) => {
  const [removeCatalogItem] = useMutation<
    removeCatalogItemType,
    removeCatalogItemVariables
  >(removeCatalogItemFromCatalogGroupMutation);
  const [setCatalogItem] = useMutation<
    setCatalogItemType,
    setCatalogItemVariables
  >(setPositionOfCatalogItemsInCatalogGroupMutation);
  const classes = useStyles();
  const client = useApolloClient();
  const [position, setPosition] = useState(0);
  const { data } = useQuery(GET_CATALOG_GROUP_LENGTH);
  const [addDescOpen, setAddDescOpen] = useState(false);

  const handleAddDescClick = () => setAddDescOpen(!addDescOpen);

  const handleRemoveButtonClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    const idsAsNumbers = props.itemIds.map(id => parseInt(id, 10));
    handleMutation<removeCatalogItemType, removeCatalogItemVariables>(
      client,
      { catalogItemIds: idsAsNumbers },
      removeCatalogItem,
    );
  };

  const handleEditButtonClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    const idsAsNumbers = props.itemIds.map(id => parseInt(id, 10));
    handleMutation<setCatalogItemType, setCatalogItemVariables>(
      client,
      {
        catalogItemIds: idsAsNumbers,
        positionInCatalogGroup: position,
      },
      setCatalogItem,
    );
    setPosition(0);
  };
  const handlePositionChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    child: React.ReactNode,
  ) => {
    event.preventDefault();
    setPosition(event.target.value as number);
  };

  const id = `select-order-${props.itemIds[0]}`;
  return (
    <div className={classes.buttonsWrapperr}>
      <FormControl className={classes.formControl}>
        <InputLabel id={id}>Pořadí</InputLabel>
        <Select labelId={id} value={position} onChange={handlePositionChange}>
          <MenuItem value={0} disabled={true}>
            Vyberte
          </MenuItem>
          {data &&
            [...Array(data.catalogGroupLength).keys()].map((item: number) => (
              <MenuItem
                value={item + 1}
                key={`${id}-${item + 1}`}
                disabled={item + 1 === props.positionInGroup}
              >
                {item + 1}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <Fab
        variant="round"
        className={`${classes.fab} ${classes.editFab}`}
        disabled={position === 0}
        onClick={handleEditButtonClick}
      >
        <EditIcon />
      </Fab>
      <Fab
        variant="round"
        className={`${classes.fab} ${classes.fabDescription}`}
        onClick={handleAddDescClick}
      >
        <DescriptionIcon />
      </Fab>
      <Fab
        color="secondary"
        variant="round"
        className={classes.fab}
        onClick={handleRemoveButtonClick}
      >
        <RemoveIcon />
      </Fab>
      {addDescOpen && (
        <AddDescription
          open={addDescOpen}
          onClose={handleAddDescClick}
          name={props.name}
          producer={props.producer}
          sizes={props.sizes}
          itemIds={props.itemIds}
        />
      )}
    </div>
  );
};

export default CellActionRemoveAndReorderItem;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 82,
      textAlign: 'left',
    },
    buttonsWrapperr: {
      display: 'inline-flex',
      alignItems: 'flex-end',
    },
    fab: {
      width: 36,
      height: 36,
      marginLeft: 8,
    },
    editFab: { backgroundColor: '#ff9100', color: 'white' },
    fabDescription: {
      backgroundColor: 'green',
      color: 'white',
      marginRight: 16,
    },
  }),
);
