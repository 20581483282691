import {
  FormControl,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useEffect, useState } from 'react';
import { PaymentForm, PaymentType } from '../../lib/enums';
import { paymentFormCZ } from '../../lib/localization';
import { PaymentDialog } from '../../lib/types';

interface Props {
  payments: PaymentDialog[];
  onPaymentsUpdate: (payments: PaymentDialog[]) => void;
}

const Payments = ({ payments, onPaymentsUpdate }: Props) => {
  const [paymentsToSave, setPaymentsToSave] = useState<PaymentDialog[]>(
    payments,
  );

  useEffect(() => {
    setPaymentsToSave(payments);
  }, [payments]);

  const handleDeleteEdited = (index: number, readyPaymentIndex: number) => {
    const paymentAmount =
      paymentsToSave[index].readyPayments[readyPaymentIndex].amount;

    const updatedPayments = paymentsToSave.map((payment, i) => {
      if (i === index) {
        const updatedReadyPayments = [
          ...payment.readyPayments.slice(0, readyPaymentIndex),
          ...payment.readyPayments.slice(readyPaymentIndex + 1),
        ];

        return {
          ...payment,
          amount: paymentsToSave[index].readyForPay + paymentAmount,
          readyPayments: updatedReadyPayments,
          readyForPay: paymentsToSave[index].readyForPay + paymentAmount,
          paymentTotal: paymentsToSave[index].paymentTotal - paymentAmount,
          buttonDisabled: !(
            paymentsToSave[index].paymentTotal - paymentAmount >=
            0
          ),
        };
      }
      return payment;
    });

    setPaymentsToSave(updatedPayments);
    onPaymentsUpdate(updatedPayments);
  };

  const hasPayment = paymentsToSave.some(
    payment => payment.readyPayments.length,
  );

  return (
    <>
      {!!hasPayment && (
        <Typography variant="h6" gutterBottom>
          Platby
        </Typography>
      )}
      <Table>
        <TableBody>
          {paymentsToSave.map((payment, index) => (
            <>
              {payment.readyPayments &&
                payment.readyPayments.map((readyPayment, readyPaymentIndex) => (
                  <TableRow key={`return-payment-${readyPaymentIndex}`}>
                    <TableCell>
                      {payment.type === PaymentType.BAIL ? 'Kauce' : 'Půjčovné'}
                    </TableCell>
                    <TableCell>
                      <FormControl>
                        <TextField
                          label={
                            payment.type === PaymentType.BAIL
                              ? 'Kauce'
                              : 'Půjčovné'
                          }
                          type="number"
                          style={{ width: '70px' }}
                          disabled={true}
                          key={`${paymentsToSave[index].type}-${paymentsToSave[index].amountToPay}`}
                          onChange={e => {
                            const amount = parseFloat(e.target.value);
                            if (payment.readyPayments) {
                              const updatedReadyPayments = payment.readyPayments.map(
                                (readyPayment, i) =>
                                  i === readyPaymentIndex
                                    ? { ...readyPayment, amount: amount }
                                    : readyPayment,
                              );

                              const updatedPayment = {
                                ...payment,
                                readyPayments: updatedReadyPayments,
                              };

                              const updatedPayments = [
                                ...paymentsToSave.slice(0, index),
                                updatedPayment,
                                ...paymentsToSave.slice(index + 1),
                              ];

                              setPaymentsToSave(updatedPayments);
                              onPaymentsUpdate(updatedPayments);
                            } else {
                              console.error(
                                'readyPayments is not defined for the payment.',
                              );
                            }
                          }}
                          value={readyPayment.amount}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell>Způsob platby</TableCell>
                    <TableCell>
                      <FormControl>
                        <Select
                          labelId="payment-type-label"
                          id="payent-type-select"
                          defaultValue={readyPayment.form}
                          disabled={true}
                          onChange={e => {
                            const selectedForm = e.target.value as PaymentForm;
                            if (payment.readyPayments) {
                              const updatedReadyPayments = payment.readyPayments.map(
                                (readyPayment, i) =>
                                  i === readyPaymentIndex
                                    ? { ...readyPayment, form: selectedForm }
                                    : readyPayment,
                              );

                              const updatedPayment = {
                                ...payment,
                                readyPayments: updatedReadyPayments,
                              };

                              const updatedPayments = [
                                ...paymentsToSave.slice(0, index),
                                updatedPayment,
                                ...paymentsToSave.slice(index + 1),
                              ];

                              setPaymentsToSave(updatedPayments);
                              onPaymentsUpdate(updatedPayments);
                            } else {
                              console.error(
                                'readyPayments is not defined for the payment.',
                              );
                            }
                          }}
                        >
                          {Object.keys(PaymentForm)
                            .filter(key => isNaN(Number(key)))
                            .map((paymentForm: string) => {
                              return (
                                <MenuItem value={paymentForm} key={paymentForm}>
                                  {paymentFormCZ[paymentForm]}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <>
                      <TableCell>
                        <Button
                          onClick={() =>
                            handleDeleteEdited(index, readyPaymentIndex)
                          }
                        >
                          <DeleteIcon />
                        </Button>
                      </TableCell>
                    </>
                  </TableRow>
                ))}
            </>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default Payments;
