import React from 'react';
import PageDrawer from '../PageDrawer';
import { Router } from '@reach/router';
import RouteWrapper from '../RouteWrapper';
import Accounting from './Accounting/Accounting';
import Employees from './Employees/Employees';
import ClosedDays from './ClosedDays/ClosedDays';

type Props = {
  pathName: string;
};

const Others: React.FunctionComponent<Props> = ({ pathName }) => (
  <PageDrawer
    listItems={[
      { label: 'Účetní přehled', to: 'accounting', adminRightsRequired: false },
      { label: 'Zaměstnanci', to: 'employees', adminRightsRequired: false },
      { label: 'Zavřené dny', to: 'closedDays', adminRightsRequired: false },
    ]}
    selectedItem={pathName}
  >
    <Router>
      <RouteWrapper path="others" default={true}>
        <Accounting path="accounting" />
        <Employees path="employees" />
        <ClosedDays path="closedDays" />
      </RouteWrapper>
    </Router>
  </PageDrawer>
);

export default Others;
