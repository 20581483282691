import { RouteComponentProps } from '@reach/router';
import * as React from 'react';
import { Mutation, Query, withApollo } from 'react-apollo';
import { configQuery, configUpdate } from '../../graphql/queries';
import {
  ConfigQuery,
  ConfigUpdate,
  ConfigUpdateVariables,
} from '../../graphql/types';
import { FormControlLabel, Switch } from '@material-ui/core';

const Settings = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Query<ConfigQuery> query={configQuery}>
        {({ data }) => (
          <Mutation<ConfigUpdate, ConfigUpdateVariables>
            mutation={configUpdate}
            refetchQueries={[{ query: configQuery }]}
          >
            {postMutation => (
              <div>
                <FormControlLabel
                  control={
                    <Switch
                      checked={!!data?.configQuery?.seasonEnabled}
                      onChange={() =>
                        postMutation({
                          variables: {
                            input: {
                              seasonEnabled: !data?.configQuery?.seasonEnabled,
                            },
                          },
                        })
                      }
                      color="secondary"
                    />
                  }
                  label="Půjčujeme celosezónní"
                />
                <br />
                <FormControlLabel
                  control={
                    <Switch
                      checked={!!data?.configQuery?.twoSeasonsEnabled}
                      onChange={() =>
                        postMutation({
                          variables: {
                            input: {
                              twoSeasonsEnabled: !data?.configQuery
                                ?.twoSeasonsEnabled,
                            },
                          },
                        })
                      }
                      color="secondary"
                    />
                  }
                  label="Půjčujeme dvou sezónní"
                />
                <br />
                <FormControlLabel
                  control={
                    <Switch
                      checked={!data?.configQuery?.addressDeliveryDisabled}
                      onChange={() =>
                        postMutation({
                          variables: {
                            input: {
                              addressDeliveryDisabled: !data?.configQuery
                                ?.addressDeliveryDisabled,
                            },
                          },
                        })
                      }
                      color="secondary"
                    />
                  }
                  label="Doručujeme na adresu"
                />
                <br />
                <FormControlLabel
                  control={
                    <Switch
                      checked={data?.configQuery?.isReservationDisabled}
                      onChange={() =>
                        postMutation({
                          variables: {
                            input: {
                              isReservationDisabled: !data?.configQuery
                                ?.isReservationDisabled,
                            },
                          },
                        })
                      }
                      color="secondary"
                    />
                  }
                  label="Online rezervace je zakázána"
                />
              </div>
            )}
          </Mutation>
        )}
      </Query>
    </div>
  );
};

const SettingsWithStylesApollo = withApollo(Settings);

const WrapperComponent: React.FunctionComponent<RouteComponentProps> = () => (
  <SettingsWithStylesApollo />
);

export default WrapperComponent;
